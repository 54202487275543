import _ from 'lodash';
import {
    DELETE_GAMEVERSION,
    EDIT_GAMEVERSION,
    FETCH_GAMEVERSION,
    CREATE_GAMEVERSION,
    FETCH_GAME_GAMEVERSIONS
} from '../actions/types';

export default (state={},action) => {
    switch (action.type) {
        case DELETE_GAMEVERSION:
            return _.omit(state, action.payload);
        case FETCH_GAME_GAMEVERSIONS:
//            return {...state, ..._.mapKeys(action.payload, 'id')};
            // se antithesi me oles tis alles periptwseis, edw antikathistw plhrws ta versions me ayta tou current paixnidiou anti
            // na prosthesw to apotelesma sta yparxonta. Alliws den paizei swsta o mixanismow gia na katalabei an einai dirty to paixnidi
            // gia na deiksei to publish button
            // @ToDo
            // Kai mallon prepei na kanw to idio gia ola ta reducers, kai oxi mono gia ta game versions
            return {..._.mapKeys(action.payload, 'id')};
        case FETCH_GAMEVERSION:
            return {...state, [action.payload.id]:action.payload};
        case CREATE_GAMEVERSION:
            return {...state, [action.payload.id]:action.payload};
        case EDIT_GAMEVERSION:
            return {...state, [action.payload.id]:action.payload};
        default:
            return state;
    }
}