import {Field, formValueSelector} from "redux-form";
import renderBooleanRadio from "./renderers/renderBooleanRadio";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import renderCheckbox from "./renderers/renderCheckbox";
import renderCheckbox2 from "./renderers/renderCheckbox2";
import {connect} from "react-redux";
import React from "react";
import {ACTION_TYPE_CAPTURE, ACTION_TYPE_SHARE} from "../interactions/interactionConstants";
import renderBooleanRadio2 from "./renderers/renderBooleanRadio2";
import G4mTooltip from "../utils/G4mTooltip";
//import ConditionForm2 from "./ConditionForm2";
//import {INTERACTION_FORM_NAME as FORM_NAME} from "./formTypes";
import {useTranslation} from "react-i18next";

const Action2 = ({baseFieldName, formName, mediatypeimage, mediatypevideo, mediatypetext, mediatypeaudio, mediatypemediaRequired, mediatypetextRequired}) => {
    const { t, i18n } = useTranslation();

    return (
        <>
        <Form.Row>
            <Col >
                <fieldset className="border p-2 mb-3">
                    <legend className="w-auto legend-small">Action type</legend>
                    <Field
                        component={renderBooleanRadio2}
                        name={`${baseFieldName}.type`}
                        type="radio"
                        inline={true}
                        value={ACTION_TYPE_SHARE}
                        label={`Share`}
                        props={{  }}
                    />
                    <Field
                        component={renderBooleanRadio2}
                        name={`${baseFieldName}.type`}
                        type="radio"
                        inline={true}
                        value={ACTION_TYPE_CAPTURE}
                        label={`Capture`}
                        props={{  }}
                    />
                </fieldset>
            </Col>
            <Col>
                <fieldset className="border p-2 mb-3">
                    <legend className="w-auto legend-small">Capture now <G4mTooltip tooltip={t('CAPTURE_NOW_TOOLTIP')} /></legend>
                        <Field
                            inline={true}
                            component={renderCheckbox2}
                            name={`${baseFieldName}.captureNow`}
                            type="checkbox"
                            label={`Yes`}
                            props={{  }}
                        />
                </fieldset>
            </Col>
        </Form.Row>
        <Form.Row>
            <Col>
                <fieldset className="border p-2 mb-3">
                    <legend className="w-auto legend-small">Capture/share must include</legend>
                    <Field
                        inline={true}
                        component={renderCheckbox2}
                        name={`${baseFieldName}.mediatypemediaRequired`}
                        type="checkbox"
                        label={`Media`}
                        props={{}}
                    />
                    <Field
                        inline={true}
                        component={renderCheckbox2}
                        name={`${baseFieldName}.mediatypetextRequired`}
                        type="checkbox"
                        label={`Text`}
                        props={{}}
                    />
                </fieldset>
            </Col>
            {
                mediatypemediaRequired &&
                <Col>
                    <fieldset className="border p-2 mb-3">
                        <legend className="w-auto legend-small">Types of possible media</legend>
                        <Field
                            component={renderCheckbox2}
                            name={`${baseFieldName}.mediatypeimage`}
                            inline={true}
                            type="checkbox"
                            label={`Image`}
                            props={{  }}
                        />
                        <Field
                            component={renderCheckbox2}
                            inline={true}
                            name={`${baseFieldName}.mediatypevideo`}
                            type="checkbox"
                            label={`Video`}
                            props={{  }}
                        />
                        <Field
                            component={renderCheckbox2}
                            inline={true}
                            name={`${baseFieldName}.mediatypeaudio`}
                            type="checkbox"
                            label={`Audio`}
                            props={{  }}
                        />

                    </fieldset>
                </Col>
            }



        </Form.Row>
</>
    );
}

export default connect(
    (state, ownProps) => {
        let fieldName = ownProps.baseFieldName;
        const selector = formValueSelector(ownProps.formName);
        const mediatypeimage = selector(state, `${fieldName}.mediatypeimage`);
        const mediatypevideo = selector(state, `${fieldName}.mediatypevideo`);
        const mediatypetext = selector(state, `${fieldName}.mediatypetext`);
        const mediatypeaudio = selector(state, `${fieldName}.mediatypeaudio`);

        const mediatypemediaRequired = selector(state, `${fieldName}.mediatypemediaRequired`);
        const mediatypetextRequired = selector(state, `${fieldName}.mediatypetextRequired`);
        return {
            mediatypeimage, mediatypevideo, mediatypetext, mediatypeaudio, mediatypemediaRequired, mediatypetextRequired
        }
    }
)(Action2)

//export default Action2;