//import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { fetchGame } from '../../actions/gamesActions';
import { fetchGameMedias } from '../../actions/mediasActions';
import { fetchGameScenes } from '../../actions/scenesActions';
import { fetchGameItems } from '../../actions/itemsActions';
import { fetchGameCharacters } from '../../actions/charactersActions';
import { fetchGameAttributes } from '../../actions/attributesActions';
import { fetchGameArchetypes } from '../../actions/archetypesActions';
import { fetchGameMilestones } from '../../actions/milestonesActions';
import { fetchGameOffers } from '../../actions/offersActions';
import { fetchGameMissions } from '../../actions/missionsActions';
import { fetchGameGameVersions } from '../../actions/gameVersionsActions';
import { fetchGameRules } from '../../actions/rulesActions';
import { fetchGameLocations } from '../../actions/locationsActions';
import { fetchGameInteractions } from '../../actions/interactionsActions';
import SideBar from '../layouts/SideBar';
import MainSidebar from './MainSidebar';
import MainContent from '../layouts/MainContent';
import G4mBreadcrumb from '../layouts/G4mBreadcrumb';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import MainArea from './MainArea';
import MainHeading from './MainHeading';
import { Alert, Breadcrumb, Button, Container } from 'react-bootstrap';
import ScreenTitle from '../utils/ScreenTitle';
import { fetchGameInitialStateSpec } from '../../actions/initialStateSpecActions';
import { fetchGameInitialState } from '../../actions/initialStateActions';
import history from '../../history';

const isEmpty = require('is-empty');

const GameDesign = ({ gameId, objecttype, objectaction, objectid, modifier1, returnTo }) => {
  const game = useSelector((state) => state.games[gameId]);

  let allScenes = useSelector((state) => state.scenes);
  let allInteractions = useSelector((state) => state.interactions);
  let allRules = useSelector((state) => state.rules);
  let allItems = useSelector((state) => state.items);
  let allCharacters = useSelector((state) => state.characters);
  let allArchetypes = useSelector((state) => state.archetypes);
  let allOffers = useSelector((state) => state.offers);
  let allAttributes = useSelector((state) => state.attributes);
  let allMilestones = useSelector((state) => state.milestones);
  let allMissions = useSelector((state) => state.missions);
  let allVersions = useSelector((state) => state.gameVersions);
  let allLocations = useSelector((state) => state.locations);

  let scenes = Object.values(allScenes).filter(function (el) {
    return el.gameId === gameId;
  });
  let interactions = Object.values(allInteractions).filter(function (el) {
    return el.gameId === gameId;
  });
  let rules = Object.values(allRules)
    .filter(function (el) {
      return el.gameId === gameId;
    })
    .sort((a, b) => a.idx - b.idx);
  let items = Object.values(allItems).filter(function (el) {
    return el.gameId === gameId;
  });
  let characters = Object.values(allCharacters).filter(function (el) {
    return el.gameId === gameId;
  });
  let archetypes = Object.values(allArchetypes).filter(function (el) {
    return el.gameId === gameId;
  });
  let offers = Object.values(allOffers).filter(function (el) {
    return el.gameId === gameId;
  });
  //console.log('----', { offers });
  let attributes = Object.values(allAttributes).filter(function (el) {
    return el.gameId === gameId;
  });
  let milestones = Object.values(allMilestones).filter(function (el) {
    return el.gameId === gameId;
  });
  let missions = Object.values(allMissions).filter(function (el) {
    return el.gameId === gameId;
  });
  let gameVersions = Object.values(allVersions).filter(function (el) {
    return el.gameId === gameId;
  });
  let locations = Object.values(allLocations).filter(function (el) {
    return el.gameId === gameId;
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (isEmpty(game)) {
      dispatch(fetchGame(gameId));
    }
    dispatch(fetchGameScenes(gameId));
    dispatch(fetchGameInteractions(gameId));
    dispatch(fetchGameRules(gameId));
    dispatch(fetchGameItems(gameId));
    dispatch(fetchGameCharacters(gameId));
    dispatch(fetchGameArchetypes(gameId));
    dispatch(fetchGameOffers(gameId));
    dispatch(fetchGameAttributes(gameId));
    dispatch(fetchGameMilestones(gameId));
    dispatch(fetchGameMissions(gameId));
    dispatch(fetchGameGameVersions(gameId));
    dispatch(fetchGameMedias(gameId));
    dispatch(fetchGameLocations(gameId));
    dispatch(fetchGameInitialState(gameId, 'current'));
  }, [gameId, dispatch, game]);

  if (isEmpty(game)) {
    return <>{'Loading game...'}</>;
  }

  if (game?.isArchived) {
    return (
      <Container>
        <Alert variant='danger'>
          <Alert.Heading>Oops</Alert.Heading>
          <p>This game has been archived.</p>
        </Alert>
        <div className='d-flex flex-column full-width align-items-center'>
          <Button
            onClick={() => {
              history.push('/');
            }}>
            Go Back
          </Button>
        </div>
      </Container>
    );
  }

  const heading = <MainHeading game={game} objecttype={objecttype} objectaction={objectaction} objectid={objectid} />;
  return (
    <>
      <SideBar>
        <MainSidebar
          game={game}
          objecttype={objecttype}
          objectaction={objectaction}
          objectid={objectid}
          scenes={scenes}
          interactions={interactions}
          rules={rules}
          milestones={milestones}
          missions={missions}
          gameVersions={gameVersions}
          items={items}
          offers={offers}
          characters={characters}
          attributes={attributes}
          archetypes={archetypes}
          locations={locations}
          modifier1={modifier1}
        />
      </SideBar>

      <MainContent>
        <Row>
          <Col>
            <G4mBreadcrumb game={game} objecttype={objecttype} objectaction={objectaction} objectid={objectid} hasSideBar={true} />

            {heading && <ScreenTitle header={heading} />}

            <MainArea
              game={game}
              scenes={scenes}
              locations={locations}
              interactions={interactions}
              rules={rules}
              attributes={attributes}
              archetypes={archetypes}
              offers={offers}
              items={items}
              characters={characters}
              milestones={milestones}
              missions={missions}
              gameVersions={gameVersions}
              returnTo={returnTo}
              objecttype={objecttype}
              objectaction={objectaction}
              objectid={objectid}
              modifier1={modifier1}
            />
          </Col>
        </Row>
      </MainContent>
    </>
  );
};
export default GameDesign;
