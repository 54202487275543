import gamesApi from "../apis/gamesApi";
import history from "../history";
import {
    FETCH_GAME_INITIAL_STATE_SPEC

} from "./types";
import {displayError, displaySuccess} from "../components/utils/notifications";




// fetch the game spec for use in initial state design. Can be from the live spec, a game version spec, or the state of a played game
export const fetchGameInitialStateSpec = (gameId, versionId) => async (dispatch) => {
    if (gameId) {
        return new Promise((resolve, reject) => {
            gamesApi.get(`/api/design/initialStateSpecs/${gameId}/${versionId}`)
                .then(response => {
                    dispatch({type:FETCH_GAME_INITIAL_STATE_SPEC, payload:response.data});
                    resolve();
                })
                .catch(err => {
                    dispatch({ type:FETCH_GAME_INITIAL_STATE_SPEC, payload: {}});
                    displayError(err, `Error retrieving game initial state spec`);
                    reject();
                });
        });
    }
};

