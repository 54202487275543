//import _ from 'lodash';
import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {editMission, createMission, deleteMission} from '../../actions/missionsActions';

import MissionForm from "../forms/MissionForm";
import history from "../../history";
import {bconfirm} from "../utils/ConfirmAlert";
import ItemForm from "../forms/ItemForm";

const MissionEdit = ({dispatch, mission, game, onCancel, onSave, afterDelete}) => {
    const handleCancel = () => {
        if (onCancel) {
            onCancel();
            return;
        }
        history.push(`/games/${game.id}`); // if no specific handler is used, redirect to game design page
    };
    const handleDelete = async (itemId) => {
        const result = await bconfirm('Are you really sure?', {cancelButtonStyle: 'secondary'});
        if (result) {
            dispatch(deleteMission(itemId))
                .then(() => {
                    if (afterDelete) {
                        afterDelete();
                        return;
                    }
                    else {
                        history.push(`/games/${game.id}`); // if no specific handler is used, redirect to game design page
                    }
                })
                .catch((e) => {
                    /* do nothing. A toastr notification should have been displayed */
                });
        }
    }
    const onSubmit = (formValues) => {
        console.log('MissionEdit onSubmit');
        if (mission) {
            dispatch(editMission(mission.id, formValues))
                .then((value) => {
                    if (onSave) {
                        onSave(value);
                        return;
                    }
                    else {
                        history.push(`/games/${game.id}`); // if no specific handler is used, redirect to game design page
                    }
                })
                .catch((e) => {
                    /* do nothing. An error toastr should have been displayed */
                });
        }
        else {
            dispatch(createMission(game.id, formValues))
                .then((value) => {
                    if (onSave) {
                        onSave(value);
                        return;
                    }
                    else {
                        history.push(`/games/${game.id}`); // if no specific handler is used, redirect to game design page
                    }
                })
                .catch((e) => {
                    /* do nothing. An error toastr should have been displayed */
                });
        }

    };
    const defaultInitialValues = {
        gameId:game.id
    };

    return (
        <div className="">

            <MissionForm
                enableReinitialize={true}
                handleCancel={handleCancel}
                handleDelete={handleDelete}
                initialValues={mission?mission:defaultInitialValues}
                game={game}
                onSubmit={onSubmit} />
            {
                // initialValues={_.pick(game, 'title', 'description', 'lat', 'lng', 'radius')}
            }
        </div>
    );

}

export default connect(null,null)(MissionEdit);
