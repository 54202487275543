import gamesApi from "../apis/gamesApi";
import history from "../history";
import {
    FETCH_GAME_INTERACTIONS,
    FETCH_INTERACTION,
    EDIT_INTERACTION,
    CREATE_INTERACTION,
    DELETE_INTERACTION
} from "./types";
import {displayError, displaySuccess} from "../components/utils/notifications";
//import {useTranslation} from "react-i18next";

import i18n from '../i18n';
//const { t, i18n } = useTranslation();

export const createInteraction = (gameId, formValues) =>  async (dispatch) => {

    return new Promise((resolve, reject) => {
        gamesApi.post(`/api/design/interactions?gameId=${gameId}`,{...formValues, gameId})
            .then(response => {
                dispatch({type:CREATE_INTERACTION, payload:response.data});
                let typeText = i18n.t(response.data.type+'_singular');
                displaySuccess(`${typeText} ${response.data.name} created`);
                resolve(response.data);
                //history.push(`/games/${gameId}`);
            })
            .catch(err => {
                displayError(err, `Error creating interaction`);
                reject();
            });
    });

};

export const fetchGameInteractions = (gameId) => async (dispatch) => {
    if (gameId) {
        return new Promise((resolve, reject) => {
            gamesApi.get(`/api/design/interactions?gameId=${gameId}`)
                .then(response => {
                    dispatch({type:FETCH_GAME_INTERACTIONS, payload:response.data});
                    resolve();
                })
                .catch(err => {
                    displayError(err, `Error retrieving game interactions`);
                    reject();
                });
        });
    }
    else {
        return new Promise((resolve, reject) => {
            dispatch({type: FETCH_GAME_INTERACTIONS, payload: []});
            displayError(null, `No gameId id in fetchGameInteractions`);
            reject();
        });
    }
};

export const fetchInteraction = (id) =>  async (dispatch) => {
    return new Promise((resolve, reject) => {
        gamesApi.get(`/api/design/interactions/${id}`)
            .then(response => {
                dispatch({type:FETCH_INTERACTION, payload:response.data});
                resolve();
            })
            .catch(err => {
                displayError(err, `Error retrieving interaction data for id ${id}`);
                reject();
            });
    });
};
export const editInteraction = (id, formValues) =>  async (dispatch) => {
    console.log('in editInteraction action, formValues', formValues);
    return new Promise((resolve, reject) => {
        gamesApi.patch(`/api/design/interactions/${id}`, formValues)
            .then(response => {
                dispatch({type:EDIT_INTERACTION, payload:response.data});
                let typeText = i18n.t(response.data.type+'_singular');
                displaySuccess(`${typeText} ${response.data.name} saved`);
                resolve(response.data);
                // history.push(`/games/${gameId}`);
            })
            .catch(err => {
                displayError(err, `Error saving interaction ${id}`);
                reject();
            });
    });
};
export const deleteInteraction = (id, redirectUrl) =>  async (dispatch) => {
    return new Promise((resolve, reject) => {
        gamesApi.delete(`/api/design/interactions/${id}`)
            .then(response => {
                dispatch({type:DELETE_INTERACTION, payload:id});
                displaySuccess(`Interaction deleted`, `Interaction ${id} was deleted`);
                resolve();
                if (redirectUrl) { // ??????????????
                    history.push(redirectUrl);
                }
            })
            .catch(err => {
                displayError(err, `Error deleting interaction ${id}`);
                reject();
            });
    });
};
