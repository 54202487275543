import React, { useCallback } from 'react';
import { Button, Col, Container, Form, FormCheck, Row, Tab, Tabs } from 'react-bootstrap';
import QRCode from 'react-qr-code';
import { useSelector } from 'react-redux';
import { displaySuccess } from '../utils/notifications';

export default function QrCodeForm({ eventType, eventName, objectId, gameId = 'any', sceneId = 'any', closeEditQrCodeModal }) {
  const dnldCode = useCallback((id) => {
    const svg = document.getElementById(id);
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image(1024, 1024);
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL('image/png');
      const downloadLink = document.createElement('a');
      downloadLink.download = 'QRCode';
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  }, []);
  const sceneValue = sceneId === 'universe' ? 'any' : sceneId;

  let httpsValue = `https://rollick.tuc.gr/mp/gameplay/${gameId}/${sceneValue}/${eventType}/${eventName}/${objectId}`;
  let rllckValue = `rllck://gameplay/${gameId}/${sceneValue}/${eventType}/${eventName}/${objectId}`;
  let txtValue = objectId;
  const defaultTab = 'rllck';
  return (
    <div className=''>
      <p>You can get the QR code in any of the following formats:</p>
      <Tabs defaultActiveKey={defaultTab} id='qr-code-tabs'>
        <Tab eventKey='rllck' title='As Deep Link'>
          <Container className='border border-top-0 pt-2'>
            <div>
              <p>
                <strong>
                  Deep Link Qr Codes can be scanned <strong>either with in-app scanner or with standard phone scanners</strong>.
                </strong>
                <br />
                In the second case the Rollick App is launched to handle the code.
              </p>
            </div>
            <Row>
              <Col className='text-center mb-2'>
                <QRCode id='QrRllck' size={1024} title={rllckValue} value={rllckValue} style={{ margin: 0, width: 300, height: 300 }} />
              </Col>
            </Row>
            <Row className='border-top p-2'>
              <Col className='text-center'>
                <Button
                  variant='outline-primary'
                  onClick={() => {
                    navigator.clipboard.writeText(rllckValue);
                    displaySuccess(`Link copied`);
                  }}>
                  <i className='fas fa-copy'></i> Copy Link to Clipboard
                </Button>
              </Col>
              <Col>
                <Button
                  variant='outline-primary'
                  type='button'
                  onClick={() => {
                    dnldCode('QrRllck');
                    if (typeof closeEditQrCodeModal === 'function') {
                      closeEditQrCodeModal();
                    }
                  }}
                  style={{ marginLeft: 2 }}>
                  <i className='fas fa-download'></i> Download QR code Image
                </Button>
              </Col>
            </Row>
          </Container>
        </Tab>
        {['do'].includes(eventType) && (
          <Tab eventKey='text' title='As In-App Text Code'>
            <Container className='border border-top-0 pt-2'>
              <div>
                <p style={{ padding: 5 }}>
                  In-App Text QR codes can be scanned <strong>only through the Rollick App scanner</strong> (in-app scanner).
                </p>
              </div>
              <Row>
                <Col className='text-center mb-2'>
                  <QRCode id='QrTxt' size={1024} title={txtValue} value={txtValue} style={{ margin: 0, width: 300, height: 300 }} />
                </Col>
              </Row>
              <Row className='border-top p-2'>
                <Col className='text-center'>
                  <Button
                    variant='outline-primary'
                    onClick={() => {
                      navigator.clipboard.writeText(txtValue);
                      displaySuccess(`Link copied`);
                    }}>
                    <i className='fas fa-copy'></i> Copy Code to Clipboard
                  </Button>
                </Col>
                <Col>
                  <Button
                    variant='outline-primary'
                    type='button'
                    onClick={() => {
                      dnldCode('QrTxt');
                      if (typeof closeEditQrCodeModal === 'function') {
                        closeEditQrCodeModal();
                      }
                    }}>
                    <i className='fas fa-download'></i> Download QR code Image
                  </Button>
                </Col>
              </Row>
            </Container>
          </Tab>
        )}

        {/* <Tab eventKey='https' title='As HTTPS Link'>
          <Container>
            <div className='p-2'>
              <p>This QR code can be both scanned with in-app scanner AND with standard phone scanners. In the second case the Rollick App is launched to handle the code.</p>
            </div>
            <Row>
              <Col className='text-center mb-2'>
                <QRCode id='QrHttp' size={256} title={httpsValue} value={httpsValue} style={{ margin: 0 }} />
              </Col>
            </Row>
            <Row>
              <Col className='text-center'>
                <Button
                  variant='primary'
                  type='button'
                  onClick={() => {
                    dnldCode('QrHttps');
                    if (typeof closeEditQrCodeModal === 'function') {
                      closeEditQrCodeModal();
                    }
                  }}
                  style={{ marginLeft: 2 }}>
                  <i className='fas fa-download'></i> Get QRcode
                </Button>
              </Col>
            </Row>
          </Container>
        </Tab> */}
      </Tabs>
    </div>
  );
}
