import React from 'react';

import {Field,reduxForm} from 'redux-form';
import {connect} from "react-redux";
import {GAME_PERIOD_FORM as FORM_NAME} from "./formTypes";
import PlayingPeriod2 from './PlayingPeriod2';
import Row   from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FormButtons from "./FormButtons";


const GamePeriodForm = ({error, dispatch, handleSubmit, onSubmit, handleCancel, initialValues, handleDelete}) => {
    const myOnSubmit = (formValues) => {
        onSubmit(formValues);
    };

    return (
        <Form noValidate onSubmit={handleSubmit(myOnSubmit)}>

        <Row>
            <Col>
                <Field name="playingPeriod"
                       reduxFieldName={'playingPeriod'}
                       component={PlayingPeriod2}
                       formName={FORM_NAME}
                       isInline={false}
                />
            </Col>
        </Row>


        <FormButtons validationGeneralError={error} handleCancel={handleCancel} objectId={initialValues.id} handleDelete={initialValues.id?handleDelete:null} />
        </Form>

    );

}

const validate = (formValues) => {
    const errors = {};
    return errors;
};

export default connect()(reduxForm(
    {
        form: FORM_NAME,
        validate:validate,
    }
)(GamePeriodForm));
