import _ from 'lodash';
import {
    FETCH_GAME_INITIAL_STATE_SPEC
} from '../actions/types';

export default (state={},action) => {
    switch (action.type) {
        case FETCH_GAME_INITIAL_STATE_SPEC:
            // return only the data returned, and not the previous data
//            return {..._.mapKeys(action.payload, 'id')};
            return action.payload;
        default:
            return state;
    }
}