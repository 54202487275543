import gamesApi from "../apis/gamesApi";
import history from "../history";
import {
    FETCH_GAME_ATTRIBUTES,
    FETCH_ATTRIBUTE,
    EDIT_ATTRIBUTE,
    CREATE_ATTRIBUTE,
    DELETE_ATTRIBUTE
} from "./types";
import {displayError, displaySuccess} from "../components/utils/notifications";


export const createAttribute = (gameId, formValues) =>  async (dispatch) => {
    return new Promise((resolve, reject) => {
        gamesApi.post(`/api/design/attributes?gameId=${gameId}`,{...formValues, gameId})
            .then(response => {
                dispatch({type:CREATE_ATTRIBUTE, payload:response.data});
                displaySuccess(`Attribute ${response.data.name} created`);
                resolve();
                //history.push(`/games/${gameId}`);
            })
            .catch(err => {
                displayError(err, `Error creating attribute`);
                reject();
            });
    });

};

export const fetchGameAttributes = (gameId) => async (dispatch) => {
    if (gameId) {
        return new Promise((resolve, reject) => {
            gamesApi.get(`/api/design/attributes?gameId=${gameId}`)
                .then(response => {
                    dispatch({type:FETCH_GAME_ATTRIBUTES, payload:response.data});
                    resolve();
                })
                .catch(err => {
                    displayError(err, `Error retrieving game attributes`);
                    reject();
                });
        });
    }
    else {
        return new Promise((resolve, reject) => {
            dispatch({type: FETCH_GAME_ATTRIBUTES, payload: []});
            displayError(null, `No gameId id in fetchGameAttributes`);
            reject();
        });
    }
};

export const fetchAttribute = (id) =>  async (dispatch) => {
    return new Promise((resolve, reject) => {
        gamesApi.get(`/api/design/attributes/${id}`)
            .then(response => {
                dispatch({type:FETCH_ATTRIBUTE, payload:response.data});
                resolve();
            })
            .catch(err => {
                displayError(err, `Error retrieving attribute data for id ${id}`);
                reject();
            });
    });
};
export const editAttribute = (id, formValues) =>  async (dispatch) => {
    return new Promise((resolve, reject) => {
        gamesApi.patch(`/api/design/attributes/${id}`, formValues)
            .then(response => {
                dispatch({type:EDIT_ATTRIBUTE, payload:response.data});
                displaySuccess(`Attribute ${response.data.name} saved`);
                resolve();
                // history.push(`/games/${gameId}`);
            })
            .catch(err => {
                displayError(err, `Error saving attribute ${id}`);
                reject();
            });
    });
};
export const deleteAttribute = (id, redirectUrl) =>  async (dispatch) => {
    return new Promise((resolve, reject) => {
        gamesApi.delete(`/api/design/attributes/${id}`)
            .then(response => {
                dispatch({type:DELETE_ATTRIBUTE, payload:id});
                displaySuccess(`Attribute deleted`, `Attribute ${id} was deleted`);
                resolve();
                if (redirectUrl) { // ??????????????
                    history.push(redirectUrl);
                }
            })
            .catch(err => {
                displayError(err, `Error deleting attribute ${id}`);
                reject();
            });
    });
};
