import React from 'react';
import { connect } from 'react-redux';
import history from '../../history';
import CollapseOffers from './CollapseOffers';

const OfferList = ({ offers, game, displayAs, dispatch }) => {
  const newItemHandler = () => {
    history.push(`/games/${game.id}/offers/create/0`);
  };
  const editItemHandler = (id) => {
    history.push(`/games/${game.id}/offers/edit/${id}`);
  };

  const editObjectHandler = (id) => {
    history.push(`/games/${game.id}/initialState/edit/${id}/offers`);
  };

  const newObjectHandler = (offerGroupId) => {
    history.push(`/games/${game.id}/initialState/create/${offerGroupId}/offers`);
  };

  const offerList = [];
  for (const item of offers) {
    offerList.push({ id: item.id, name: item.name });
  }

  if (displayAs === 'collapse')
    return (
      <CollapseOffers
        identifier='offerlist'
        heading='Offers'
        offers={offerList}
        newItemHandler={newItemHandler}
        newLabel='New Offer'
        editItemHandler={editItemHandler}
        editObjectHandler={editObjectHandler}
        newObjectHandler={newObjectHandler}
        deleteItemHandler={null}
        editLabel='Settings'
        deleteLabel='Delete'
        additionalClass='sideBar'
      />
    );
  return <>No displayAs</>;
};

export default connect(null, null)(OfferList);
