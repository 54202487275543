import React, { useEffect, useState } from 'react';
import { GoogleMap, InfoBox, InfoWindow, Marker, useJsApiLoader } from '@react-google-maps/api';
import { Button, Col, Image, Row } from 'react-bootstrap';

export default function ReactGoogleMap({ width = '100%', height = '100%', center, items, onItemClick }) {
  const [map, setMap] = React.useState(null);
  const [skipRebound, setSkipRebound] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GMAP_KEY,
  });

  useEffect(() => {
    if (map && !skipRebound && Array.isArray(items)) {
      const bounds = new window.google.maps.LatLngBounds(center);
      (items ?? []).forEach(({ position }) => {
        bounds.extend(position);
      });
      if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
        const extendPoint1 = new window.google.maps.LatLng(bounds.getNorthEast().lat() + 0.005, bounds.getNorthEast().lng() + 0.005);
        const extendPoint2 = new window.google.maps.LatLng(bounds.getNorthEast().lat() - 0.005, bounds.getNorthEast().lng() - 0.005);
        bounds.extend(extendPoint1);
        bounds.extend(extendPoint2);
      }
      map.fitBounds(bounds);
      setSkipRebound(true);
    }
  }, [map, items, skipRebound, center]);

  const onLoad = React.useCallback((map) => {
    console.log('map reloaded');
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    console.log('unmounting');
    setMap(null);
  }, []);

  const handleMarkerClick = (item) => {
    setActiveItem(item.id);
  };

  const getMarkers = () => {
    const markers = items.map((player) => {
      const { position } = player;
      return (
        <Marker onClick={() => handleMarkerClick(player)} position={position} draggable={false} title={player.name}>
          {activeItem === player.id && (
            <InfoWindow position={position}>
              <div style={{ minWidth: 250, padding: 10, paddingTop: 0, textAlign: 'center' }}>
                <Image src={player.iconUrl} alt='' roundedCircle width={40} height={40} />
                <h2 style={{ textAlign: 'center' }}>{player.name}</h2>
                <Button
                  variant='outline'
                  style={{ width: '100%' }}
                  onClick={(e) => {
                    setActiveItem(null);
                    onItemClick && onItemClick(e, player);
                  }}>
                  View
                </Button>
              </div>
            </InfoWindow>
          )}
        </Marker>
      );
    });
    return markers;
  };
  console.log({ center });
  return isLoaded ? (
    <GoogleMap mapContainerStyle={{ width, height }} center={center} zoom={10} onLoad={onLoad} onUnmount={onUnmount}>
      {getMarkers()}
    </GoogleMap>
  ) : (
    <></>
  );
}
