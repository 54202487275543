import React from "react";

function SocialLoginButtons(props) {
    return (
        <>
            <p>
                <a href={`${process.env.REACT_APP_API_BASE}/api/users/googleauth`} className="btn btn-block btn-outline-info">
                    <i className="fab fa-google"></i> &nbsp; Sign in via Google</a>
                <a href={`${process.env.REACT_APP_API_BASE}/api/users/facebookauth`} className="btn btn-block btn-outline-primary">
                    <i className="fab fa-facebook-f"></i> &nbsp; Sign in via facebook</a>
            </p>
        </>
    )
}

export default SocialLoginButtons;