import React, {useState, useRef} from "react";
import Row   from "react-bootstrap/Row";
import Button   from "react-bootstrap/Button";
import Overlay   from "react-bootstrap/Overlay";
import Tooltip   from "react-bootstrap/Tooltip";


// based on react-bootstrap-confirmation
const G4mTooltip = ({tooltip, textBefore}) => {
    const [show, setShow] = useState(false);
    const target = useRef(null);

    // &#9432; = ⓘ
    // preventdefault wste sta checkbox labels, kai genika sta labels na mhn kanei activate to field h' check/uncheck to control
    return (
        <>
            {textBefore?textBefore:''}&nbsp;<span ref={target} onClick={(e) => {e.preventDefault();setShow(!show)}} className={'help-icon'}>ⓘ</span>
                <Overlay target={target.current} show={show} placement="top">
                    {(props) => (
                        <Tooltip id="overlay-example" {...props}>
                            {tooltip}
                        </Tooltip>
                    )}
                </Overlay>
            </>
    );
};

export default G4mTooltip;