import { change, Field, formValueSelector } from 'redux-form';
import renderInput from './renderers/renderInput';
import { connect } from 'react-redux';
import React from 'react';
import { INTERACTION_FORM_NAME as FORM_NAME } from './formTypes';
import ImageChoose from './ImageChoose';
import RichTextEditorWYSIWYG from './renderers/RichTextEditorWYSIWYG';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

const selector = formValueSelector(FORM_NAME);

let Prompt = ({ dispatch, fieldNameBase, index, fields, mediaId, gameId, additionalRTEClass, textLabel }) => {
  if (!textLabel) {
    textLabel = 'Message';
  }
  return (
    <>
      <Col lg={10} md={9} sm={8}>
        <Field label={textLabel} name={`${fieldNameBase}.description`} component={RichTextEditorWYSIWYG} props={{ additionalRTEClass }} />
      </Col>
      <Col lg={2} md={3} sm={4}>
        <Field
          name={`${fieldNameBase}.mediaId`}
          label='Media'
          logoIdToShow={mediaId}
          handleRemoveMedia={() => dispatch(change(FORM_NAME, `${fieldNameBase}.mediaId`, null))}
          gameId={gameId}
          handleSetMediaId={(newId) => dispatch(change(FORM_NAME, `${fieldNameBase}.mediaId`, newId))}
          imageWidth={800}
          component={ImageChoose}
        />
      </Col>
    </>
  );
};
Prompt = connect((state, props) => ({
  mediaId: selector(state, `${props.fieldNameBase}.mediaId`),
  //qType: selector(state, `${props.question}.qType`)
}))(Prompt);

export default Prompt;
