import React from 'react';
import history from '../../history';
import CollapseItems from '../widgets/CollapseItems';

const InitlaStateMenuItems = ({ game, displayAs }) => {
  const editItemHandler = (url) => {
    history.push(url);
  };

  const itemList = [
    { id: `/games/${game.id}/initialState/manage-objects/0`, name: 'Objects' },
    { id: `/games/${game.id}/initialState/manage-offers/0`, name: 'Offers' },
  ];

  if (displayAs === 'collapse')
    return (
      <CollapseItems
        identifier='InitialStateItems'
        heading='Initial World'
        items={itemList}
        newItemHandler={null}
        newLabel={null}
        editItemHandler={editItemHandler}
        deleteItemHandler={null}
        editLabel='Manage'
        deleteLabel={null}
        additionalClass='sideBar'
        showCount={false}
      />
    );
  return <>No displayAs</>;
};

export default InitlaStateMenuItems;
