import {
    FETCH_ACTIVE_INSTANCES,
    FETCH_INSTANCE,
} from '../actions/types';

export default (state={},action) => {
    switch (action.type) {
        case FETCH_ACTIVE_INSTANCES:
            return {...state, activeInstances: action.payload };
        case FETCH_INSTANCE:
            return {...state, instance: action.payload };
        default:
            return state;
    }
}
