const isEmpty = require('is-empty');
const THUMB_WIDTH = 100;

export const getThumbUrl = (media, returnPlaceholder, width) => {
  if (isEmpty(media)) {
    return getThumbUrlById(null, returnPlaceholder, width);
  } else {
    return getThumbUrlById(media.id, returnPlaceholder, width);
  }
};
export const getThumbUrlById = (mediaId, returnPlaceholder, width, square = 1) => {
  if (isEmpty(mediaId)) {
    if (returnPlaceholder) {
      return `${process.env.PUBLIC_URL}/resources/images/empty-image.svg`;
    } else {
      return null;
    }
  } else {
    return `${process.env.REACT_APP_API_BASE}/api/design/medias/thumb/${mediaId}?width=${width || THUMB_WIDTH}&square=${square}`;
  }
};
export const getPreviewUrl = (media) => {
  if (isEmpty(media)) {
    return null;
  } else {
    return `${process.env.REACT_APP_API_BASE}/api/design/medias/thumb/${media.id}?width=300&square=0`;
  }
};
export const getOriginalUrl = (media) => {
  if (isEmpty(media)) {
    return null;
  } else {
    return `${process.env.REACT_APP_API_BASE}/${media.storagepath}`;
  }
};
