import _ from 'lodash';
import { CREATE_GAME, DELETE_GAME, EDIT_GAME, FETCH_GAME, FETCH_MY_GAMES } from '../actions/types';

export default (state = {}, action) => {
  switch (action.type) {
    case DELETE_GAME:
      return _.omit(state, action.payload);
    case FETCH_MY_GAMES:
      //return {...state, ..._.mapKeys(action.payload, 'id')};
      return { ..._.mapKeys(action.payload, 'id') };
    case FETCH_GAME:
      return { ...state, [action.payload.id]: action.payload };
    case CREATE_GAME:
      return { ...state, [action.payload.id]: action.payload };
    case EDIT_GAME:
      return { ...state, [action.payload.id]: action.payload };
    default:
      return state;
  }
};
