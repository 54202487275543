import React  from 'react';
import { getTotalCount } from '../../utils/utils';

const OffersSummaryWidget = ({ publishedGameVersion, analytics }) => {
  return (
    <>
      <h5>Offers</h5>
      <ul className="list-group mt-3">
        {publishedGameVersion.game.spec.resources.offers.map(o => {
          return (
            <li
              key={o.id}
              className="list-group-item d-flex justify-content-between align-items-center"
            >
              {o.title}
              <small className="font-sm text-right">
                Picked: <strong>{getTotalCount(analytics,`has.picked.offer.${o.id}`)}</strong>,
                Redeemed: <strong>{getTotalCount(analytics,`has.redeemed.offer.${o.id}`)}</strong>,
                Left: <strong>{o.quantity}</strong>
              </small>
            </li>
          )
        })}
      </ul>
    </>
  );
};

export default OffersSummaryWidget;
