import React from 'react';

import { change, Field, formValueSelector, reduxForm, submit } from 'redux-form';
import { connect } from 'react-redux';
import { LOCATION_FORM_NAME as FORM_NAME } from './formTypes';
import renderInput from './renderers/renderInput';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import ImageChoose from './ImageChoose';
import GoogleMapLocationForm from '../gmap/GoogleMapLocationForm';
import { ICON_URL_LOCATION } from '../gmap/iconUrls';
import FormButtons from './FormButtons';
import { useTranslation } from 'react-i18next';
import renderCheckbox2 from './renderers/renderCheckbox2';
import ConditionForm2 from './ConditionForm2';
import { asyncValidateForm, g4mFormTotalFormValidate, g4mFormValidate } from '../utils/validationUtils';
import renderSelect from './renderers/renderSelect';
import renderCheckbox from './renderers/renderCheckbox';
import G4mTooltip from '../utils/G4mTooltip';
const selector = formValueSelector(FORM_NAME);

const LocationForm = ({ error, dispatch, handleSubmit, onSubmit, handleCancel, scene, game, initialValues, logoId, handleDelete, visible, trackable }) => {
  const { t, i18n } = useTranslation();

  const onMarkerDrag = (lat, lng) => {
    dispatch(change(FORM_NAME, 'lat', lat));
    dispatch(change(FORM_NAME, 'lng', lng));
  };
  // βάσει https://github.com/redux-form/redux-form/issues/3701
  // για να μην κάνει propagate το event προς τα πάνω
  // γιατί παίζει να είναι react nested (όχι DOM nested) σε άλλη φόρμα
  // και σε αυτή την περίπτωση κάνει submit και την container form
  const formHandleSubmit = (form) => {
    return function handleSubmit(e) {
      e.preventDefault();
      e.stopPropagation();
      dispatch(submit(form));
    };
  };
  const visibleSelOptions = [
    { value: '', title: '- Select one -' },
    { value: 'always', title: 'Always' },
    { value: 'in-range', title: 'In Range' },
  ];
  if (trackable) {
    visibleSelOptions.push({ value: 'never', title: 'Never' });
  }

  const contactOptions = [
    { value: '', title: '- Select one -' },
    { value: 'in-range', title: 'In Range' },
  ];
  if (visible === 'always') {
    contactOptions.push({ value: 'always', title: 'Always' });
  }
  if (trackable && ['in-range', 'always'].includes(visible)) {
    contactOptions.push({ value: 'never', title: 'Never' });
  }

  return (
    <Container fluid>
      <Form noValidate onSubmit={formHandleSubmit(FORM_NAME)}>
        <Row>
          <Col lg={5}>
            <Field name='sceneId' component='input' type='hidden' />
            <Field name='gameId' component='input' type='hidden' />
            <Field name='name' label={t('NAME_FIELD_LABEL')} tooltip={t('NAME_FIELD_TOOLTIP')} asCol={false} component={renderInput} />
            <Field name='title' label='Title' asCol={false} component={renderInput} />
            <Form.Row className='mb-4'>
              <Field
                asCol={true}
                cols={8}
                name='logoId'
                label='Map Icon'
                logoIdToShow={logoId}
                imageWidth={300}
                handleRemoveMedia={() => dispatch(change(FORM_NAME, 'logoId', null))}
                gameId={initialValues && initialValues.gameId}
                handleSetMediaId={(newId) => dispatch(change(FORM_NAME, 'logoId', newId))}
                component={ImageChoose}
              />
              <Col></Col>
              <Field asCol={true} cols={4} width={40} name='iconSize' label='Icon Size' type='number' step='2' component={renderInput} />
            </Form.Row>
            <Form.Row>
              <Field asCol={true} name='lat' normalize={(value, previousValue, allValues) => (value ? parseFloat(value) : 0)} label='Latitude' type='number' step='0.001' component={renderInput} />
              <Field asCol={true} name='lng' normalize={(value, previousValue, allValues) => (value ? parseFloat(value) : 0)} label='Longitude' type='number' step='0.001' component={renderInput} />
              <Field asCol={true} name='radius' label='Radius (m)' type='number' step='10' component={renderInput} />
            </Form.Row>
          </Col>
          <Col lg={7}>
            <GoogleMapLocationForm
              formName={FORM_NAME}
              lat={initialValues.lat}
              noRadius={false}
              lng={initialValues.lng}
              onMarkerDrag={onMarkerDrag}
              inconUrl={ICON_URL_LOCATION}
              style={{ minHeight: 500 }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <fieldset className='border p-4 mb-3'>
              <legend>Perception</legend>
              <Row>
                <Col lg={2}>
                  <Field
                    inline={true}
                    tooltip='Specify if place visit will be triggered automatically when player enters its range'
                    component={renderCheckbox}
                    name={`trackable`}
                    type='checkbox'
                    addonLabel={`Trackable`}
                    props={{ containerControlClass: 'p-2' }}
                  />
                </Col>
                <Col lg={3}>
                  <Field
                    normalize={(value, previousValue, allValues) => (value ? value : null)}
                    name={`visible`}
                    noFormGroup={true}
                    asCol={false}
                    component={renderSelect}
                    hideLabel={false}
                    label={'Visible'}
                    tooltip='Specify when place icon will be visible on the map'
                    no_separate_error={true}>
                    {visibleSelOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.title}
                      </option>
                    ))}
                  </Field>
                </Col>
                {visible && visible !== 'never' && (
                  <Col lg={3}>
                    <Field
                      normalize={(value, previousValue, allValues) => (value ? value : null)}
                      name={`contactable`}
                      noFormGroup={true}
                      asCol={false}
                      component={renderSelect}
                      hideLabel={false}
                      label={'Tappable'}
                      tooltip={'Specify if tapping on (visible) place icon will be considered as place visit'}
                      no_separate_error={true}>
                      {contactOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.title}
                        </option>
                      ))}
                    </Field>
                  </Col>
                )}
              </Row>
              <fieldset className='border p-2 mb-3'>
                <legend className='w-auto legend-small'>Percieved only if</legend>
                <ConditionForm2 dispatch={dispatch} scene={null} game={game} baseFieldName={'if'} formName={FORM_NAME}></ConditionForm2>
              </fieldset>
            </fieldset>
          </Col>
        </Row>
        <FormButtons validationGeneralError={error} handleCancel={handleCancel} objectId={initialValues.id} handleDelete={initialValues.id ? handleDelete : null} />
      </Form>
    </Container>
  );
};

const validate = (formValues) => {
  const errors = {};
  if (!formValues.trackable) {
    if (!['in-range', 'always'].includes(formValues.visible)) {
      errors.visible = 'Non trackable locations should be visible';
    }
    if (!['in-range', 'always'].includes(formValues.contactable)) {
      errors.contactable = 'Non trackable locations should be contactable by tapping';
    }
  }

  g4mFormValidate(errors, formValues, ['title', 'if']); // name is validated asynchronously
  g4mFormTotalFormValidate(errors);

  //console.log("Validation errors of rule form", errors);
  return errors;
};

// export default reduxForm({
//     form: FORM_NAME,
//     validate:validate,
//  })(GameForm);

//
// export default reduxForm({
//     form: FORM_NAME,
//     validate:validate,
// })(LocationForm);

export default connect((state, ownProps) => {
  return {
    logoId: selector(state, 'logoId'),
    visible: selector(state, 'visible'),
    trackable: selector(state, 'trackable'),
  };
})(
  reduxForm({
    form: FORM_NAME,
    validate: validate,
    asyncValidate: asyncValidateForm,
    asyncBlurFields: ['name'],
  })(LocationForm)
);
