import humanNumber from 'human-number';
import React from 'react';
import { Card } from 'react-bootstrap';
import CountUp from 'react-countup';
import history from '../../history';

export default function DashboardCard({ title, stats, subStats, linkTo, icon = 'fa-delete' }) {
  const navigate = () => {
    if (linkTo) {
      history.push(linkTo);
    }
  };
  return (
    <Card style={{ width: '15rem', margin: 5, cursor: linkTo ? 'pointer' : 'auto', borderRadius: 20 }} onClick={navigate}>
      <Card.Header className='d-flex font-weight-bold align-items-center bg-rlck-primary txt-white' style={{ borderTopLeftRadius: 20, borderTopRightRadius: 20 }}>
        {title}
      </Card.Header>
      <Card.Body className='d-flex flex-col justify-content-center align-content-center'>
        <CountUp
          formattingFn={(n) => humanNumber(n)}
          style={{ fontSize: 50 }}
          className='text-info font-weight-bolder'
          start={0}
          end={stats}
          duration={1}
          useEasing={true}
          useGrouping={true}
          decimalPlaces={1}
          separator=','
          decimals={1}
        />
      </Card.Body>
      <Card.Subtitle style={{ textAlign: 'center', color: 'gray', margin: 0, fontSize: 16, padding: 3 }}>{subStats}</Card.Subtitle>
    </Card>
  );
}
