import _ from 'lodash';
import {
    DELETE_MISSION,
    EDIT_MISSION,
    FETCH_MISSION,
    CREATE_MISSION,
    FETCH_GAME_MISSIONS
} from '../actions/types';

export default (state={},action) => {
    switch (action.type) {
        case DELETE_MISSION:
            return _.omit(state, action.payload);
        case FETCH_GAME_MISSIONS:
            // return only the data returned, and not the previous data
//            return {..._.mapKeys(action.payload, 'id')};
            return {...state, ..._.mapKeys(action.payload, 'id')};
        case FETCH_MISSION:
            return {...state, [action.payload.id]:action.payload};
        case CREATE_MISSION:
            return {...state, [action.payload.id]:action.payload};
        case EDIT_MISSION:
            return {...state, [action.payload.id]:action.payload};
        default:
            return state;
    }
}