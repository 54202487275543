import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRoleRequests, respondRoRoleRequest } from '../../actions/adminActions';
import MainContent from '../layouts/MainContent';
import { Button, Card } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { format } from 'date-fns';
import { LinkContainer } from 'react-router-bootstrap';

export default function RoleRequests() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchRoleRequests());
  }, [dispatch]);

  const handleRoleResponse = useCallback(
    (email, action, reason) => {
      dispatch(respondRoRoleRequest(email, action, reason));
    },
    [dispatch]
  );

  const requests = useSelector((state) => state.admin.roleRequests);
  const columns = [
    {
      dataField: 'name',
      text: 'User',
      sort: true,
    },
    {
      dataField: 'roleRequest.role',
      text: 'Requested Role',
      sort: true,
      formatter: (cell, row) => {
        return cell?.toUpperCase();
      },
    },
    {
      dataField: 'roleRequest.date',
      text: 'Date Requested',
      sort: true,
      formatter: (cell, row) => {
        return format(new Date(cell), 'MMM do, yyyy');
      },
    },
    {
      dataField: 'email',
      text: 'Respond',
      headerStyle: () => {
        return { width: '120px' };
      },
      formatter: (cell, row) => {
        return (
          <div className='d-flex justify-content-between'>
            <Button
              variant='success'
              type='button'
              onClick={() => {
                handleRoleResponse(cell, 'approve');
              }}>
              <i className='fas fa-check'></i>
            </Button>
            <Button
              variant='danger'
              type='button'
              onClick={() => {
                handleRoleResponse(cell, 'reject');
              }}>
              <i className='fas fa-remove'></i>
            </Button>
          </div>
        );
      },
    },
  ];
  return (
    <MainContent hasSideBar={false}>
      <nav aria-label='breadcrumb'>
        <ol className='breadcrumb'>
          <li className='breadcrumb-item'>
            <LinkContainer to='/admin'>
              <a>Admin Dashboard</a>
            </LinkContainer>
          </li>
          <li className='breadcrumb-item'>
            <a>Role Requests</a>
          </li>
        </ol>
      </nav>
      <Card>
        <Card.Header className='d-flex font-weight-bold align-items-center bg-rlck-primary txt-white'>Role upgrade Requests</Card.Header>
        <Card.Body>
          <BootstrapTable
            keyField='id'
            data={requests}
            columns={columns}
            filter={filterFactory()}
            pagination={paginationFactory({
              sizePerPageList: [
                {
                  text: '10',
                  value: 10,
                },
                {
                  text: '20',
                  value: 20,
                },
                {
                  text: '50',
                  value: 50,
                },
                {
                  text: 'All',
                  value: requests.length,
                },
              ],
            })}
          />
        </Card.Body>
      </Card>
    </MainContent>
  );
}
