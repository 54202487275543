import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {getThumbUrl} from '../utils/mediaUtils';
import {fetchGameMedias} from '../../actions/mediasActions';
import Button from "react-bootstrap/Button";


const MediaList = ({ medias, isAuthenticated, errors, initialSelectedMedia, gameId, fetchGameMedias, dispatch, onMediaSelectHandler, handleMediaChoosen }) => {
    const [selectedMedia, setSelectedMedia] = useState(initialSelectedMedia?initialSelectedMedia.id:null);

    useEffect(() => {
        dispatch(fetchGameMedias(gameId));

    },[isAuthenticated, gameId]);

    const mediaSelect = (mediaId) => {
        setSelectedMedia(mediaId);
        onMediaSelectHandler(mediaId);
    }
    const renderList = () => {
        let gameMedias = Object.values(medias).filter(function (el) {return el.gameId === gameId;});

        if (!gameMedias || gameMedias.length === 0) {
            return (
                <>No Media</>
            );
        }
        return gameMedias.map(media=> {
            /*
                ending: "jpg"
                gameId: "5f16a97199527c9b7a975649"
                id: "5f1fef6b5e7a853b13de0163"
                mimetype: "image/jpeg"
                orig_filename: "servetas.jpg"
                filenamePrefix:xxxxx
                size: 43287
                storagepath: "uploads/5f16a97199527c9b7a975649/V7F3XwuTWt61ZcV1jZPnJ.jpg"
                __v: 0
                _id: "5f1fef6b5e7a853b13de0163"
             */

            const url = getThumbUrl(media);
            return (
                <li className={selectedMedia === media.id?'active':''} key={media.id} onClick={() => mediaSelect(media.id)}>
                    <img src={url} alt={""} />
                </li>
            );
        });
    }

    return (
        <>
            Total of {medias?Object.values(medias).filter(function (el) {return el.gameId === gameId;}).length:0} media in game
            <ul className={"mediamanagerlist"}>
                {renderList()}
            </ul>
            <Button  variant="primary" disabled={selectedMedia?false:true} onClick={()=>handleMediaChoosen(selectedMedia)}>
                Choose selected media
            </Button>
        </>

    );
};


const mapStateToProps = (state) => {
    // to object.values pairnei ola ta properties enos object kai ta metatrepei ta values se array
    return {
        medias: state.medias,
        isAuthenticated: state.auth.isAuthenticated,
        errors:state.errors,
    };
};
//export default connect(mapStateToProps, {fetchMyGames, deleteGame})(GameList);
export default connect(mapStateToProps,
    (dispatch) => ({dispatch, fetchGameMedias: fetchGameMedias}))(MediaList);
