import React from 'react';
import ScreenTitle from "../utils/ScreenTitle";
import {connect} from "react-redux";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import {
    toggleSidebar
} from "../../actions/appActions";

// to dispatch to pairnei epeidh to kanw export me connect kai DEN exw 2h parametro. De xreiazetai sto connect na to balw explicitely
const MainContent = ({ children, errors, hasSideBar, dispatch, isAuthView = false }) => {
    const renderContent = () => {
        return (
            <>


            { errors && errors.statusText && <div className="general-errors">General Errors: {errors.statusText}</div>}
            {children}

            </>
        );
    };

    return (
        <div id="content" className={isAuthView ? '' : 'bg-white p-3 rounded shadow-sm'}>
                {renderContent()}
        </div>
    )
};


const mapStateToProps = (state) => {
    return {
        errors:state.errors
    };
};
export default connect(mapStateToProps,null)(MainContent);

//export default MainContent;
