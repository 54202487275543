import {combineReducers} from "redux";

import {reducer as formReducer} from "redux-form";
import {reducer as toastrReducer} from 'react-redux-toastr';

import authReducer from "./authReducer";
import errorReducer from "./errorReducer";

import gameReducer from "./gameReducer";
import gameAnalyticsReducer from "./gameAnalyticsReducer";
import gameInstancesReducer from "./gameInstancesReducer";
import mediaReducer from "./mediaReducer";
import appReducer from "./appReducer";
import sceneReducer from "./sceneReducer";
import itemReducer from "./itemReducer";
import characterReducer from "./characterReducer";
import attributeReducer from "./attributeReducer";
import archetypeReducer from "./archetypeReducer";
import milestoneReducer from "./milestoneReducer";
import missionReducer from "./missionReducer";
import gameVersionReducer from "./gameVersionReducer";
import interactionReducer from "./interactionReducer";
import offerReducer from "./offerReducer";
import ruleReducer from "./ruleReducer";
import locationReducer from "./locationReducer";
import initialStateReducer from "./initialStateReducer";
import initialStateSpecReducer from "./initialStateSpecReducer";
import adminReducer from './adminReducer';


export default combineReducers( {
    form: formReducer,
    auth: authReducer,
    errors: errorReducer,
    games: gameReducer,
    gameAnalytics: gameAnalyticsReducer,
    gameInstances: gameInstancesReducer,
    medias: mediaReducer,
    scenes: sceneReducer,
    milestones: milestoneReducer,
    missions: missionReducer,
    gameVersions: gameVersionReducer,
    interactions: interactionReducer,
    items: itemReducer,
    initialState: initialStateReducer,
    initialStateSpec: initialStateSpecReducer,
    characters: characterReducer,
    attributes: attributeReducer,
    archetypes: archetypeReducer,
    offers: offerReducer,
    rules: ruleReducer,
    locations: locationReducer,
    toastr: toastrReducer,
    app:appReducer,
    admin:adminReducer
})
