//import _ from 'lodash';
import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {editAttribute, createAttribute, deleteAttribute} from '../../actions/attributesActions';

import AttributeForm from "../forms/AttributeForm";
import history from "../../history";
import ArchetypeForm from "../forms/ArchetypeForm";
import {bconfirm} from "../utils/ConfirmAlert";

const AttributeEdit = ({dispatch, attribute, game, onCancel, onSave, afterDelete}) => {
    const handleCancel = () => {
        if (onCancel) {
            onCancel();
            return;
        }
        history.push(`/games/${game.id}`); // if no specific handler is used, redirect to game design page
    };
    const handleDelete = async (id) => {
        const result = await bconfirm('Are you really sure?', {cancelButtonStyle: 'secondary'});
        if (result) {
            dispatch(deleteAttribute(id))
                .then(() => {
                    if (afterDelete) {
                        afterDelete();
                        return;
                    }
                    else {
                        history.push(`/games/${game.id}`); // if no specific handler is used, redirect to game design page
                    }
                })
                .catch((e) => {
                    /* do nothing. A toastr notification should have been displayed */
                });
        }
    }
    const onSubmit = (formValues) => {
        if (attribute) {
            dispatch(editAttribute(attribute.id, formValues))
                .then(() => {
                    if (onSave) {
                        onSave();
                        return;
                    }
                    else {
                        history.push(`/games/${game.id}`); // if no specific handler is used, redirect to game design page
                    }
                })
                .catch((e) => {
                    /* do nothing. An error toastr should have been displayed */
                });
        }
        else {
            dispatch(createAttribute(game.id, formValues))
                .then(() => {
                    if (onSave) {
                        onSave();
                        return;
                    }
                    else {
                        history.push(`/games/${game.id}`); // if no specific handler is used, redirect to game design page
                    }
                })
                .catch((e) => {
                    /* do nothing. An error toastr should have been displayed */
                });
        }

    };
    const defaultInitialValues = {
        gameId: game.id
    };

    return (
        <div className="">

            <AttributeForm
                enableReinitialize={true}
                handleCancel={handleCancel}
                handleDelete={handleDelete}
                game={game}
                initialValues={attribute?attribute:defaultInitialValues}
                onSubmit={onSubmit} />
            {
                // initialValues={_.pick(game, 'title', 'description', 'lat', 'lng', 'radius')}
            }
        </div>
    );

}

export default connect(null,null)(AttributeEdit);
