import { Field, FieldArray } from 'redux-form';
import renderInput from './renderers/renderInput';
import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import renderCheckbox from './renderers/renderCheckbox';
import renderError from './renderers/renderError';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

//const selector = formValueSelector(FORM_NAME);
const renderOptions = ({ fields, meta, viewer, hasCorrectAnswer }) => {
  return (
    <>
      {renderError(meta, true)}
      <Button disabled={viewer} className='mb-2' onClick={() => fields.push({})}>
        <i className='fas fa-plus'></i> New Option
      </Button>
      <ul className={'p-0 m-0 list-unstyled questionoptionsul'}>
        {fields.map((option, index) => (
          <li key={index} className='border p-1 mb-1 rounded'>
            <Row>
              <Col className={''}>
                <Field name={`${option}.description`} type='text' component={renderInput} label={`Option #${index + 1}`} hideLabel={true} noFormGroup />
              </Col>
              {hasCorrectAnswer && (
                <Col lg={2} className={''}>
                  <Field placementFixInline noFormGroup component={renderCheckbox} name={`${option}.partOfCorrect`} type='checkbox' label='Correct' />
                </Col>
              )}
              <Col lg={1} className={''}>
                <Button
                  className={'mr-1 '}
                  disabled={viewer}
                  variant='outline-danger'
                  style={{ borderWidth: '0px' }}
                  onClick={() => {
                    return fields.remove(index);
                  }}
                  title='Remove Option'>
                  <i className='fa fa-times'></i>
                  <span className={'controlLabel sr-only'}> Remove Option</span>
                </Button>
              </Col>
            </Row>
          </li>
        ))}
      </ul>
    </>
  );
};
let McOptions = ({ dispatch, question, index, viewer, fields, hasCorrectAnswer }) => {
  return (
    <>
      <FieldArray name={`${question}.options`} component={renderOptions} props={{ hasCorrectAnswer, viewer }} />
    </>
  );
};
// McOptions =  connect(
//     (state, props) => ({
//         mediaId:selector(state, `${props.question}.mediaId`),
//     })
// )(McOptions);

export default McOptions;
