import React from 'react';
import history from "../../history";
import CollapseItems from "../widgets/CollapseItems";
import {bconfirm} from "../utils/ConfirmAlert";
import {deleteMission} from "../../actions/missionsActions";
import {connect} from "react-redux";

const MissionList = ({missions, game, displayAs, dispatch}) => {
    const newItemHandler = () => {
        history.push(`/games/${game.id}/missions/create/0`);
    }
    const editItemHandler = (id) => {
        history.push(`/games/${game.id}/missions/edit/${id}`);
    }


    const itemList = [];
    for (const item of missions) {
        itemList.push({id:item.id,name:item.name});
    }

    if (displayAs === 'collapse')
        return (
            <CollapseItems identifier="missionlist" heading="Missions" items={itemList}
                           newItemHandler={newItemHandler}
                           newLabel="New"
                           editItemHandler={editItemHandler}
                           deleteItemHandler={null}
                           editLabel="Settings"
                           deleteLabel="Delete"
                           additionalClass="sideBar"
            />
        );
    return <>No displayAs</>
}

export default connect(null,null)(MissionList);