import gamesApi from "../apis/gamesApi";
import history from "../history";
import {
    FETCH_GAME_LOCATIONS,
    FETCH_LOCATION,
    EDIT_LOCATION,
    CREATE_LOCATION,
    DELETE_LOCATION
} from "./types";
import {displayError, displaySuccess} from "../components/utils/notifications";


export const createLocation = (gameId, formValues) =>  async (dispatch) => {
    return new Promise((resolve, reject) => {
        gamesApi.post(`/api/design/locations?gameId=${gameId}`,{...formValues, gameId})
            .then(response => {
                dispatch({type:CREATE_LOCATION, payload:response.data});
                displaySuccess(`Location ${response.data.name} created`);
                resolve(response.data);
                //history.push(`/games/${gameId}`);
            })
            .catch(err => {
                displayError(err, `Error creating location`);
                reject();
            });
    });

};

export const fetchGameLocations = (gameId) => async (dispatch) => {
    if (gameId) {
        return new Promise((resolve, reject) => {
            gamesApi.get(`/api/design/locations?gameId=${gameId}`)
                .then(response => {
                    dispatch({type:FETCH_GAME_LOCATIONS, payload:response.data});
                    resolve();
                })
                .catch(err => {
                    displayError(err, `Error retrieving game locations`);
                    reject();
                });
        });
    }
    else {
        return new Promise((resolve, reject) => {
            dispatch({type: FETCH_GAME_LOCATIONS, payload: []});
            displayError(null, `No gameId id in fetchGameLocations`);
            reject();
        });
    }
};

export const fetchLocation = (id) =>  async (dispatch) => {
    return new Promise((resolve, reject) => {
        gamesApi.get(`/api/design/locations/${id}`)
            .then(response => {
                dispatch({type:FETCH_LOCATION, payload:response.data});
                resolve();
            })
            .catch(err => {
                displayError(err, `Error retrieving location data for id ${id}`);
                reject();
            });
    });
};
export const editLocation = (id, formValues) =>  async (dispatch) => {
    return new Promise((resolve, reject) => {
        gamesApi.patch(`/api/design/locations/${id}`, formValues)
            .then(response => {
                dispatch({type:EDIT_LOCATION, payload:response.data});
                displaySuccess(`Location ${response.data.name} saved`);
                resolve();
                // history.push(`/games/${gameId}`);
            })
            .catch(err => {
                displayError(err, `Error saving location ${id}`);
                reject();
            });
    });
};
export const deleteLocation = (id, redirectUrl) =>  async (dispatch) => {
    return new Promise((resolve, reject) => {
        gamesApi.delete(`/api/design/locations/${id}`)
            .then(response => {
                dispatch({type:DELETE_LOCATION, payload:id});
                displaySuccess(`Location deleted`, `Location ${id} was deleted`);
                resolve();
                if (redirectUrl) { // ??????????????
                    history.push(redirectUrl);
                }
            })
            .catch(err => {
                displayError(err, `Error deleting location ${id}`);
                reject();
            });
    });
};
