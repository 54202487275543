import React from 'react';
import LinkContainer from "react-router-bootstrap/lib/LinkContainer";
import {connect} from "react-redux";
import MainContent from "../layouts/MainContent";
import history from "../../history";



const RegisterSuccess = ({ isAuthenticated }) => {

    if (isAuthenticated) {
        history.push(`/`);
        return (
            <></>
        );
    }

    return (
        <>
            <MainContent header="" hasSideBar={false}>
                <div className="row justify-content-md-center">
                    <div className="card col-md-3">
                        <article className="card-body">
                            Thank you for registering with Rollick Games. Please check your email to verify your email address. You can then proceed to
                            <LinkContainer to={`/login`}>
                                <a href="/login"> Sign in</a>
                            </LinkContainer>
                        </article>
                    </div>
                </div>

            </MainContent>
        </>
    );

};




const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.isAuthenticated
    };
};
export default connect(mapStateToProps, null)(RegisterSuccess);

