import React, { Component } from "react";

import { connect } from "react-redux";
import {setCurrentUser} from "../../actions/authActions";
import setAuthToken from "../../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import authapi from "../../apis/authapi";
import {setCookie, getCookie} from "../utils/cookieUtils";
import {GET_ERRORS} from "../../actions/types";

class SocialRedirect extends Component {
    constructor() {
        super();
        console.log(`SocialRedirect constructor`);
        // creazetai initial state alliws petaei sfalma logw xrhshs tou getDerivedStateFromProps
        this.state = {
        };
    }



    async componentDidMount() {
        console.log(`SocialRedirect componentDidMount`);
        // If already logged in and user navigates to this page, should redirect them to dashboard
        if (this.props.auth.isAuthenticated) {
            this.props.history.push("/");
        }

        // we check the special jwt cookie set by social login callback if it is present
        // if yes, we set it to our redux store, and empty the cookie
        let token = getCookie('jwtfromsocial');

        if (!token || token === '') {
            // if BE and Client are on different domains, the JWT cookie set by the BE is not accessible via get cookie
            // because of different domains.
            // So I make a new special request to the BE, which should be made by the client with the cookie just sent from the BE,
            // and the BE will a) send the token in the BODY of the response and b) will delete the cookie
            // We use {withCredentials: true} as otherwise the cookie is not sent

            const res = await authapi.get("/api/users/jwtAsBody",{withCredentials: true});
            if (res.data.jwtToken && res.data.jwtToken !== '') {
                token = res.data.jwtToken;
            }
            /*
            authapi
                .get("/api/users/jwtAsBody",{withCredentials: true})
                .then(res => {
                    console.log(res.data);
                    if (res.data.jwtToken && res.data.jwtToken !== '') {
                        token = res.data.jwtToken;
                    }

                }) // re-direct to login on successful register
                .catch(err => {

                    }
                );*/
        }


        console.log(`SocialRedirect componentDidMount token from cookie is ${token}`);
        if (token && token !== '') {
            localStorage.setItem("jwtToken", token);
            // Set token to Auth header
            setAuthToken(token);
            // Decode token to get user data
            const decoded = jwt_decode(token);
            // Set current user
            this.props.dispatch(setCurrentUser(decoded));
            setCookie('jwtfromsocial', '', 0)
        }


    }


    static getDerivedStateFromProps(nextProps, state) {
        console.log(`SocialRedirect getDerivedStateFromProps`);
        if (nextProps.auth.isAuthenticated) {
            nextProps.history.push("/"); // push user to dashboard when they login
        }
        if (nextProps.errors) {
            return{
                errors: nextProps.errors
            };
        }

        // Return null to indicate no change to state.
        return null;
    }

    render() {
        console.log(`SocialRedirect render`);
        const { errors } = this.state;
        return (
            <>
                TODO display any errors
            </>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});


export default connect(mapStateToProps,
    (dispatch) => ({dispatch, setCurrentUser}))(SocialRedirect);;