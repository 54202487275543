import React from 'react';
import renderError from './renderError';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import G4mTooltip from '../../utils/G4mTooltip';

const renderSelect = ({ input, tooltip, label, meta, hideLabel, type, value, asCol, noFormGroup, children, additionalControlClass, additionalControlStyle, no_separate_error }) => {
  if (typeof no_separate_error === 'undefined') {
    no_separate_error = false;
  }
  const className =
    `form-control ${no_separate_error ? 'no_separate_error' : ''} field ${meta.error && meta.touched ? 'error' : ''}` + (additionalControlClass ? ' ' + additionalControlClass + '' : '');

  const formGroupPros = {}; // for handling <Form.Group as={Col}>
  if (asCol) {
    formGroupPros.as = Col;
  }
  const labelClassName = hideLabel ? 'sr-only' : '';
  let tooltipControl = null;
  if (tooltip) {
    tooltipControl = <G4mTooltip tooltip={tooltip} textBefore={label} />;
  }
  const controls = (
    <>
      <Form.Label className={labelClassName}>{tooltip ? tooltipControl : label}</Form.Label>
      <Form.Control {...input} className={className} as='select' style={additionalControlStyle ? additionalControlStyle : {}}>
        {children}
      </Form.Control>
      {!no_separate_error && renderError(meta)}
    </>
  );
  if (noFormGroup) {
    return controls;
  }

  return (
    <>
      <Form.Group {...formGroupPros}>{controls}</Form.Group>
    </>
  );
};

export default renderSelect;
