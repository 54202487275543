import { useTranslation } from 'react-i18next';
import { ARCHETYPE_FORM_NAME, INTERACTION_FORM_NAME, OFFER_FORM_NAME, RULE_FORM_NAME } from './formTypes';

export const CONDITION_TYPE_SELECT_OPTIONS = {
  CONDITION_TYPE_PLAYER: [
    { title: 'CONDITION_TYPE_PLAYER_VERB_INFO', value: 'CONDITION_TYPE_PLAYER_VERB_INFO' },
    { title: 'CONDITION_TYPE_PLAYER_VERB_CONFIRMATION', value: 'CONDITION_TYPE_PLAYER_VERB_CONFIRMATION' },
    { title: 'CONDITION_TYPE_PLAYER_VERB_QUESTION', value: 'CONDITION_TYPE_PLAYER_VERB_QUESTION' },
    { title: 'CONDITION_TYPE_PLAYER_VERB_QUIZ', value: 'CONDITION_TYPE_PLAYER_VERB_QUIZ' },
    { title: 'CONDITION_TYPE_PLAYER_VERB_QUESTION_QUIZ', value: 'CONDITION_TYPE_PLAYER_VERB_QUESTION_QUIZ' },
    { title: 'CONDITION_TYPE_PLAYER_VERB_SOME_QUIZ', value: 'CONDITION_TYPE_PLAYER_VERB_SOME_QUIZ' },
    { title: 'CONDITION_TYPE_PLAYER_VERB_MISSION', value: 'CONDITION_TYPE_PLAYER_VERB_MISSION' },
    { title: 'CONDITION_TYPE_PLAYER_VERB_SCENE', value: 'CONDITION_TYPE_PLAYER_VERB_SCENE' },
    { title: 'CONDITION_TYPE_PLAYER_VERB_LOCATION', value: 'CONDITION_TYPE_PLAYER_VERB_LOCATION' },
    { title: 'CONDITION_TYPE_PLAYER_VERB_SOME_ARCHETYPE', value: 'CONDITION_TYPE_PLAYER_VERB_SOME_ARCHETYPE' },
    { title: 'CONDITION_TYPE_PLAYER_VERB_CURRENT_OBJECT', value: 'CONDITION_TYPE_PLAYER_VERB_CURRENT_OBJECT', showOnlyInForm: [ARCHETYPE_FORM_NAME] },
    { title: 'CONDITION_TYPE_PLAYER_VERB_SOME_ITEM', value: 'CONDITION_TYPE_PLAYER_VERB_SOME_ITEM' },
    { title: 'CONDITION_TYPE_PLAYER_VERB_CODE', value: 'CONDITION_TYPE_PLAYER_VERB_CODE' },
    { title: 'CONDITION_TYPE_PLAYER_VERB_SOME_CURRENCY', value: 'CONDITION_TYPE_PLAYER_VERB_SOME_CURRENCY' },
    { title: 'CONDITION_TYPE_PLAYER_VERB_ATTRIBUTE', value: 'CONDITION_TYPE_PLAYER_VERB_ATTRIBUTE' },
    { title: 'CONDITION_TYPE_PLAYER_VERB_CONVERSATION', value: 'CONDITION_TYPE_PLAYER_VERB_CONVERSATION' },
    { title: 'CONDITION_TYPE_PLAYER_VERB_CHARACTER', value: 'CONDITION_TYPE_PLAYER_VERB_CHARACTER' },
    { title: 'CONDITION_TYPE_PLAYER_VERB_GAME', value: 'CONDITION_TYPE_PLAYER_VERB_GAME' },
  ],
  CONDITION_TYPE_GAME: [{ title: 'CONDITION_TYPE_GAME_VERB_STATUS', value: 'CONDITION_TYPE_GAME_VERB_STATUS' }],
  CONDITION_TYPE_WORLD: [
    { title: 'CONDITION_TYPE_WORLD_VERB_ATTRIBUTE', value: 'CONDITION_TYPE_WORLD_VERB_ATTRIBUTE' },
    { title: 'CONDITION_TYPE_WORLD_VERB_SOME_ITEM', value: 'CONDITION_TYPE_WORLD_VERB_SOME_ITEM' },
    { title: 'CONDITION_TYPE_WORLD_VERB_SOME_ARCHETYPE', value: 'CONDITION_TYPE_WORLD_VERB_SOME_ARCHETYPE' },
  ],
  CONDITION_TYPE_OFFER: [
    { title: 'CONDITION_TYPE_OFFER_KEY_PREDICATE_VALUE', value: 'CONDITION_TYPE_OFFER_KEY_PREDICATE_VALUE', showOnlyInForm: [ARCHETYPE_FORM_NAME, OFFER_FORM_NAME] },
    { title: 'CONDITION_TYPE_OFFER_VERB_ATTRIBUTE', value: 'CONDITION_TYPE_OFFER_VERB_ATTRIBUTE', showOnlyInForm: [OFFER_FORM_NAME] },
  ],
  CONDITION_TYPE_OTHER: [{ title: 'CONDITION_TYPE_OTHER_VERB_MILESTONE', value: 'CONDITION_TYPE_OTHER_VERB_MILESTONE' }],
};

export const CONDITION_TYPE_VERBS = {
  CONDITION_TYPE_PLAYER_VERB_INFO: ['has_seen', 'not_has_seen'],
  CONDITION_TYPE_PLAYER_VERB_CONFIRMATION: ['has_approved', 'not_has_approved'],
  CONDITION_TYPE_PLAYER_VERB_QUESTION: ['has_been_asked', 'not_has_been_asked', 'has_answered', 'not_has_answered', 'has_passed', 'not_has_passed', 'has_failed', 'not_has_failed'],
  CONDITION_TYPE_PLAYER_VERB_QUIZ: ['has_been_asked', 'not_has_been_asked', 'has_started', 'not_has_started', 'has_completed', 'not_has_completed'],
  CONDITION_TYPE_PLAYER_VERB_QUESTION_QUIZ: ['has_been_asked', 'not_has_been_asked', 'has_answered', 'not_has_answered', 'has_passed', 'not_has_passed', 'has_failed', 'not_has_failed'],
  CONDITION_TYPE_PLAYER_VERB_SOME_QUIZ: ['has_been_asked', 'not_has_been_asked', 'has_answered', 'not_has_answered', 'has_passed', 'not_has_passed', 'has_failed', 'not_has_failed'],
  CONDITION_TYPE_PLAYER_VERB_MISSION: ['has_been_assigned', 'not_has_been_assigned', 'has_completed', 'not_has_completed'],
  CONDITION_TYPE_PLAYER_VERB_SCENE: ['has_entered', 'not_has_entered', 'has_exited', 'not_has_exited', 'is_currently_in', 'not_is_currently_in'],
  CONDITION_TYPE_PLAYER_VERB_LOCATION: ['has_visited', 'not_has_visited'],
  CONDITION_TYPE_PLAYER_VERB_SOME_ARCHETYPE: ['owns', 'has_created', 'has_picked', 'has_dropped', 'has_destroyed'],
  CONDITION_TYPE_PLAYER_VERB_CURRENT_OBJECT: ['not_has_picked', 'has_picked', 'not_owns', 'owns'],
  CONDITION_TYPE_PLAYER_VERB_SOME_ITEM: ['owns'],
  CONDITION_TYPE_PLAYER_VERB_CODE: [],
  CONDITION_TYPE_PLAYER_VERB_SOME_CURRENCY: ['owns'],
  CONDITION_TYPE_PLAYER_VERB_ATTRIBUTE: ['is_attributed', 'not_is_attributed', 'was_attributed'],
  CONDITION_TYPE_PLAYER_VERB_CONVERSATION: ['has_started', 'not_has_started', 'has_completed', 'not_has_completed'],
  CONDITION_TYPE_PLAYER_VERB_GAME: ['has_completed', 'not_has_completed'],
  CONDITION_TYPE_PLAYER_VERB_CHARACTER: ['has_talked', 'not_has_talked'],

  CONDITION_TYPE_OTHER_VERB_MILESTONE: ['has_reached'],

  CONDITION_TYPE_WORLD_VERB_ATTRIBUTE: ['is_attributed', 'not_is_attributed', 'has', 'not_has'],
  CONDITION_TYPE_WORLD_VERB_SOME_ITEM: ['has', 'not_has'],
  CONDITION_TYPE_WORLD_VERB_SOME_ARCHETYPE: ['has', 'not_has'],
  CONDITION_TYPE_GAME_VERB_STATUS: ['is', 'not_is'],
  CONDITION_TYPE_OFFER_VERB_ATTRIBUTE: ['is_attributed', 'not_is_attributed'],

  CONDITION_TYPE_PLAYER_VERB_INFO_TRIGGER: ['has_seen'],
  CONDITION_TYPE_PLAYER_VERB_CONFIRMATION_TRIGGER: ['has_approved', 'not_has_approved'],
  CONDITION_TYPE_PLAYER_VERB_QUESTION_TRIGGER: ['has_completed'],
  CONDITION_TYPE_PLAYER_VERB_QUIZ_TRIGGER: ['has_completed'],
  CONDITION_TYPE_PLAYER_VERB_QUESTION_QUIZ_TRIGGER: [],
  CONDITION_TYPE_PLAYER_VERB_SOME_QUIZ_TRIGGER: [],
  CONDITION_TYPE_PLAYER_VERB_MISSION_TRIGGER: ['has_completed'],
  CONDITION_TYPE_PLAYER_VERB_SCENE_TRIGGER: ['has_entered', 'has_exited'],
  CONDITION_TYPE_PLAYER_VERB_LOCATION_TRIGGER: ['has_visited'],
  CONDITION_TYPE_PLAYER_VERB_SOME_ARCHETYPE_TRIGGER: [],
  CONDITION_TYPE_PLAYER_VERB_CURRENT_OBJECT_TRIGGER: [], //TODO I dont not if needed
  CONDITION_TYPE_PLAYER_VERB_SOME_ITEM_TRIGGER: [],
  CONDITION_TYPE_PLAYER_VERB_CODE_TRIGGER: ['has_typedscannedcode'],
  CONDITION_TYPE_PLAYER_VERB_SOME_CURRENCY_TRIGGER: [],
  CONDITION_TYPE_PLAYER_VERB_ATTRIBUTE_TRIGGER: [],
  CONDITION_TYPE_PLAYER_VERB_CONVERSATION_TRIGGER: ['has_completed'],
  CONDITION_TYPE_PLAYER_VERB_CHARACTER_TRIGGER: [],
  CONDITION_TYPE_OTHER_VERB_MILESTONE_TRIGGER: ['has_reached'],
};

//export const CONDITION_FORM_NAME = 'CONDITION_FORM_NAME';
