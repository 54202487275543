import gamesApi from "../apis/gamesApi";
import history from "../history";
import {
    FETCH_GAME_OFFERS,
    EDIT_OFFER,
    CREATE_OFFER,
    DELETE_OFFER,
} from "./types";
import {displayError, displaySuccess} from "../components/utils/notifications";

export const fetchGameOffers = (gameId) => async (dispatch) => {
    if (gameId) {
        return new Promise((resolve, reject) => {
            gamesApi.get(`/api/design/offers?gameId=${gameId}`)
                .then(response => {
                    dispatch({ type:FETCH_GAME_OFFERS, payload:response.data });
                    resolve();
                })
                .catch(err => {
                    displayError(err, `Error retrieving game archetypes`);
                    reject();
                });
        });
    }
    else {
        return new Promise((resolve, reject) => {
            dispatch({ type: FETCH_GAME_OFFERS, payload: [] });
            displayError(null, `No gameId id in fetchGameOffers`);
            reject();
        });
    }
};


export const createOffer = (gameId, formValues) =>  async (dispatch) => {
    return new Promise((resolve, reject) => {
        gamesApi.post(`/api/design/offers?gameId=${gameId}`,{...formValues, gameId})
          .then(response => {
              dispatch({ type:CREATE_OFFER, payload:response.data });
              displaySuccess(`Object ${response.data.name} created`);
              resolve();
              //history.push(`/games/${gameId}`);
          })
          .catch(err => {
              displayError(err, `Error creating archetype`);
              reject();
          });
    });

};


export const ediOffer = (id, formValues) =>  async (dispatch) => {
    return new Promise((resolve, reject) => {
        gamesApi.patch(`/api/design/offers/${id}`, formValues)
          .then(response => {
              dispatch({ type: EDIT_OFFER, payload:response.data });
              displaySuccess(`Object ${response.data.name} saved`);
              resolve();
              // history.push(`/games/${gameId}`);
          })
          .catch(err => {
              displayError(err, `Error saving archetype ${id}`);
              reject();
          });
    });
};
export const deleteOffer = (id, redirectUrl) =>  async (dispatch) => {
    return new Promise((resolve, reject) => {
        gamesApi.delete(`/api/design/offers/${id}`)
          .then(response => {
              dispatch({ type: DELETE_OFFER, payload:id });
              displaySuccess(`Offer deleted`, `Offer ${id} was deleted`);
              resolve();
              if (redirectUrl) { // ??????????????
                  history.push(redirectUrl);
              }
          })
          .catch(err => {
              displayError(err, `Error deleting offer ${id}`);
              reject();
          });
    });
};

