//import _ from 'lodash';
import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {editGameVersion, createGameVersion, deleteGameVersion} from '../../actions/gameVersionsActions';

import GameVersionForm from "../forms/GameVersionForm";
import history from "../../history";
import CharacterForm from "../forms/CharacterForm";
import {bconfirm} from "../utils/ConfirmAlert";
import LinkContainer from "react-router-bootstrap/lib/LinkContainer";

const GameVersionEdit = ({dispatch, gameVersion, game, onCancel, onSave, afterDelete}) => {
    const handleCancel = () => {
        if (onCancel) {
            onCancel();
            return;
        }
        history.push(`/games/${game.id}`); // if no specific handler is used, redirect to game design page
    };
    const handleDelete = async (id) => {
        const result = await bconfirm('Are you really sure?', {cancelButtonStyle: 'secondary'});
        if (result) {
            dispatch(deleteGameVersion(id))
                .then(() => {
                    if (afterDelete) {
                        afterDelete();
                        return;
                    }
                    else {
                        history.push(`/games/${game.id}`); // if no specific handler is used, redirect to game design page
                    }
                })
                .catch((e) => {
                    /* do nothing. A toastr notification should have been displayed */
                });
        }
    }
    const onSubmit = (formValues) => {
        console.log('GameVersionEdit onSubmit');
        if (gameVersion) {
            dispatch(editGameVersion(gameVersion.id, formValues))
                .then((value) => {
                    if (onSave) {
                        onSave(value);
                        return;
                    }
                    else {
                        history.push(`/games/${game.id}`); // if no specific handler is used, redirect to game design page
                    }
                })
                .catch((e) => {
                    /* do nothing. An error toastr should have been displayed */
                });
        }
        else {
            dispatch(createGameVersion(game.id, formValues))
                .then((value) => {
                    if (onSave) {
                        onSave(value);
                        return;
                    }
                    else {
                        history.push(`/games/${game.id}`); // if no specific handler is used, redirect to game design page
                    }
                })
                .catch((e) => {
                    /* do nothing. An error toastr should have been displayed */
                });
        }

    };
    const defaultInitialValues = {
        gameId:game.id,
        newInstancesAllowed: true

    };

    let versionIdQueryPart = '';
    if (gameVersion) {
        if (!gameVersion.isLiveSpec) {
            versionIdQueryPart = `?versionId=${gameVersion.id}`;
        }
    }
    return (
        <div className="">

            <GameVersionForm
                enableReinitialize={true}
                handleCancel={handleCancel}
                handleDelete={handleDelete}
                initialValues={gameVersion?gameVersion:defaultInitialValues}
                game={game}
                onSubmit={onSubmit} />

            {
                gameVersion && (
                  <div className="mt-3">
                    <LinkContainer to={`/games/${game.id}/initialState/objects/0${versionIdQueryPart}`} className="btn btn-secondary">
                      <a>
                        Manage version Objects
                      </a>
                    </LinkContainer>

                    <LinkContainer to={`/games/${game.id}/initialState/offers/0${versionIdQueryPart}`} className="ml-4 btn btn-secondary">
                      <a>
                        Manage version Offers
                      </a>
                    </LinkContainer>
                  </div>
                )
            }

            {
                // initialValues={_.pick(game, 'title', 'description', 'lat', 'lng', 'radius')}
            }
        </div>
    );

}

export default connect(null,null)(GameVersionEdit);
