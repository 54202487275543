import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import React from 'react';
import { useSelector } from 'react-redux';
import { getUserRightsOnGame } from '../../utils/utils';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Alert } from 'react-bootstrap';

const FormButtons = ({ validationGeneralError, handleCancel, handleDelete, objectId, saveButtonText }) => {
  const { id, activeGameId } = useParams();
  const game = useSelector((state) => state.games[id ?? activeGameId]);
  const user = useSelector((state) => state.auth.user);
  const viewer = !getUserRightsOnGame(game, user).includes('edit');

  return (
    <>
      <hr />
      {validationGeneralError && <span className='generalFormError'>{validationGeneralError}</span>}
      <Row className={'mt-4 px-3'}>
        <Col>
          <Row className={'justify-content-start'}>
            {handleDelete && (
              <Button disabled={viewer} variant='danger' type='button' onClick={() => handleDelete(objectId)}>
                <i className='fas fa-trash'></i> Delete
              </Button>
            )}
          </Row>
        </Col>
        <Col>
          <Row className={'justify-content-end'}>
            <Button variant='secondary' type='button' className={'mr-2'} onClick={handleCancel}>
              <i className='fas fa-window-close'></i> Cancel
            </Button>
            <Button disabled={viewer} variant='primary' type='submit'>
              <i className='fas fa-save'></i> {saveButtonText ? saveButtonText : 'Save'}
            </Button>
          </Row>
        </Col>
      </Row>
      {false && (
        <Row className={'mt-4 full-width'}>
          <Col>
            <Alert variant='secondary'>
              <p className='font-italic font-smaller p-0 m-0 text-right'>You have no access rights to edit this game</p>
            </Alert>
          </Col>
        </Row>
      )}
    </>
  );
};

export default FormButtons;
