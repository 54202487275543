import _ from 'lodash';
import {
    DELETE_OFFER,
    EDIT_OFFER,
    FETCH_OFFER,
    CREATE_OFFER,
    FETCH_GAME_OFFERS
} from '../actions/types';

export default (state={},action) => {
    switch (action.type) {
        case DELETE_OFFER:
            return _.omit(state, action.payload);
        case FETCH_GAME_OFFERS:
            return {...state, ..._.mapKeys(action.payload, 'id')};
        case FETCH_OFFER:
            return {...state, [action.payload.id]:action.payload};
        case CREATE_OFFER:
            return {...state, [action.payload.id]:action.payload};
        case EDIT_OFFER:
            return {...state, [action.payload.id]:action.payload};
        default:
            return state;
    }
}
