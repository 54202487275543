import React, { useState } from 'react';
import CollapseItems from '../widgets/CollapseItems';
import { bconfirm } from '../utils/ConfirmAlert';
import { deleteLocation } from '../../actions/locationsActions';
import LocationEdit from './LocationEdit';
import ModalEdit from '../utils/ModalEdit';
import { connect } from 'react-redux';

const LocationList = ({ locations, game, scene, displayAs, dispatch }) => {
  const [openEditLocationModal, setOpenEditLocationModal] = useState(false);
  const [selectedLocationToEdit, setSelectedLocationToEdit] = useState({});
  const closeEditLocationModal = () => {
    setOpenEditLocationModal(false);
  };
  const cancelEditLocationModal = () => {
    setOpenEditLocationModal(false);
  };
  const newItemHandler = () => {
    setSelectedLocationToEdit(null);
    setOpenEditLocationModal(true);
    //history.push(`/games/${game.id}/locations/create/0/${scene.id}`);
  };
  const editItemHandler = (id) => {
    const location = locations.find((element) => element.id === id);
    setSelectedLocationToEdit(location);
    setOpenEditLocationModal(true);
    //history.push(`/games/${game.id}/locations/edit/${id}`);
  };

  const itemList = [];
  for (const item of locations) {
    if (scene && item.sceneId && item.sceneId.toString() === scene.id.toString()) {
      itemList.push({ id: item.id, name: item.name });
    }
  }
  return (
    <>
      <CollapseItems
        identifier='locationlist'
        heading='Scene Places'
        items={itemList}
        newItemHandler={newItemHandler}
        newLabel='New Place'
        editItemHandler={editItemHandler}
        deleteItemHandler={null}
        editLabel='Settings'
        deleteLabel='Delete'
        collapsible={displayAs === 'collapse'}
        additionalClass='sideBar'
      />
      <ModalEdit openEditModal={openEditLocationModal} closeEditModal={closeEditLocationModal} heading={'Edit or create Scene Place'} portal='location'>
        <LocationEdit game={game} scene={scene} location={selectedLocationToEdit} onSave={closeEditLocationModal} onCancel={cancelEditLocationModal} />
      </ModalEdit>
    </>
  );
};

export default connect(null, null)(LocationList);
