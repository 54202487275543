//import _ from 'lodash';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
//import Col from "react-bootstrap/Col";
//import './CollapseItems.css';
import { setCookie, getCookie } from '../utils/cookieUtils';
import { useTranslation } from 'react-i18next';
import CollapseItems from '../widgets/CollapseItems';
import { useDispatch, useSelector } from 'react-redux';
import history from '../../history';
import { toggleSideBarMenuItem } from '../../actions/appActions';

//const isEmpty = require("is-empty");
const STATE_COOKIE_NAME = 'g4mAccStates';

const OfferListItem = ({
  showCount,
  heading,
  identifier,
  newItemHandler,
  newItemArgument,
  newLabel,
  editItemHandler,
  editLabel,
  editObjectHandler,
  newObjectHandler,
  visitItemHandler,
  visitLabel,
  deleteItemHandler,
  deleteLabel,
  additionalClass,
}) => {
  const dispatch = useDispatch();
  const open = useSelector((state) => !!state.app.menu[identifier]);
  const toggleAccordion = () => {
    dispatch(toggleSideBarMenuItem(identifier));
  };

  const objects = useSelector((state) => {
    const objectsPerScene = state.initialState?.state?.has?.offer;
    const result = [];
    if (objectsPerScene) {
      Object.values(objectsPerScene).forEach((sceneObjects) => {
        Object.values(sceneObjects).forEach((object) => {
          if (object.type === identifier) {
            result.push(object);
          }
        });
      });
    }
    return result;
  });

  const getOfferObjects = () => {
    if (objects?.length === 0) {
      return <p>Not used yet</p>;
    }
    return objects.map((item) => {
      //const itemId = item.id;
      let label = (
        <span>
          {item.specialNamePrefixMarkup} {item.name}
        </span>
      );

      label = (
        <div className='clickable-collapsible-label' onClick={() => editObjectHandler(item.id)} title={editLabel}>
          {item.specialNamePrefixMarkup} {item.name ?? item.title}
        </div>
      );

      return <li key={item.id}>{label}</li>;
    });
  };

  let collapsibleStateClass = 'closed';
  if (open) {
    collapsibleStateClass = 'opened';
  }
  let newItemControl = null;
  if (newItemHandler) {
    newItemControl = (
      <li title={newLabel} onClick={() => newObjectHandler(identifier)}>
        <Button variant='outline-success' style={{ width: '100%' }}>
          {' '}
          <i className='fas fa-plus'></i> {newLabel}
        </Button>
      </li>
    );
  }
  return (
    <div className={`g4mcollapsible col-12 ${collapsibleStateClass} ${additionalClass}`} id={identifier}>
      <div className={'g4mcollapsible-heading'}>
        <div onClick={toggleAccordion} aria-controls={identifier + '-collapse'} className={'font-weight-bold d-flex w-100'}>
          {heading} {showCount && `(${objects?.length})`}
          <span className='ml-auto'>
            <i className='fas fa-angle-down showopened'></i>
            <i className='fas fa-angle-right closedicon showclosed'></i>
          </span>
        </div>
      </div>
      <Collapse in={open}>
        <ul className='g4mcollapsible-content' id={identifier + '-collapse'}>
          {newItemControl}
          {getOfferObjects()}
        </ul>
      </Collapse>
    </div>
  );
};
OfferListItem.propTypes = {
  showCount: PropTypes.bool,
};

OfferListItem.defaultProps = {
  showCount: true,
};

export default OfferListItem;
