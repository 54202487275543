import React from 'react';

import {change, Field, formValueSelector,reduxForm} from 'redux-form';
import {connect} from "react-redux";
import {CHARACTER_FORM_NAME as FORM_NAME} from "./formTypes";
import renderInput from "./renderers/renderInput";
//import renderBooleanRadio from "./renderers/renderBooleanRadio";

import Row   from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
//import renderCheckbox from "./renderers/renderCheckbox";
import ImageChoose from "./ImageChoose";
import FormButtons from "./FormButtons";
import {asyncValidateForm, g4mFormTotalFormValidate, g4mFormValidate} from "../utils/validationUtils";
import renderSelect from "./renderers/renderSelect";
import {useTranslation} from "react-i18next";


const selector = formValueSelector(FORM_NAME);

const CharacterForm = ({error, dispatch, handleSubmit, onSubmit, handleCancel, initialValues, mediaId, handleDelete}) => {
    const { t, i18n } = useTranslation();

    const myOnSubmit = (formValues) => {
        onSubmit(formValues);
    };

    return (
        <Form noValidate onSubmit={handleSubmit(myOnSubmit)}>

            <Field name="name" label={t('NAME_FIELD_LABEL')} tooltip={t('NAME_FIELD_TOOLTIP')} asCol={false} component={renderInput} />
            <Field name="title" label="Τitle" asCol={false} component={renderInput} />

            <Form.Row>
                <Col lg={5}  sm={4}>
                    <Field name="age" label="Age" asCol={false} type="number" step="1" component={renderInput} />
                </Col>
                <Col lg={5}  sm={4}>
                    <Field asCol={true} name={'gender'} component={renderSelect} label="Gender" noFormGroup={true}>
                        <option key={'m'} value={'m'}>Male</option>
                        <option key={'f'} value={'f'}>Female</option>
                    </Field>
                </Col>
                <Col lg={2}  sm={4}>
                    <Field name="mediaId" label="Media" logoIdToShow={mediaId}
                           handleRemoveMedia={()=>dispatch(change(FORM_NAME, 'mediaId', null))}
                           gameId={initialValues&&initialValues.gameId}
                           handleSetMediaId={(newId)=>dispatch(change(FORM_NAME, 'mediaId', newId))}
                           component={ImageChoose} />
                </Col>
            </Form.Row>
            <FormButtons validationGeneralError={error} handleCancel={handleCancel} objectId={initialValues.id} handleDelete={initialValues.id?handleDelete:null} />



        </Form>
    );

}

const validate = (formValues) => {
    const errors = {};
    g4mFormValidate(errors, formValues, ['title']); // name is validated asynchronously
    g4mFormTotalFormValidate(errors);
console.log('Character validation errors',errors);
    return errors;
};

export default connect(
    (state, ownProps) => {
        return {
            mediaId:selector(state, 'mediaId'),
        }
    }
)(reduxForm(
    {
        form: FORM_NAME,
        validate:validate,
        asyncValidate:asyncValidateForm,
        asyncBlurFields: [ 'name' ]
    }
)(CharacterForm));
