import React from 'react';

import {Field, reduxForm, submit} from 'redux-form';
import {MEDIA_FORM_NAME as FORM_NAME} from "./formTypes";
import renderInput from "./renderers/renderInput";
import Button from "react-bootstrap/Button";
import Row   from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";



const MediaForm = ({dispatch, handleSubmit, handleFormSubmit, handleCancel, initialValues,formName }) => {
    const myOnSubmit = (formValues) => {
        handleFormSubmit(formValues);
    };
    return (
        <Container fluid>
            <Row>
                <Col>
                    {
                        // diplo form. To ena aplo markup <form> kai to allo to to bootstrap form <Form>
                        // skopos einai sto conmtainer <form> na kanw stoppropagation wste na mhn kanei submit sth genikh forma pou anoikse to modal
                        // apo https://github.com/redux-form/redux-form/issues/3701
                    }
                    <form onSubmit={event => event.stopPropagation()}>
                        <Form noValidate onSubmit={handleSubmit(myOnSubmit)}>
                            <Field name="title" label="Enter title" asCol={false} component={renderInput} />

                            <Field name="description" type="textarea" label="Enter description" component={renderInput} />

                            <Form.Row>
                                <Form.Group as={Col} xs="auto">
                                    <Button  variant="primary" type="submit">
                                        Update
                                    </Button>
                                </Form.Group>
                            </Form.Row>
                        </Form>
                    </form>
                </Col>
            </Row>
        </Container>
    );

}

const validate = (formValues) => {
    const errors = {};
    if (!formValues.title) {
        errors.title = "You must enter a title";
    }
    return errors;
};

export default reduxForm({
    form: FORM_NAME,
    validate:validate,
})(MediaForm);


