import { REMOVE_ROLE_REQUEST, SET_ADMIN_STATS, SET_ROLE_REQUESTS } from '../actions/types';

const initialState = {
  stats: {},
  roleRequests: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ADMIN_STATS:
      return { ...state, stats: action.payload };
    case SET_ROLE_REQUESTS:
      return { ...state, roleRequests: action.payload };
    case REMOVE_ROLE_REQUEST: {
      const { email } = action.payload;
      const uRequests = state.roleRequests.filter((r) => r.email !== email);
      return { ...state, roleRequests: uRequests, stats: { ...state.stats, roleRequests: uRequests.length } };
    }
    default:
      return state;
  }
};
