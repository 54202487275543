import gamesApi from '../apis/gamesApi';
import history from '../history';
import {
  FETCH_GAME_GAMEVERSIONS,
  FETCH_GAMEVERSION,
  EDIT_GAMEVERSION,
  CREATE_GAMEVERSION,
  DELETE_GAMEVERSION,
  ARCHIVE_GAMEVERSION,
} from './types';
import {
  displayError,
  displaySuccess,
} from '../components/utils/notifications';

export const createGameVersion = (gameId, formValues) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    gamesApi
      .post(`/api/design/gameVersions?gameId=${gameId}`, {
        ...formValues,
        gameId,
      })
      .then((response) => {
        dispatch({ type: CREATE_GAMEVERSION, payload: response.data });
        displaySuccess(`Game Version ${response.data.version} created`);
        resolve(response.data);
        //history.push(`/games/${gameId}`);
      })
      .catch((err) => {
        displayError(err, `Error creating gameVersion`);
        reject();
      });
  });
};

export const fetchGameGameVersions = (gameId) => async (dispatch) => {
  if (gameId) {
    return new Promise((resolve, reject) => {
      gamesApi
        .get(`/api/design/gameVersions?gameId=${gameId}`)
        .then((response) => {
          dispatch({ type: FETCH_GAME_GAMEVERSIONS, payload: response.data });
          resolve();
        })
        .catch((err) => {
          displayError(err, `Error retrieving game gameVersions`);
          reject();
        });
    });
  } else {
    return new Promise((resolve, reject) => {
      dispatch({ type: FETCH_GAME_GAMEVERSIONS, payload: [] });
      displayError(null, `No gameId id in fetchGameGameVersions`);
      reject();
    });
  }
};

export const fetchGameVersion = (id) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    gamesApi
      .get(`/api/design/gameVersions/${id}`)
      .then((response) => {
        dispatch({ type: FETCH_GAMEVERSION, payload: response.data });
        resolve();
      })
      .catch((err) => {
        displayError(err, `Error retrieving gameVersion data for id ${id}`);
        reject();
      });
  });
};
export const editGameVersion = (id, formValues) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    gamesApi
      .patch(`/api/design/gameVersions/${id}`, formValues)
      .then((response) => {
        dispatch({ type: EDIT_GAMEVERSION, payload: response.data });
        displaySuccess(`GameVersion ${id} saved`);
        resolve();
        // history.push(`/games/${gameId}`);
      })
      .catch((err) => {
        displayError(err, `Error saving gameVersion ${id}`);
        reject();
      });
  });
};
//Used by Nektarios to archive a public game version!

export const deleteGameVersion = (id, redirectUrl) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    gamesApi
      .delete(`/api/design/gameVersions/${id}`)
      .then((response) => {
        dispatch({ type: DELETE_GAMEVERSION, payload: id });
        displaySuccess(`GameVersion deleted`, `GameVersion ${id} was deleted`);
        resolve();
        if (redirectUrl) {
          // ??????????????
          history.push(redirectUrl);
        }
      })
      .catch((err) => {
        displayError(err, `Error deleting gameVersion ${id}`);
        reject();
      });
  });
};
