import _ from 'lodash';
import { FETCH_GAME_ANALYTICS } from '../actions/types';

export default (state={},action) => {
    switch (action.type) {
        case FETCH_GAME_ANALYTICS:
            return action.payload;
        default:
            return state;
    }
}
