import React, { useCallback, useEffect, useRef, useState } from 'react';
import { formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { ICON_URL_DEFAULT } from './iconUrls';
// Variables

const defaultLocation = {
  // CN Tower Landmark
  lat: 39,
  lng: 22,
  radius: 1000,
};
// styles
const mapStyles = {
  width: '100%',
  height: '100px',
  backgroundColor: 'white',
  border: '2 solid lightgray',
  borderRadius: 5,
};

const getSceneMapData = ({ center, radius }) => {
  const { latitude, longitude } = center ?? {};
  return {
    center: {
      lat: latitude,
      lng: longitude,
    },
    radius,
  };
};

// function GoogleMap2(props) {
const GoogleMapObjectForm = (props) => {
  const googleMap = useRef(null);
  const marker = useRef(null);

  const lat = props.lat ?? defaultLocation.lat;
  const lng = props.lng ?? defaultLocation.lng;
  const radius = props.radius ? parseInt(props.radius) : defaultLocation.radius;
  const inScenes = props.inScenes ?? [];

  const [sceneCircles, setSceneCircles] = useState([]);

  useEffect(() => {
    const createSceneCircles = () => {
      const circles = [];
      inScenes.forEach((scene) => {
        if (!scene.universe) {
          const sceneCircle = new window.google.maps.Circle({
            strokeColor: '#0c1852',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#abccf0',
            fillOpacity: 0.35,
            map: googleMap.current,
            ...getSceneMapData(scene.place),
          });
          circles.push(sceneCircle);
        }
      });
      setSceneCircles(circles);
    };
    if (googleMap.current) {
      createSceneCircles();
    }
  }, [googleMap, inScenes]);

  const repositionMarker = useCallback(() => {
    let latLng = new window.google.maps.LatLng(parseFloat(lat), parseFloat(lng));
    marker.current.setPosition(latLng);
    if (!googleMap.current?.getBounds()?.contains(latLng)) {
      googleMap.current.setCenter(latLng);
    }
  }, [lat, lng]);

  const initMap = useCallback(() => {
    // helper functions
    const createGoogleMap = () =>
      new window.google.maps.Map(document.getElementById('rollick-object-map'), {
        zoom: 14,
        center: {
          lat: parseFloat(lat),
          lng: parseFloat(lng),
        },
      });

    const createMarker = (iconUrl) => {
      const markerOptions = {
        position: { lat: parseFloat(lat), lng: parseFloat(lng) },
        map: googleMap.current,
        //label:'skata',
        draggable: props.onMarkerDrag ? true : false,
      };
      if (iconUrl) {
        markerOptions.icon = {
          url: iconUrl ? iconUrl : ICON_URL_DEFAULT,
        };
      }
      const marker = new window.google.maps.Marker(markerOptions);
      if (props.onMarkerDrag) {
        window.google.maps.event.addListener(marker, 'dragend', function () {
          props.onMarkerDrag(marker.getPosition().lat(), marker.getPosition().lng());
        });
      }

      return marker;
    };

    if (googleMap.current === null) {
      const map = createGoogleMap();
      const circles = [];
      inScenes.forEach((scene) => {
        if (!scene.universe) {
          const sceneMapData = getSceneMapData(scene.place);
          const sceneCircle = new window.google.maps.Circle({
            strokeColor: '#0c1852',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#abccf0',
            fillOpacity: 0.35,
            visible: true,
            map: map,
            ...sceneMapData,
          });
          circles.push(sceneCircle);
        }
      });
      setSceneCircles(circles);
      googleMap.current = map;
    }
    if (marker.current === null) {
      marker.current = createMarker(props.inconUrl);
    } else {
      repositionMarker();
    }
  }, [inScenes, lat, lng, props, repositionMarker]);

  // useEffect Hook
  useEffect(() => {
    setTimeout(initMap, 500);
  }, [initMap, lat, lng, radius]);

  return <div id='rollick-object-map' className='google-map-location-form' style={{ ...mapStyles, ...props.style }} />;
};

const mapStateToProps = (state, ownProps) => {
  if (ownProps.formName) {
    const selector = formValueSelector(ownProps.formName);
    return selector(state, 'lat', 'lng', 'radius');
  }
  return {};
};
export default connect(mapStateToProps)(GoogleMapObjectForm);

//export default GoogleMap2;
