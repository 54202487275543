export const QTYPE_OPEN = 'openEnded';
export const QTYPE_MC = 'MC';
//export const QTYPE_BINARY = 'binary';

export const DTYPE_NUMBER = 'number';
export const DTYPE_STEXT = 'stext';
export const DTYPE_MTEXT = 'mtext';

export const ACTION_TYPE_SHARE = 'ACTION_TYPE_SHARE';
export const ACTION_TYPE_CAPTURE = 'ACTION_TYPE_CAPTURE';
// export const ACTION_MEDIA_TYPE_TEXT = 'text';
// export const ACTION_MEDIA_TYPE_VIDEO = 'video';
// export const ACTION_MEDIA_TYPE_IMAGE = 'image';