/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { find } from 'lodash';
import { connect, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { Card, Modal, Dropdown, Button, Accordion, Image } from 'react-bootstrap';
import ReactJson from 'react-json-view';

import GamePeriodForm from '../forms/GamePeriodForm';
import MainContent from '../layouts/MainContent';
import PlayersMap from '../gmap/PlayersMap';
import UserInfoModal from '../users/UserInfoModal';
import { fetchGame, editInstancePlayingPeriod, terminateInstance, deleteInstance } from '../../actions/gamesActions';
import { fetchInstance } from '../../actions/gameInstancesActions';
import { getExistingItemList, getMap } from '../initialState/ManageInitialStateItems';
import { setGameInstanceWorldStateItem, deleteGameInstanceWorldStateItem } from '../../actions/initialStateActions';
import { bconfirm } from '../utils/ConfirmAlert';
import CustomToggle from '../utils/CustomToggle';
import ObjectForm from '../forms/ObjectForm';
import OfferObjectForm from '../forms/OfferObjectForm';
import { fetchGameMedias } from '../../actions/mediasActions';

const ActiveGame = ({ app, fetchInstance, dispatch, match, history, location }) => {
  const { instanceId, activeGameId } = match.params;
  const [objectsFilter, setObjectsFilter] = useState('all');
  useEffect(() => {
    dispatch(fetchInstance(instanceId));
    dispatch(fetchGame(activeGameId));
    dispatch(fetchGameMedias(activeGameId));
  }, [activeGameId, app, dispatch, fetchInstance, instanceId]);

  const instance = useSelector((state) => state.gameInstances.instance);
  const game = useSelector((state) => state.games[activeGameId]);
  const [sendMessageModal, setSendMessageModal] = useState({
    isOpen: false,
    gameVersion: {},
  });
  const [terminateInstancesModal, setTerminateInstancesModal] = useState({
    isOpen: false,
    gameInstance: {},
  });
  const [deleteInstancesModal, setDeleteInstancesModal] = useState({
    isOpen: false,
    gameInstance: {},
  });
  const [playerModal, setPlayerModal] = useState({ isOpen: false, player: {} });
  const [editPeriodModal, setEditPeriodModal] = useState({ isOpen: false });
  const [objectModal, setObjectModal] = useState({
    isOpen: false,
    type: null,
    object: null,
  });

  const onChangePeriodSubmit = (formValues) => {
    dispatch(editInstancePlayingPeriod(instance._id, formValues))
      .then(() => {
        dispatch(fetchInstance(instanceId));
        setEditPeriodModal({ isOpen: false });
      })
      .catch((e) => {});
  };

  const onObjectItemClick = (object, type) => {
    console.log('Editing Object:', { object });
    setObjectModal({ isOpen: true, object, type });
  };

  const onObjectSubmit = (formValues) => {
    dispatch(setGameInstanceWorldStateItem(instance._id, formValues))
      .then(() => {
        setObjectModal({ isOpen: false, object: null, type: null });
        dispatch(fetchInstance(instanceId));
        // history.push(`/games/${game.id}/initialState/${type}/0${versionIdQueryPart}`);
      })
      .catch((e) => {
        /* do nothing. An error toastr should have been displayed */
      });
  };

  const onObjectDelete = async (type) => {
    const result = await bconfirm('Are you really sure?', {
      cancelButtonStyle: 'secondary',
    });
    if (result) {
      const { object } = objectModal;
      dispatch(deleteGameInstanceWorldStateItem(instanceId, `has.${type.slice(0, -1)}.${object.sceneId}.${object.id}`))
        .then(() => {
          setObjectModal({ isOpen: false, object: null, type: null });
          dispatch(fetchInstance(instanceId));
        })
        .catch((e) => {
          /* do nothing. A toastr notification should have been displayed */
        });
    }
  };

  const onTerminateConfirm = (formValues) => {
    dispatch(terminateInstance(instance._id))
      .then(() => {
        dispatch(fetchInstance(instanceId));
        setTerminateInstancesModal({ isOpen: false });
      })
      .catch((e) => {
        /* do nothing. An error toastr should have been displayed */
      });
  };

  const onDeleteConfirm = (formValues) => {
    dispatch(deleteInstance(instance._id))
      .then(() => {
        history.push(`/active-games/${game._id}`);
        setDeleteInstancesModal({ isOpen: false });
      })
      .catch((e) => {
        /* do nothing. An error toastr should have been displayed */
      });
  };

  const onMessageSend = (e) => {};

  const showEditPeriodModal = (e, gameVersion) => {
    e.preventDefault();
    setEditPeriodModal({ isOpen: true });
  };

  if (!instance || !game) {
    return 'Loading...';
  }

  let gameSpec = instance && instance.gameVersion.game;
  const scenes = gameSpec && gameSpec.spec && gameSpec.spec.script.scenes;
  const versionIdQueryPart = instance.gameVersion._id;

  const showPlayerInfo = (e, player) => {
    e.preventDefault();
    setPlayerModal({ isOpen: true, player });
  };

  const showTerminateModal = (e, gameInstance) => {
    e.preventDefault();
    setTerminateInstancesModal({ isOpen: true, gameInstance });
  };

  const showDeleteModal = (e, gameInstance) => {
    e.preventDefault();
    setDeleteInstancesModal({ isOpen: true, gameInstance });
  };

  const isMultiplayer = instance.gameVersion.game.metadata.multiplayer;
  const worldState = instance.state.worldState;

  let gameCurrency = null;
  if (instance.gameVersion.game.spec.market && instance.gameVersion.game.spec.market.available) {
    gameCurrency = find(instance.gameVersion.game.spec.resources.items, (i) => i.id === instance.gameVersion.game.spec.market.currency);
  }

  return (
    <MainContent hasSideBar={false}>
      <Card className='shadow-sm'>
        <Card.Header className='d-flex font-weight-bold align-items-center bg-rlck-primary txt-white'>
          Game: {game.title} / Instance: {instance._id}
          <div className='ml-auto'>
            <Dropdown>
              <Dropdown.Toggle variant='info' id='dropdown-basic'>
                Actions
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item disabled={worldState && worldState.status === 'terminated'} onClick={(e) => showTerminateModal(e, instance)}>
                  <span className='text-danger'>Terminate instance</span>
                </Dropdown.Item>
                <Dropdown.Item onClick={(e) => showDeleteModal(e, game)}>
                  <span className='text-danger'>Delete instance</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Card.Header>
      </Card>

      <div className='row'>
        <div className='col-12 col-md-6'>
          <div className='mb-2'>
            <span className='text-secondary'>Game:</span> <span className={'text-capitalize'}>{instance.gameVersion.game.title}</span>
          </div>

          <div className='mb-2'>
            <span className='text-secondary'>Instance ID:</span> <span className={'text-capitalize'}>{instance && instance._id}</span>
          </div>

          <div className='mb-2'>
            <span className='text-secondary'>Status:</span> <span className={'text-capitalize'}>{worldState.status}</span>
          </div>

          <div className='mb-2'>
            <span className='text-secondary'>Multiplayer:</span> {isMultiplayer ? 'Yes' : 'No'}
          </div>

          <div className='mb-2'>
            <span className='text-secondary'>Notes:</span> {instance.notes ? instance.notes : '-'}
          </div>
        </div>

        <div className='col-12 col-md-6'>
          <div className='mb-2'>
            <span className='text-secondary'>Started at:</span> <time>{moment(instance.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</time>
          </div>

          <div className='mb-2'>
            <span className='text-secondary'>Last updated:</span> <time>{moment(instance.updatedAt).format('MMMM Do YYYY, h:mm:ss a')}</time>
          </div>

          <div className='mb-2'>
            <span className='text-secondary'>Market enabled:</span> {instance.gameVersion.game.spec.market.available ? 'Yes' : 'No'}
          </div>
          <div className='mb-2'>
            <span className='text-secondary'>Market currency:</span>{' '}
            {(gameCurrency && instance.gameVersion.game.spec.market && (
              <span>
                <img height='28' src={gameCurrency.iconUri} /> {gameCurrency.title}
              </span>
            )) ||
              '-'}
          </div>
        </div>
      </div>

      <Accordion defaultActiveKey='0'>
        <Card className='mt-4'>
          <Card.Header>
            <CustomToggle eventKey={0 + ''} clickText={`Players`} style={{ color: 'purple', fontSize: '1.2em' }}></CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey='0'>
            <Card.Body>
              <div className='mb-2'>
                <div className='mb-2 d-flex flex-wrap'>
                  {instance.players.map((player) => {
                    const playerState = instance.state.usersState[player.id];
                    playerState.id = player.id;
                    return (
                      <div className='my-1 p-2 border d-flex flex-column align-items-center col col-3' key={player.id}>
                        <Image src={playerState.avatar?.photo?.uri} alt='' roundedCircle width={20} height={20} className='m-0 mr-1 border' />
                        <a
                          href='#'
                          onClick={(e) =>
                            showPlayerInfo(e, {
                              ...player,
                              state: playerState,
                            })
                          }>
                          {player.name}
                        </a>
                        <span className='text-secondary'> [{playerState.status}]</span>
                      </div>
                    );
                  })}
                </div>
                <PlayersMap
                  onRefresh={() => {
                    dispatch(fetchInstance(instanceId));
                  }}
                  usersState={Object.values(instance.state.usersState)}
                  onPlayerMarkerClick={(e, player) => {
                    const playerState = instance.state.usersState[player.id];
                    showPlayerInfo(e, {
                      ...player,
                      state: playerState,
                    });
                  }}
                />
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>

        <Card className='mt-4'>
          <Card.Header>
            <CustomToggle eventKey={1 + ''} clickText={`World State`} style={{ color: 'purple', fontSize: '1.2em' }}></CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey='1'>
            <Card.Body>
              <div className='row'>
                <div className='col-12'>
                  <Card className='mt-4'>
                    <Card.Header className='d-flex'>
                      <select
                        id='show-type'
                        value={objectsFilter}
                        className='ml-3 rounded-sm border-1'
                        onChange={(e) => {
                          setObjectsFilter(e.target.value);
                        }}>
                        {[
                          { value: 'all', label: 'All Objects' },
                          { value: 'notApproved', label: 'Not Approved Objects' },
                        ].map((f) => (
                          <option value={f.value} key={f.value}>
                            {f.label}
                          </option>
                        ))}
                      </select>
                      <Button className='ml-auto' onClick={() => setObjectModal({ isOpen: true, type: 'objects' })}>
                        <i className='fas fa-plus mr-2' />
                        Add Object
                      </Button>
                    </Card.Header>
                    <Card.Body>
                      <div className='row'>
                        <div className='col-12 col-md-5'>
                          <ul className='scene-objects-list'>
                            {getExistingItemList(
                              {
                                gameId: activeGameId,
                                id: '',
                                state: instance.state.worldState,
                                _id: '',
                              },
                              scenes,
                              'object',
                              game,
                              'objects',
                              versionIdQueryPart,
                              gameSpec,
                              null,
                              {
                                onItemClick: onObjectItemClick,
                                filterFn: (obj) => {
                                  return objectsFilter === 'all' ? true : obj.moderated && !obj.approved;
                                },
                              }
                            )}
                          </ul>
                        </div>
                        <div className='col-12 col-md-7'>
                          {getMap(
                            gameSpec,
                            scenes,
                            {
                              gameId: activeGameId,
                              id: '',
                              state: instance.state.worldState,
                              _id: '',
                            },
                            'object',
                            'objects',
                            '300px'
                          )}
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </div>

                <div className='col-12 mt-4'>
                  <Card className='mt-4'>
                    <Card.Header className='d-flex'>
                      Offers
                      <Button className='ml-auto' onClick={() => setObjectModal({ isOpen: true, type: 'offers' })}>
                        <i className='fas fa-plus mr-2' />
                        Add Offer
                      </Button>
                    </Card.Header>
                    <Card.Body>
                      <div className='row'>
                        <div className='col-12 col-md-5'>
                          <ul className='scene-objects-list'>
                            {getExistingItemList(
                              {
                                gameId: activeGameId,
                                id: '',
                                state: instance.state.worldState,
                                _id: '',
                              },
                              scenes,
                              'offer',
                              game,
                              'offers',
                              versionIdQueryPart,
                              gameSpec,
                              null,
                              { onItemClick: onObjectItemClick }
                            )}
                          </ul>
                        </div>
                        <div className='col-12 col-md-7'>
                          {getMap(
                            gameSpec,
                            scenes,
                            {
                              gameId: activeGameId,
                              id: '',
                              state: instance.state.worldState,
                              _id: '',
                            },
                            'offer',
                            'offers',
                            '300px'
                          )}
                        </div>
                      </div>
                    </Card.Body>
                  </Card>

                  <hr />
                  <ReactJson enableClipboard={false} collapsed name={null} displayDataTypes={false} src={instance.state.worldState} />
                </div>
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>

      <UserInfoModal
        isOpen={playerModal.isOpen}
        player={playerModal.player}
        onHide={() => setPlayerModal({ isOpen: false, player: {} })}
        showStateTree
        spec={gameSpec.spec}
        gameSpec={gameSpec}
        gameId={game.id}
      />

      {sendMessageModal.isOpen && (
        <Modal show={sendMessageModal.isOpen} onHide={() => setSendMessageModal({ isOpen: false, game: {} })}>
          <Modal.Header closeButton>
            <Modal.Title>Send Message</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className='mb-2'>
              <form onSubmit={onMessageSend}>
                <div className='form-group'>
                  <label htmlFor='sceneId'>Scene</label>
                  <select className='form-control' name='sceneId'>
                    <option value=''>All Scenes</option>
                    {scenes.map((scene) => (
                      <option key={scene.id} value={scene.id}>
                        {scene.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className='form-group'>
                  <label htmlFor='message'>Message</label>
                  <textarea onChange={() => {}} style={{ height: '200px' }} placeholder='Type your massage here...' name='message' className='form-control'></textarea>

                  <div className='text-right mt-3'>
                    <button type='submit' className='btn btn-primary'>
                      Send message
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {terminateInstancesModal.isOpen && (
        <Modal show={terminateInstancesModal.isOpen} onHide={() => setTerminateInstancesModal({ isOpen: false, gameinstance: {} })}>
          <Modal.Header closeButton>
            <Modal.Title>Terminate instance</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            This instance of <strong>{game.title}</strong> will get terminated.
            <br />
            Please confirm action.
            <div className='text-right mt-5'>
              <button type='button' onClick={onTerminateConfirm} className='btn btn-primary'>
                Confirm
              </button>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {deleteInstancesModal.isOpen && (
        <Modal show={deleteInstancesModal.isOpen} onHide={() => setDeleteInstancesModal({ isOpen: false, gameinstance: {} })}>
          <Modal.Header closeButton>
            <Modal.Title>Terminate instance</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            This instance of <strong>{game.title}</strong> will be deleted.
            <br />
            Please confirm action.
            <div className='text-right mt-5'>
              <button type='button' onClick={onDeleteConfirm} className='btn btn-danger'>
                Delete
              </button>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {editPeriodModal.isOpen && (
        <Modal show={editPeriodModal.isOpen} onHide={() => setEditPeriodModal({ isOpen: false })}>
          <Modal.Header closeButton>
            <Modal.Title>Change Game Period</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className='mb-4 text-warning p-4 bg-light'>Be aware! All instances with the same game version will get updated.</div>

            <div>
              <GamePeriodForm
                enableReinitialize={true}
                handleCancel={() => setEditPeriodModal({ isOpen: false })}
                initialValues={{
                  playingPeriod: game && game.playingPeriod,
                }}
                onSubmit={onChangePeriodSubmit}
              />
            </div>
          </Modal.Body>
        </Modal>
      )}

      {objectModal.isOpen && (
        <Modal size='lg' show={objectModal.isOpen} onHide={() => setObjectModal({ isOpen: false, type: null, object: null })}>
          <Modal.Header closeButton>
            <Modal.Title>{`${objectModal.object ? 'Edit' : 'Add'} ${objectModal.type.slice(0, -1)}`}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {objectModal.type === 'objects' ? (
              <ObjectForm
                scenes={scenes}
                gameSpec={gameSpec}
                enableReinitialize={true}
                type={objectModal.type}
                handleCancel={() => setObjectModal({ isOpen: false, object: null, type: null })}
                handleDelete={() => onObjectDelete('objects')}
                game={game}
                initialValues={{
                  ...objectModal.object,
                }}
                onSubmit={onObjectSubmit}
              />
            ) : (
              <OfferObjectForm
                scenes={scenes}
                gameSpec={gameSpec}
                enableReinitialize={true}
                type={objectModal.type}
                handleCancel={() => setObjectModal({ isOpen: false, object: null, type: null })}
                handleDelete={() => onObjectDelete('offers')}
                game={game}
                initialValues={{
                  ...objectModal.object,
                }}
                onSubmit={onObjectSubmit}
              />
            )}
          </Modal.Body>
        </Modal>
      )}
    </MainContent>
  );
};

const mapStateToProps = (state) => {
  return {
    errors: state.errors,
  };
};
//export default connect(mapStateToProps, {fetchMyGames, deleteGame})(GameList);
export default withRouter(connect(mapStateToProps, (dispatch) => ({ dispatch, fetchInstance }))(ActiveGame));
