import gamesApi from "../apis/gamesApi";
import history from "../history";
import {
    FETCH_GAME_SCENES,
    FETCH_SCENE,
    EDIT_SCENE,
    CREATE_SCENE,
    DELETE_SCENE
} from "./types";
import {validateCoords} from "../utils/utils";
import {displayError, displaySuccess} from "../components/utils/notifications";


export const createScene = (gameId, formValues) =>  async (dispatch) => {
    validateCoords(formValues, true);

    return new Promise((resolve, reject) => {
        gamesApi.post(`/api/design/scenes?gameId=${gameId}`,{...formValues, gameId})
            .then(response => {
                dispatch({type:CREATE_SCENE, payload:response.data});
                displaySuccess(`Scene ${response.data.name} created`);
                resolve();
                //history.push(`/games/${gameId}`);
            })
            .catch(err => {
                displayError(err, `Error creating scene`);
                reject();
            });
    });

};

export const fetchGameScenes = (gameId) => async (dispatch) => {
    // console.log("fetchGameScenes action with gameId "+gameId);
    // const response = await gamesApi.get(`/scenes?gameId=${gameId}`);
    // dispatch({type: FETCH_SCENES, payload: response.data});
    if (gameId) {
        return new Promise((resolve, reject) => {
            gamesApi.get(`/api/design/scenes?gameId=${gameId}`)
                .then(response => {
                    dispatch({type:FETCH_GAME_SCENES, payload:response.data});
                    resolve();
                })
                .catch(err => {
                    displayError(err, `Error retrieving game scenes`);
                    reject();
                });
        });
    }
    else {
        return new Promise((resolve, reject) => {
            dispatch({type: FETCH_GAME_SCENES, payload: []});
            displayError(null, `No gameId id in fetchGameScenes`);
            reject();
        });
    }
};

export const fetchScene = (id) =>  async (dispatch) => {
    return new Promise((resolve, reject) => {
        gamesApi.get(`/api/design/scenes/${id}`)
            .then(response => {
                dispatch({type:FETCH_SCENE, payload:response.data});
                resolve();
            })
            .catch(err => {
                displayError(err, `Error retrieving scene data for id ${id}`);
                reject();
            });
    });
};
export const editScene = (id, formValues) =>  async (dispatch) => {
    validateCoords(formValues, false);

    return new Promise((resolve, reject) => {
        gamesApi.patch(`/api/design/scenes/${id}`, formValues)
            .then(response => {
                dispatch({type:EDIT_SCENE, payload:response.data});
                displaySuccess(`Scene ${response.data.name} saved`);
                resolve();
                // history.push(`/games/${gameId}`);
            })
            .catch(err => {
                displayError(err, `Error saving scene ${id}`);
                reject();
            });
    });
};
export const deleteScene = (id, redirectUrl) =>  async (dispatch) => {
    // await gamesApi.delete(`/scenes/${id}`);
    // dispatch({type:DELETE_SCENE, payload:id});
    // if (redirectUrl) {
    //     history.push(redirectUrl);
    // }

    return new Promise((resolve, reject) => {
        gamesApi.delete(`/api/design/scenes/${id}`)
            .then(response => {
                dispatch({type:DELETE_SCENE, payload:id});
                displaySuccess(`Scene deleted`, `Scene ${id} was deleted`);
                resolve();
                if (redirectUrl) { // ??????????????
                    history.push(redirectUrl);
                }
            })
            .catch(err => {
                displayError(err, `Error deleting scene ${id}`);
                reject();
            });
    });
};
