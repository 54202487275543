import React from "react";
import renderError from "./renderError";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import G4mTooltip from "../../utils/G4mTooltip";


const renderCheckbox2 = ({input, label, meta, asCol, addonId, addonLabel, disabled, inline, tooltip, containerControlClass}) => {
    const className = `${meta.error && meta.touched ? 'error':''}`;

    const disabledProp = {};
    if (disabled) {
        disabledProp.disabled = 'disabled'; //
    }
    const controlId = addonId?input.name+addonId:input.name;
    const addonLabelElement = addonLabel?<label>{addonLabel}</label>:'';
    let tooltipControl = null;
    const inlinedProp = {};
    const isValidProp = {};
    if (tooltip) {
        tooltipControl = <G4mTooltip tooltip={tooltip} textBefore={label} />
    }
    if (inline) {
        inlinedProp.inline = 'inline'; //
    }
    return (
        <>
            {
                // <Form.Group controlId={controlId}>
                //     {addonLabelElement}
                //     <Form.Check {...input} className={className} {...disabledProp} value={1} type="checkbox"
                //                 label={tooltipControl ? tooltipControl : label}/>
                //     {renderError(meta)}
                // </Form.Group>
            }

            <Form.Check {...inlinedProp}  type={"checkbox"} id={input.name+input.value} className={containerControlClass}>
                {addonLabelElement}
                <Form.Check.Input {...input} type={"checkbox"} {...disabledProp} value={1} {...isValidProp} className={className} />
                <Form.Check.Label>{tooltipControl?tooltipControl:label}</Form.Check.Label>
                {
                    (meta.error && meta.touched) &&
                    <Form.Control.Feedback type="invalid">{(inline?' ':'')+meta.error}</Form.Control.Feedback>
                }
            </Form.Check>
        </>
    );

};

export default renderCheckbox2;

