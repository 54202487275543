import { GAME_LOADING, SET_GAMES_FILTER, TOGGLE_SIDEBAR, TOGGLE_SIDEBAR_MENU } from '../actions/types';

export default (state = { sidebarHidden: false, menu: {}, gameLoading: false, gamesFilter: 'mine' }, action) => {
  switch (action.type) {
    case GAME_LOADING:
      return { ...state, gameLoading: action.payload };
    case SET_GAMES_FILTER:
      return { ...state, gamesFilter: action.payload };
    case TOGGLE_SIDEBAR:
      return { ...state, sidebarHidden: !state.sidebarHidden };
    case TOGGLE_SIDEBAR_MENU: {
      const item = action.payload;
      if (item) {
        return { ...state, menu: { ...state.menu, [item]: !state.menu[item] } };
      } else {
        return { ...state };
      }
    }
    default:
      return state;
  }
};
