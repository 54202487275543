import React, {useState} from 'react';
import history from "../../history";
import CollapseItems from "../widgets/CollapseItems";
//import {bconfirm} from "../utils/ConfirmAlert";
//import {deleteInteraction} from "../../actions/interactionsActions";
import InteractionEdit from './InteractionEdit';
import ModalEdit from "../utils/ModalEdit";
import {connect} from "react-redux";
import '../../i18n';
import { useTranslation } from 'react-i18next';
import {INTER_CONFIRM, INTER_INSPECT_INFO, INTER_ANSWER_QUESTION, INTER_QUIZ, INTER_CONVERSATION} from './interactionTypes'


const InteractionList = ({interactions, game, displayAs, dispatch}) => {
    const { t, i18n } = useTranslation();
    const [openEditInteractionModal, setOpenEditInteractionModal] = useState(false);
    const [newInteractionType, setNewInteractionType] = useState(null);
    const [selectedInteractionToEdit, setSelectedInteractionToEdit] = useState({});
    const closeEditInteractionModal = () => {
        setOpenEditInteractionModal(false);
    };
    const cancelEditInteractionModal = () => {
        setOpenEditInteractionModal(false);
    };
    const newItemHandlerModal = (type) => {
        setSelectedInteractionToEdit(null);
        setNewInteractionType(type);
        setOpenEditInteractionModal(true);
        //history.push(`/games/${game.id}/interactions/create/0/${scene.id}`);
    }
    const editItemHandlerModal = (id) => {
        const interaction = interactions.find(element => element.id === id);
        setSelectedInteractionToEdit(interaction);
        setOpenEditInteractionModal(true);
        //history.push(`/games/${game.id}/interactions/edit/${id}`);
    }
    const newItemHandler = (type) => {
        history.push(`/games/${game.id}/interactions/create/0/${type}`);
    }
    const editItemHandler = (id) => {
        history.push(`/games/${game.id}/interactions/edit/${id}`);
    }
    // const deleteItemHandler = async (itemId) => {
    //     const result = await bconfirm('Are you really sure?', {cancelButtonStyle: 'secondary'});
    //     if (result) {
    //         dispatch(deleteInteraction(itemId))
    //             .then(() => {
    //                 /* do nothing */
    //             })
    //             .catch((e) => {
    //                 /* do nothing. A toastr notification should have been displayed */
    //             });
    //     }
    // }

    const itemListGrouped = [];
    itemListGrouped[INTER_INSPECT_INFO] = [];
    itemListGrouped[INTER_CONFIRM] = [];
    itemListGrouped[INTER_ANSWER_QUESTION] = [];
    itemListGrouped[INTER_QUIZ] = [];
    itemListGrouped[INTER_CONVERSATION] = [];


    for (const item of interactions) {
        if (game && item.gameId && item.gameId.toString() === game.id.toString()) {
            if (typeof itemListGrouped[item.type] === 'undefined' && item.type!=='INTER_MSN') { // mpakalia gia palies eggrafes typoy MSN na tis agnohsei
                alert ('sto InteractionList leipei to array gia '+item.type);
            }
            if (item.type!=='INTER_MSN') {  // mpakalia gia palies eggrafes typoy MSN na tis agnohsei
                itemListGrouped[item.type].push({id: item.id, name: item.name});
            }
        }
    }

    if (displayAs === 'collapse') {
        return (
            <>
                <div className="gameelementscontainer row">

                {
                    Object.keys(itemListGrouped).map(group => {
                        return (
                            <CollapseItems key={"interactionlist"+group} identifier={"interactionlist"+group} heading={t(group)} items={itemListGrouped[group]}
                                           newItemHandler={newItemHandler}
                                           newItemArgument={group}
                                           newLabel="New"
                                           editItemHandler={editItemHandler}
                                           deleteItemHandler={null}
                                           editLabel="Settings"
                                           deleteLabel="Delete"
                                           additionalClass="sideBar"
                            />
                        )
                    })
                }
                </div>

                <ModalEdit openEditModal={openEditInteractionModal} closeEditModal={closeEditInteractionModal}
                           heading={"Edit or create game element"}
                           portal="interaction">
                    <InteractionEdit game={game} interaction={selectedInteractionToEdit}
                                     newInteractionType={newInteractionType}
                                     onSave={closeEditInteractionModal}
                                     onCancel={cancelEditInteractionModal}
                    />
                </ModalEdit>
            </>
        );
    }
    return <>No displayAs</>
}

export default connect(null,null)(InteractionList);
