import React from 'react';


const Privacy = () => {

    return (
        <>
<div>
            <p>Privacy Policy of&nbsp;Rollick&nbsp;Application</p>
            <p>Last modified: July 6, 2021</p>
            <p>This Application collects some Personal Data from its Users. By using this Application, you agree that we
                can collect and use such content and information in accordance with this Privacy Policy as amended from
                time to time.</p>
            <p>The Owner of this Application is the Technical University of Crete - Greece</p>
            <p>Contact</p>
            <p>Technical University of Crete</p>
            <p>University Campus, Akrotiri</p>
            <p>73100 Chania</p>
            <p>GREECE</p>
            <p>Rector email:</p>
            <p>rector@central.tuc.gr</p>
            <p>Deputy Rector of Finance, Planning and Development</p>
            <p><a href="mailto:vice-rector-fin@central.tuc.gr">vice-rector-fin@central.tuc.gr</a></p>
            <p>Website: www.tuc.gr</p>
            <p>POLICY SUMMARY</p>
            <p>Personal Data collected for the following purposes and using the following services:</p>
            <table>
                <tbody>
                <tr>
                    <td colspan="1" rowspan="1">
                        <p>Displaying content from external platforms</p>
                        <p>Google Maps widget</p>
                        <p>Apple Maps</p>
                        <p>Personal Data: Cookies and Usage data</p>
                    </td>
                    <td colspan="1" rowspan="1">
                        <p>Location-based interactions</p>
                        <p>Geolocation</p>
                        <p>Personal Data: geographic position</p>
                    </td>
                </tr>
                <tr>
                    <td colspan="1" rowspan="1">
                        <p>Registration and authentication</p>
                        <p>Facebook Authentication</p>
                        <p>Personal Data: various types of Data as specified in the privacy policy of the service</p>
                    </td>
                    <td colspan="1" rowspan="1">
                        <p>Access to third party services' accounts</p>
                        <p>Access to the Facebook account</p>
                        <p>Permissions: About Me and Email</p>
                    </td>
                </tr>
                <tr>
                    <td colspan="1" rowspan="1">
                        <p>Device information</p>
                        <p>We collect information from or about the computers, phones, or other devices where you
                            install or access this Application</p>
                    </td>
                    <td colspan="1" rowspan="1">
                        <p>Capture things you do and collect information you provide</p>
                        <p>Collect the content and other information you provide</p>
                    </td>
                </tr>
                </tbody>
            </table>
            <p>Further information about Personal Data</p>
            <table>
                <tbody>
                <tr>
                    <td colspan="1" rowspan="1">
                        <p>Push notifications</p>
                        <p>This Application may&nbsp;send push notifications to the User.</p>
                    </td>
                    <td colspan="1" rowspan="1">
                        <p>Instant Messaging</p>
                        <p>This application may send instant messages to the User.</p>
                    </td>
                </tr>
                </tbody>
            </table>
            <hr/>
            <p>FULL POLICY</p>
            <p>I. Definitions</p>
            <p>Personal Data (or Data)</p>
            <p>Any information regarding a natural person, a legal person, an institution or an association, which is,
                or can be, identified, even indirectly, by reference to any other information, including a personal
                identification number.</p>
            <p>Data Subject</p>
            <p>The legal or natural person to whom the Personal Data refers.</p>
            <p>Processing of Personal Data</p>
            <p>Any operation or set of operations which is performed upon personal data, whether or not by automatic
                means, such as collection, recording, organisation, storage, alteration, use, disclosure by
                transmission, dissemination or otherwise making available, combination, blocking, erasure,
                destruction.</p>
            <p>Informed consent of the data subject</p>
            <p>Any freely given specific and informed indication of his or her wishes by which the data subject
                signifies his or her agreement to personal data relating to him or her being processed.</p>
            <p>Cookies</p>
            <p>Small piece of data stored in the User's device.</p>
            <p>Usage Data</p>
            <p>Information collected automatically from this Application (or third party services employed in this
                Application), which can include: the IP addresses or UUID (Universally Unique Identifier) or domain
                names of the devices utilized by the Users who use this Application, the URI addresses (Uniform Resource
                Identifier), the time of the request, the method utilized to submit the request to the server, the size
                of the file received in response, the numerical code indicating the status of the server's answer
                (successful outcome, error, etc.), the country of origin, the features of the browser and the operating
                system utilized by the User, the various time details per visit (e.g., the time spent on each page
                within the Application) and the details about the path followed within the Application with special
                reference to the sequence of pages visited, and other parameters about the device operating system
                and/or the User's IT environment.</p>
            <p>This Application</p>
            <p>The hardware or software tool by which the Personal Data of the User is collected.</p>
            <p>User</p>
            <p>The individual using this Application, which must coincide with or be authorized by the Data Subject, to
                whom the Personal Data refers.</p>
            <p>Data Processor (or Data Supervisor)</p>
            <p>The natural person, legal person, public administration or any other body, association or organization
                authorized by the Data Controller to process the Personal Data in compliance with this privacy
                policy.</p>
            <p>Data Controller (or Owner)</p>
            <p>The natural person, legal person, public administration or any other body, association or organization
                with the right, also jointly with another Data Controller, to make decisions regarding the purposes, and
                the methods of processing of Personal Data and the means used, including the security measures
                concerning the operation and use of this Application. The Data Controller, unless otherwise specified,
                is the Owner of this Application.</p>
            <p>II. About the Data Collection and the Data Processing</p>
            <p>Types of Data collected</p>
            <p>Among the types of Personal Data that this Application collects, by itself or through third parties,
                there are: Cookies, Usage data and geographic position.</p>
            <p>Other Personal Data collected may be described in other sections of this privacy policy or by dedicated
                explanation text contextually with the Data collection.</p>
            <p>The Personal Data may be freely provided by the User, or collected automatically when using this
                Application.</p>
            <p>Any use of Cookies - or of other tracking tools - by this Application or by the owners of third party
                services used by this Application, unless stated otherwise, serves to identify Users and remember their
                preferences, for the sole purpose of providing the service required by the User.</p>
            <p>Failure to provide certain Personal Data may make it impossible for this Application to provide its
                services.</p>
            <p>Mode and place of processing the Data</p>
            <p>Methods of processing</p>
            <p>The Data Controller processes the Data of Users in a proper manner and shall take appropriate security
                measures to prevent unauthorized access, disclosure, modification, or unauthorized destruction of the
                Data.</p>
            <p>The Data processing is carried out using computers and/or IT enabled tools, following organizational
                procedures and modes strictly related to the purposes indicated. In addition to the Data Controller, in
                some cases, the Data may be accessible to certain types of persons in charge, involved with the
                operation of the Application and the back system (administration, legal, system administration) or
                external parties (such as third party technical service providers, mail carriers, hosting providers, IT
                companies, communications agencies) appointed, if necessary, as Data Processors by the Owner. &nbsp;</p>
            <p>Place</p>
            <p>The Data is processed at the Data Controller's operating offices and in any other places where the
                parties involved with the processing are located. For further information, please contact the Data
                Controller.</p>
            <p>Retention time</p>
            <p>The Data is kept for the time necessary to provide the service requested by the User, or stated by the
                purposes outlined in this document, and the User can always request that the Data Controller suspend or
                remove the data.</p>
            <p>The use of the collected Data</p>
            <p>The Data concerning the User is collected to allow the Owner to provide its services, as well as for the
                following purposes: Registration and authentication, Displaying content from external platforms,
                Location-based interactions, Access to third party services' accounts, Capture things you do and collect
                information you provide, Device information and Push notifications.</p>
            <p>The Personal Data used for each purpose is outlined in the specific sections of this document.</p>
            <p>Facebook permissions asked by this Application</p>
            <p>This Application may ask for some Facebook permissions allowing it to perform actions with the User's
                Facebook account and to retrieve information, including Personal Data, from it.</p>
            <p>For more information about the following permissions, refer to the Facebook permissions documentation and
                to the Facebook privacy policy.</p>
            <p>The permissions asked are the following:</p>
            <ul>
                <li>Basic information</li>
            </ul>
            <p>By default, this includes certain User&rsquo;s Data such as id, name, picture, gender, and their locale.
                Certain connections of the User, such as the Friends, are also available. If the User has made more of
                their Data public, more information will be available.</p>
            <ul>
                <li>About Me</li>
            </ul>
            <p>Provides access to the 'About Me' section of the profile.</p>
            <ul>
                <li>Email</li>
            </ul>
            <p>Provides access to the User's primary email address.</p>
            <hr/>
            <p>III. Detailed information on the processing of Personal Data</p>
            <p>Personal Data is collected for the following purposes and using the following services:</p>
            <table>
                <tbody>
                <tr>
                    <td colspan="1" rowspan="1">
                        <p>Displaying content from external platforms</p>
                    </td>
                    <td colspan="1" rowspan="1">
                        <p>Location-based interactions</p>
                    </td>
                </tr>
                <tr>
                    <td colspan="1" rowspan="1">
                        <p>This type of service allows you to view content hosted on external platforms directly from
                            the pages of this Application and interact with them.</p>
                        <p>This type of service might still collect web traffic data for the pages where the service is
                            installed, even when Users do not use it.</p>
                        <p>Google Maps widget (Google Inc.)</p>
                        <p>Google Maps is a maps visualization service provided by Google Inc. that allows this
                            Application to incorporate content of this kind on its pages.</p>
                        <p>Personal Data collected: Cookies and Usage data.</p>
                        <p>Place of processing: US &ndash; Privacy Policy (<a
                            href="https://www.google.com/url?q=https://www.google.it/intl/en/policies/privacy/&amp;sa=D&amp;source=editors&amp;ust=1633328452743000&amp;usg=AOvVaw1bX2kx1lAvTQBXEkgMefCv">https://www.google.it/intl/en/policies/privacy/</a>)
                        </p>
                        <p>Apple Maps (Apple Inc.)</p>
                        <p>Apple Maps is a web mapping service developed by Apple Inc. It is the default map system of
                            iOS, iPadOS, macOS, and watchOS. Personalized features are created using data on your
                            device. And data that is sent from your device to the Maps service, using end-to-end
                            encryption, is associated with random identifiers so Apple doesn&rsquo;t have a profile of
                            your movements and searches. Furthermore, maps goes even further to obscure your location on
                            Apple servers when you search using a process called &ldquo;fuzzing.&rdquo;</p>
                        <p>Personal Data collected: Cookies and Usage data.</p>
                        <p>Place of processing: US &ndash; Privacy Policy (https://www.apple.com/legal/privacy/)</p>
                    </td>
                    <td colspan="1" rowspan="1">
                        <p>Geolocation</p>
                        <p>This Application may collect, use, and share User location Data in order to provide
                            location-based services.</p>
                        <p>Most browsers and devices provide tools to opt out from this feature by default. If explicit
                            authorization has been provided, the User&rsquo;s location data may be tracked by this
                            Application.</p>
                        <p>Personal Data collected: geographic position.</p>
                    </td>
                </tr>
                <tr>
                    <td colspan="1" rowspan="1">
                        <p>Registration and authentication</p>
                    </td>
                    <td colspan="1" rowspan="1">
                        <p>Access to third party services' accounts</p>
                    </td>
                </tr>
                <tr>
                    <td colspan="1" rowspan="1">
                        <p>By registering or authenticating, Users allow this Application to identify them and give them
                            access to dedicated services.</p>
                        <p>Depending on what is described below, third parties may provide registration and
                            authentication services. In this case, this Application will be able to access some Data,
                            stored by these third party services, for registration or identification purposes.</p>
                        <p>Facebook Authentication (Facebook, Inc.)</p>
                        <p>Facebook Authentication is a registration and authentication service provided by Facebook,
                            Inc. and is connected to the Facebook social network.</p>
                        <p>Personal Data collected: various types of Data as specified in the privacy policy of the
                            service.</p>
                        <p>Place of processing: US &ndash; Privacy Policy
                            (https://www.facebook.com/help/405977429438260)</p>
                    </td>
                    <td colspan="1" rowspan="1">
                        <p>This type of service allows this Application to access Data from your account on a third
                            party service and perform actions with it.</p>
                        <p>These services are not activated automatically, but require explicit authorization by the
                            User.</p>
                        <p>Access to the Facebook account (This Application)</p>
                        <p>This service allows this Application to connect with the User's account on the Facebook
                            social network, provided by Facebook, Inc.</p>
                        <p>Permissions asked: About Me and Email.</p>
                        <p>Place of processing: US &ndash; Privacy Policy</p>
                        <p>(https://www.facebook.com/policy.php)</p>
                    </td>
                </tr>
                <tr>
                    <td colspan="1" rowspan="1">
                        <p>Device information</p>
                    </td>
                    <td colspan="1" rowspan="1">
                        <p>Capture things you do and collect information you provide</p>
                    </td>
                </tr>
                <tr>
                    <td colspan="1" rowspan="1">
                        <p>We collect information from or about the computers, phones, or other devices where you
                            install this Application or access our services, depending on the permissions you&rsquo;ve
                            granted.We may associate the information we collect from your different devices, which helps
                            us provide consistent Services across your devices. Here are some examples of the device
                            information we collect:</p>
                        <ul>
                            <li>Attributes such as the operating system, hardware version, device settings, file and
                                software names and types, battery and signal strength, and device identifiers.
                            </li>
                            <li>Device locations, including specific geographic locations, such as through GPS,
                                Bluetooth, or WiFi signals.
                            </li>
                            <li>Connection information such as the name of your mobile operator or ISP, browser type,
                                language and time zone, mobile phone number and IP address.
                            </li>
                        </ul>
                    </td>
                    <td colspan="1" rowspan="1">
                        <p>This Application collects&nbsp;the content and other information you provide when you use our
                            services, including when you sign up for an account, create or share, and message or
                            communicate with others. This can include information in or about the content you provide,
                            such as the location of a photo or the date a file was created. We also collect information
                            about how you use our services, such as the types of content you view or engage with or the
                            frequency and duration of your activities.</p>
                        <p>Personal Data collected: email address, first name, last name and username.</p>
                    </td>
                </tr>
                </tbody>
            </table>
            <p>Further information about Personal Data</p>
            <table>
                <tbody>
                <tr>
                    <td colspan="1" rowspan="1">
                        <p>Push notifications</p>
                    </td>
                </tr>
                <tr>
                    <td colspan="1" rowspan="1">
                        <p>This Application may&nbsp;send push notifications to the User using Google Cloud Messaging
                            and Apple Push Notification Service</p>
                    </td>
                </tr>
                </tbody>
            </table>
            <p>IV. Additional information about Data collection and processing</p>
            <p>Legal action</p>
            <p>The User's Personal Data may be used for legal purposes by the Data Controller, in Court or in the stages
                leading to possible legal action arising from improper use of this Application or the related
                services.</p>
            <p>The User declares to be aware that the Data Controller may be required to reveal personal data upon
                request of public authorities.</p>
            <p>Additional information about User's Personal Data</p>
            <p>In addition to the information contained in this privacy policy, this Application may provide the User
                with additional and contextual information concerning particular services or the collection and
                processing of Personal Data upon request.</p>
            <p>System Logs and Maintenance&nbsp;</p>
            <p>For operation and maintenance purposes, this Application and any third party services may collect files
                that record interaction with this Application (System Logs) or use for this purpose other Personal Data
                (such as IP Address).</p>
            <p>Information not contained in this policy&nbsp;</p>
            <p>More details concerning the collection or processing of Personal Data may be requested from the Data
                Controller at any time. Please see the contact information at the beginning of this document.</p>
            <p>This Application does not support &ldquo;Do Not Track&rdquo; requests</p>
            <p>To determine whether any of the third party services it uses honor the &ldquo;Do Not
                Track&rdquo; requests, please read their privacy policies.</p>
            <p>V. Legal Provisions and References</p>
            <p>The rights of Users</p>
            <p>Users have the right, at any time, to know whether their Personal Data has been stored and can consult
                the Data Controller to learn about their contents and origin, to verify their accuracy or to ask for
                them to be supplemented, cancelled, updated or corrected, or for their transformation into anonymous
                format or to block any data held in violation of the law, as well as to oppose their treatment for any
                and all legitimate reasons. Requests should be sent to the Data Controller at the contact information
                set out above.</p>
            <p>Users&rsquo; Declaration and acknowledgment</p>
            <p>Users declare that they were clearly and properly informed and that the Data Controller provided them
                with at least the following information:</p>
            <p>a)The identity of the Data Controller</p>
            <p>b)The purposes of the processing operation</p>
            <p>c)The recipients or categories of recipients of the Data</p>
            <p>d)The existence of the right of access, the right of rectify, the right of blocking and the right to
                oppose to the Data concerning the Data Subject</p>
            <p>Users acknowledge that they gave freely their &ldquo;informed consent&rdquo; prior to install the present
                Application. That consent can be revoked at any time but with no retroactive effects.</p>
            <p>Personal Data of third parties</p>
            <p>Users are solely responsible for any Personal Data of third parties obtained, published or shared through
                this Application and confirm that they have the third party's prior consent to provide the Data to the
                Owner.</p>
            <p>Legal Restrictions</p>
            <p>It is prohibited to use the Application for the transmission, storage, publication of material that
                offends third parties&rsquo; rights or of material that has an immoral, libelous content or a content
                obtained, used and disseminated with no right and with an illegal way. In such cases, the Data
                Controller bears no responsibility for compensation against the offended persons and has the right to
                proceed with any action that can reduce the offence from the moment the Data Controller becomes aware of
                the offence.</p>
            <p>Changes to this privacy policy</p>
            <p>The Data Controller reserves the right to make changes to this privacy policy at any time by giving
                notice to its Users on this page. It is strongly recommended to check this page often, referring to the
                date of the last modification listed at the bottom. If a User objects to any of the changes to the
                Policy, the User must cease using this Application and can request that the Data Controller removes the
                Personal Data. Unless stated otherwise, the then-current privacy policy applies to all Personal Data the
                Data Controller has about Users.</p>
            <p>Information about this privacy policy</p>
            <p>The Data Controller is responsible for this privacy policy which relates solely to cNext Application and
                its services. The present Privacy Policy is not related with the privacy policies of any other third
                parties including those to whom the Application may be connected. In such a case, no acceptance or
                approval of the third parties&rsquo; privacy policy can be concluded on behalf of the Data
                Controller.</p>
            <p>Disputes</p>
            <p>You will resolve any claim, cause of action or dispute (claim) you have with us or the Data Controller
                arising out of or relating to this Policy exclusively in the local Courts of Chania, Crete and you agree
                to submit to the personal jurisdiction of such courts for the purpose of litigating all such claims. The
                Greek laws will govern this Policy, as well as any claim that might arise between you and us, without
                regard to conflict of law provisions.</p>
            <p>Legal sources</p>
            <p>Notice to European Users: this privacy statement has been prepared in accordance with the current greek
                national laws, the relevant Directives and Regulations of the European Parliament and of the Council and
                the EC Guidelines (http://ec.europa.eu/ipg/basics/legal/cookies/index_en.htm
                http://ec.europa.eu/ipg/basics/legal/data_protection/index_en.htm) concerning the protection of
                individuals with regard to the processing of personal data.</p>
</div>
        </>
    )
}

export default Privacy;
