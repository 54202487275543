import DOMPurify from 'dompurify';
import { getProperty } from 'dot-prop';
import moment from 'moment';
import { GAME_RIGHTS, USER_ROLE } from './constants';

export const getUserGreetingName = (user) => {
  return user.name;
};

export const somethingElse = (user) => {
  console.log(user);
  return user.name;
};

export const extractData = (analytics, path) => {
  if (!Array.isArray(analytics)) {
    return ['-'];
  }
  return analytics.filter((state) => getProperty(state, path));
};

export const getTotalCount = (analytics, path) => {
  if (!Array.isArray(analytics)) {
    return '-';
  }
  return extractData(analytics, path).length;
};

export const getDatePart = (dateTime) => {
  if (!dateTime) {
    return '';
  }
  return dateTime.substring(0, 10);
  //return dateTime;
};
/**
 * makes sure lat, lng and radius properties of object are set
 * @param object
 * @param onlyIfSet
 */
export const validateCoords = (object, forceValue) => {
  if (!object) {
    object = {};
  }
  if (forceValue) {
    // Force a value on lat, lng and radius.
    // This should be done on creation of a game or scene
    object.lat = parseFloat(object.lat);
    object.lng = parseFloat(object.lng);
    object.radius = parseInt(object.radius);
  }

  // if there is a value on lat, lng and radius, and this value is invalid, set it
  if (object.hasOwnProperty('lat') && (!object.lat || isNaN(object.lat))) {
    object.lat = 0;
  }
  if (object.hasOwnProperty('lng') && (!object.lng || isNaN(object.lng))) {
    object.lng = 0;
  }
  if (object.hasOwnProperty('radius') && (!object.radius || isNaN(object.radius))) {
    object.radius = 100;
  }
};

export const shortLabelForConversationStatement = (conversationStatement) => {
  return shortenText(conversationStatement.description, 50, '-');
  // const MAX_LENGTH = 50;
  // if (!conversationStatement.description) {
  //     return '-';
  // }
  // const cleanText = DOMPurify.sanitize(conversationStatement.description, {ALLOWED_TAGS: []}); // no allowed tags. So everything is stripped out
  // if (cleanText && cleanText.length < MAX_LENGTH) {
  //     return cleanText;
  // }
  // return cleanText.substring(0,MAX_LENGTH)+'...';
};

export const shortenText = (text, maxLength, defaultIfEmpty) => {
  if (typeof maxLength === 'undefined') {
    maxLength = 50;
  }
  if (typeof defaultIfEmpty === 'undefined') {
    defaultIfEmpty = '-';
  }
  if (!text || text === '') {
    return defaultIfEmpty;
  }
  let cleanText = DOMPurify.sanitize(text, { ALLOWED_TAGS: [] }); // no allowed tags. So everything is stripped out
  if (cleanText === '') {
    cleanText = defaultIfEmpty;
  }
  if (cleanText && cleanText.length < maxLength) {
    return cleanText;
  }
  return cleanText.substring(0, maxLength) + '...';
};

export const getLiveSpecVersion = (gameVersions) => {
  if (!gameVersions) {
    return null;
  }
  let count;
  for (count = 0; count < gameVersions.length; count++) {
    if (gameVersions[count].isLiveSpec) return gameVersions[count];
  }
  return null;
};
export const getLatestPublishedVersion = (gameVersions) => {
  // find the version with the highest "version" property
  let count;
  let largest = null;
  for (count = 1; count < gameVersions.length; count++) {
    if (gameVersions[count].isLiveSpec !== true) {
      if (largest === null || gameVersions[count].version > largest.version) {
        largest = gameVersions[count];
      }
    }
  }
  return largest;
};

export const formatDate = (date, format = 'lll') => {
  if (!date) {
    return '-';
  }

  let dt = typeof date === 'string' ? moment(date) : date;
  return dt.format(format);
};

export const stringIsNotEmpty = (s) => s && s.length > 0;

export function exportToCsv(filename, rows) {
  var processRow = function (row) {
    var finalVal = '';
    for (var j = 0; j < row.length; j++) {
      var innerValue = row[j] === undefined || row[j] === null ? '' : row[j]?.toString();
      if (row[j] instanceof Date) {
        innerValue = row[j].toLocaleString();
      }
      try {
        var result = innerValue.replace(/"/g, '""');
        if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
        if (j > 0) finalVal += ',';
        finalVal += result;
      } catch (err) {
        console.log('csv export error in row: ', { row, column: j });
      }
    }
    return finalVal + '\n';
  };

  var csvFile = '\uFEFF';
  for (var i = 0; i < rows.length; i++) {
    csvFile += processRow(rows[i]);
  }

  var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, filename);
  } else {
    var link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

export function getUserRightsOnGame(game, user) {
  let userRights = GAME_RIGHTS.VIEWER;
  try {
    if (game?.publicAccess === 'clone') {
      userRights = GAME_RIGHTS.CLONER;
    }
    if ((game?.additionalDesigners ?? []).includes(user.id)) {
      userRights = GAME_RIGHTS.DESIGNER;
    }
    if (game?.userId?.id === user.id) {
      userRights = GAME_RIGHTS.DESIGNER;
    }
    if (user.role === USER_ROLE.ADMIN) {
      userRights = GAME_RIGHTS.ADMIN;
    }
  } catch (err) {
    console.error('Error in getUserRightsOnGame', err);
  } finally {
    return userRights;
  }
}
