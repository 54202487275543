import _ from 'lodash';
import {
    DELETE_INTERACTION,
    EDIT_INTERACTION,
    FETCH_INTERACTION,
    CREATE_INTERACTION,
    FETCH_GAME_INTERACTIONS
} from '../actions/types';

export default (state={},action) => {
    switch (action.type) {
        case DELETE_INTERACTION:
            return _.omit(state, action.payload);
        case FETCH_GAME_INTERACTIONS:
            // return only the data returned, and not the previous data
//            return {..._.mapKeys(action.payload, 'id')};
            return {...state, ..._.mapKeys(action.payload, 'id')};
        case FETCH_INTERACTION:
            return {...state, [action.payload.id]:action.payload};
        case CREATE_INTERACTION:
            return {...state, [action.payload.id]:action.payload};
        case EDIT_INTERACTION:
            return {...state, [action.payload.id]:action.payload};
        default:
            return state;
    }
}