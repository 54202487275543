import React, { useEffect, useState } from 'react';
import { Alert, Button, Card, Container, FormCheck, ToggleButton } from 'react-bootstrap';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import QuestionsSummaryWidget from '../analytics/QuestionsSummaryWidget';
import QuizzesSummaryWidget from '../analytics/QuizzesSummaryWidget';
import MissionsSummaryWidget from '../analytics/MissionsSummaryWidget';
import OffersSummaryWidget from '../analytics/OffersSummaryWidget';
import InfosSummaryWidget from '../analytics/InfosSummaryWidget';
import ScenesSummaryWidget from '../analytics/ScenesSummaryWidget';
import ConversationsSummaryWidget from '../analytics/ConversationsSummaryWidget';
import { fetchGameGameVersions } from '../../actions/gameVersionsActions';
import { fetchGame, fetchGameAnalytics } from '../../actions/gamesActions';
import MainContent from '../layouts/MainContent';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { isEqual } from 'lodash';
import history from '../../history';
import { getUserRightsOnGame } from '../../utils/utils';

const SHOW_TYPES = {
  LIVE: 'live-only',
  PUBLISHED: 'published-only',
};

const GameAnalytics = ({ dispatch, match }) => {
  const { t } = useTranslation();
  const { activeGameId } = match.params;
  const [gameVersionId, setGameVersionId] = useState(null);
  const [includeAbandoned, setIncludeAbandoned] = useState(false);

  useEffect(() => {
    dispatch(fetchGame(activeGameId));
  }, [activeGameId, dispatch]);

  useEffect(() => {
    if (activeGameId) {
      console.log('ga:fetching game versions of game: ' + activeGameId);
      dispatch(fetchGameGameVersions(activeGameId));
    }
  }, [activeGameId, dispatch]);

  const gameVersions = useSelector(
    (state) => {
      //return Object.values(state.gameVersions).filter((gv) => !gv.isArchived);
      return Object.values(state.gameVersions);
    },
    (left, right) => isEqual(left, right)
  );

  useEffect(() => {
    if (gameVersionId) {
      console.log('ga:fetching game analytics of version: ' + gameVersionId);
      dispatch(fetchGameAnalytics(gameVersionId, includeAbandoned));
    }
  }, [activeGameId, dispatch, gameVersionId, includeAbandoned]);

  useEffect(() => {
    console.log('ga:sgmvEffect: ', {
      gameVersions: gameVersions.length,
    });
    if (gameVersions.length > 0) {
      setGameVersionId(gameVersions[gameVersions.length - 1].id);
      console.log('ga:gameVersionId set to ' + gameVersions[gameVersions.length - 1].id);
    }
  }, [gameVersions]);

  const user = useSelector((state) => state.auth.user);

  const analytics = useSelector((state) => state.gameAnalytics);
  const activeGameVersion = gameVersions.find((gv) => gv.id === gameVersionId);
  if (!activeGameVersion) {
    return <i className='mt-4 d-block'>Nothing to show</i>;
  }

  const { game } = activeGameVersion;
  const gameSpec = activeGameVersion.game.spec;

  const userRights = getUserRightsOnGame(game, user);
  if (!userRights.includes('edit')) {
    return <></>;
  }

  if (activeGameVersion?.isArchived) {
    return (
      <Container>
        <Alert variant='danger'>
          <Alert.Heading>Oops</Alert.Heading>
          <p>This game has been archived.</p>
        </Alert>
        <div className='d-flex flex-column full-width align-items-center'>
          <Button
            onClick={() => {
              history.push('/');
            }}>
            Go Back
          </Button>
        </div>
      </Container>
    );
  }

  return (
    <>
      <MainContent hasSideBar={false}>
        <Card className='shadow-sm'>
          <Card.Header className='d-flex font-weight-bold align-items-center bg-rlck-primary txt-white'>
            Game: {game.title}
            <div className='ml-auto row d-flex align-items-center'>
              <label className='mr-2  mt-2' htmlFor='show-type'>
                Show Data
              </label>
              <select
                id='show-type'
                value={gameVersionId}
                className='p-1'
                onChange={(e) => {
                  setGameVersionId(e.target.value);
                }}>
                {gameVersions.map((gv) => (
                  <option value={gv._id} key={gv._id}>
                    {t(`SHOW_TYPE_${gv.isLiveSpec ? SHOW_TYPES.LIVE : SHOW_TYPES.PUBLISHED}`)}
                  </option>
                ))}
              </select>
              <label className='ml-2 mr-2  mt-2' htmlFor='show-type'>
                {' '}
                Inlcude Abandoned
              </label>
              <FormCheck
                value={includeAbandoned}
                onChange={(e) => {
                  setIncludeAbandoned(e.target.checked);
                }}
              />
            </div>
          </Card.Header>

          <Card.Body>
            <div className='row'>
              <div className='col-12 col-lg-6'>
                <div className='mt-2'>
                  <span className='text-secondary'>Created at:</span> <time>{moment(game.createdAt).format('DD MMM YYYY, HH:mm')}</time>
                </div>

                {/* <div className='mt-2'>
                  <span className='text-secondary'>Instances:</span>{' '}
                  {displayedInstances.length}
                </div> */}

                <div className='mt-2'>
                  <span className='text-secondary'>Is multiplayer:</span> {game.multiplayer ? 'Yes' : 'No'}
                </div>

                <div className='mt-2'>
                  <span className='text-secondary'>Total Players:</span> {analytics ? analytics.length : '-'}
                </div>
              </div>
            </div>
          </Card.Body>
          {analytics && (
            <Card.Footer>
              <Card className='my-4 shadow-sm analytics'>
                <Card.Body>
                  <div className='row'>
                    {gameSpec.resources.questions.length > 0 && (
                      <div className='col-12 col-md-4 mb-5'>
                        <QuestionsSummaryWidget publishedGameVersion={activeGameVersion} analytics={analytics} />
                      </div>
                    )}

                    {game.spec.resources.quizzes.length > 0 && (
                      <div className='col-12 col-md-4 mb-5'>
                        <QuizzesSummaryWidget publishedGameVersion={activeGameVersion} analytics={analytics} />
                      </div>
                    )}

                    {game.spec.resources.missions.length > 0 && (
                      <div className='col-12 col-md-4 mb-5'>
                        <MissionsSummaryWidget publishedGameVersion={activeGameVersion} analytics={analytics} />
                      </div>
                    )}

                    {game.spec.resources.offers.length > 0 && (
                      <div className='col-12 col-md-4 mb-5'>
                        <OffersSummaryWidget publishedGameVersion={activeGameVersion} analytics={analytics} />
                      </div>
                    )}

                    {game.spec.script.scenes.length > 1 && (
                      <div className='col-12 col-md-4 mb-5'>
                        <ScenesSummaryWidget publishedGameVersion={activeGameVersion} analytics={analytics} />
                      </div>
                    )}

                    {game.spec.resources.infos.length > 0 && (
                      <div className='col-12 col-md-4 mb-5'>
                        <InfosSummaryWidget publishedGameVersion={activeGameVersion} analytics={analytics} />
                      </div>
                    )}

                    {game.spec.resources.conversations.length > 0 && (
                      <div className='col-12 col-md-4 mb-5'>
                        <ConversationsSummaryWidget publishedGameVersion={activeGameVersion} analytics={analytics} />
                      </div>
                    )}
                  </div>
                </Card.Body>
              </Card>
            </Card.Footer>
          )}
        </Card>
      </MainContent>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({ dispatch });

const GameAnalyticsConnected = connect(undefined, mapDispatchToProps)(GameAnalytics);

export default withRouter(GameAnalyticsConnected);
