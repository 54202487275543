import React from 'react';

import {change, Field, FieldArray, formValueSelector} from 'redux-form';
import '../../i18n';


import {useTranslation} from "react-i18next";
//import renderBooleanRadio from "./renderers/renderBooleanRadio";
import renderBooleanRadio2 from "./renderers/renderBooleanRadio2";
import Action2 from './Action2';
import Form from "react-bootstrap/Form";
import {connect} from "react-redux";
import ConditionForm2 from "./ConditionForm2";
import Mission from "./Mission";
import renderError from "./renderers/renderError";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import FieldArrayButtons from "./FieldArrayButtons";
import renderCheckbox from "./renderers/renderCheckbox";
import G4mTooltip from "../utils/G4mTooltip";
import renderCheckbox2 from "./renderers/renderCheckbox2";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import {shortenText} from "../../utils/utils";
import CustomToggle from "../utils/CustomToggle";

let lastId = Math.floor(Math.random()*10000);
const generatedId = () => {lastId = lastId + 1; return lastId};

let RenderMissionsArray = ({ fields, meta, level, game, formName, dispatch, submissions }) => {
    return (
        <>
            <Form.Row className="align-items-center">
                <Col lg="12">
                    <Button className="mb-2 " onClick={() => fields.push({
                        generatedId:generatedId(), gameId:game.id
                    })}><i className="fas fa-plus"></i> Add new Sub Mission</Button>
                </Col>
            </Form.Row>
            {renderError(meta, true)}
            <Accordion>
                {fields.map(
                    (mission, index) => {
                        const missionObject=fields.get(index);
                        // as key, I either use the mongodb id, or the generated id for new objects
                        const key=missionObject._id?missionObject._id:'mission'+missionObject.generatedId;
                        return (
                            <Card key={key}>
                                <Card.Header className={'submission-header'}>
                                    {
                                        // need eventKey as string  !

                                    }
                                    <CustomToggle eventKey={key+""} clickText={`Sub mission ${index+1}: ${shortenText(submissions[index].title, 50, '-')}`}>
                                        <FieldArrayButtons rowName={"sub mission"} fieldNameBase={mission} fields={fields} index={index}  />
                                    </CustomToggle>

                                </Card.Header>
                                {
                                    // need eventKey as string !
                                }
                                <Accordion.Collapse className={"accordion-content pb-2"} eventKey={key+""}>
                                    <Mission index={index} dispatch={dispatch} fieldNameParentMission={mission} level={level} game={game}  />
                                </Accordion.Collapse>
                            </Card>
                        )
                    }
                )}
            </Accordion>
        </>
    )
};
RenderMissionsArray = connect(
    (state, ownProps) => {
        const selector = formValueSelector(ownProps.formName);
        return {
            submissions: selector(state, 'accomplishment.submissions')
        }
    }
)(RenderMissionsArray)



const MissionAccomplishment = ({dispatch, accomplishmentType, baseFieldName, fieldNameParentMission, level, game, formName}) => {
    const handleAccomplishmentTypeChange = (event, newValue, previousValue, name) => {
        dispatch(change(formName, `${fieldName}.action`, {}));
        dispatch(change(formName, `${fieldName}.condition`, []));
        dispatch(change(formName, `${fieldName}.submissions`, []));
    }

    const { t, i18n } = useTranslation();
    if (!level) {
        level = 0;
    }
    //console.log('in MissionAccomplishment, level:',level);
    let fieldName = baseFieldName;
    if (fieldNameParentMission) {
        fieldName = `${fieldNameParentMission}.${baseFieldName}`;
    }
    let contentTypeComponent = null;
    const actionAccomplishment = <Field
        component={renderBooleanRadio2}
        name={`${fieldName}.accomplishmentType`}
        type="radio"
        inline={true}
        value={'action'}
        label="player action"
        onChange={handleAccomplishmentTypeChange}
    />
    const conditionAccomplishment = <Field
        component={renderBooleanRadio2}
        name={`${fieldName}.accomplishmentType`}
        type="radio"
        inline={true}
        value={'condition'}
        label="condition"
        onChange={handleAccomplishmentTypeChange}
    />
    const submissionsAccomplishment = <Field
        component={renderBooleanRadio2}
        name={`${fieldName}.accomplishmentType`}
        type="radio"
        inline={true}
        value={'submissions'}
        label="sub missions"
        onChange={handleAccomplishmentTypeChange}
    />

    const accomplishmentTypeLegend = <legend className="w-auto legend-small">Accomplishment by <G4mTooltip tooltip={t('ACCOMPLISHMENT_TYPE_TOOLTIP')} /></legend>

    const notifyField =  <fieldset className="border p-2 mb-3">
                            <legend className="w-auto legend-small">Notify player</legend>
                            <Field
                                inline={true}
                                component={renderCheckbox2}
                                name={`${fieldName}.notifyPlayer`}
                                type="checkbox"
                                label={`Yes`}
                                props={{  }}
                            />
                        </fieldset> ;
    if (level === 0) {
        contentTypeComponent = (
            <Form.Row>
                <Col>
                    <fieldset className="border p-2 mb-3">
                        {accomplishmentTypeLegend}
                        {actionAccomplishment}
                        {conditionAccomplishment}
                        {submissionsAccomplishment}
                    </fieldset>
                </Col>
                <Col>
                        {notifyField}
                </Col>
            </Form.Row>
        )
    }
    else {
        contentTypeComponent = (
            <Row>
                <Col>
                    <fieldset className="border p-2 mb-3">
                        {accomplishmentTypeLegend}
                        {actionAccomplishment}
                        {conditionAccomplishment}
                    </fieldset>
                </Col>
                <Col>
                    {notifyField}
                </Col>
            </Row>
        )
    }

    let accomomplishementComponent;
    let accomplishmentTitle;
    switch (accomplishmentType) {
        case 'action':
            accomplishmentTitle = 'Player action';
            accomomplishementComponent = (
                <Action2 baseFieldName={`${fieldName}.action` } formName={formName} ></Action2>
            )
            break;
        case 'condition':
            accomplishmentTitle = 'Condition';
            accomomplishementComponent = <ConditionForm2 dispatch={dispatch} scene={null} game={game}
                                                      baseFieldName={`${fieldName}.condition`}
                                                      formName={formName}
                                            ></ConditionForm2>
            break;
        case 'submissions':
            accomplishmentTitle = 'Sub missions';
            accomomplishementComponent = (
                <>
                    <Field
                        asCol={true}
                        component={renderCheckbox}
                        name={`${fieldName}.ordered`}
                        type="checkbox"
                        label={`Assign sub missions in order`}
                        props={{  }}
                    />
                    <FieldArray name={`${fieldName}.submissions`} component={RenderMissionsArray}
                            props={{fieldNameParentMission:fieldName, level:1, game:game, formName:formName, dispatch:dispatch}} />
                </>
            )
            break;
    }
    return (
        <>
            {contentTypeComponent}
            { accomomplishementComponent && <h3>{accomplishmentTitle}</h3> }
            {accomomplishementComponent}
        </>
    );
};

export default connect(
    (state, ownProps) => {
        let fieldName = ownProps.baseFieldName;
        if (ownProps.fieldNameParentMission) {
            fieldName = `${ownProps.fieldNameParentMission}.${ownProps.baseFieldName}`;
        }
        const selector = formValueSelector(ownProps.formName);
        const accomplishmentType = selector(state, `${fieldName}.accomplishmentType`);
        const xxxxxxxx = 1;
        return {
            accomplishmentType, xxxxxxxx
        }
    }
)(MissionAccomplishment)



