import React, { useState } from 'react';
import {connect} from "react-redux";
import {change, submit, getFormValues, formValueSelector, Field, reduxForm} from 'redux-form';
import Form from "react-bootstrap/Form";

import gamesApi from "../../apis/gamesApi";
import renderCheckbox from "../../components/forms/renderers/renderCheckbox";
import renderInput from "../../components/forms/renderers/renderInput";
import GoogleMapLocationForm from "../gmap/GoogleMapLocationForm";
import MainContent from "../layouts/MainContent";
const FORM_NAME = 'debugging-tools-form';

const onSubmit = (values) => {
  if (
    !values.message
    || !values.room
    || !(values && values.location && values.location.latitude)
  ) {
    alert('Payload validation failed. Check values and try again.');
    return;
  }

  const payload = {
    room: values.room,
    message: values.message,
    payload: {
      location: {
        coords: {
          latitude: values.location.latitude,
          longitude: values.location.longitude,
        }}
    }
  }

  gamesApi.post('/api/users/socketio-test', payload);
};

const DebuggingTools = ({ formValues, dispatch, handleSubmit }) => {
  const [sendOnPinDrop, setSendOnPinDrop] = useState(false);

  const onMarkerDrag = (lat, lng, sendRequest) => {
    dispatch(change(FORM_NAME, "location.latitude", lat));
    dispatch(change(FORM_NAME, "location.longitude", lng));

    if (sendRequest) {
      dispatch(submit(FORM_NAME));
    }
  };

  return (
    <MainContent hasSideBar={false}>
      <h1>Debugging tools</h1>

      <hr />
      <h3 className="mt-4">Set user Location</h3>

      <Form noValidate onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12 col-lg-12 col-xl-12">
            <div className="row mt-3">

              <div className="col-12 col-md-6 col-xl-3">
                <Field
                  name="message"
                  label="Socket Message"
                  type="string"
                  component={renderInput}
                  placeholder="ie: LOCATION_UPDATE"
                />
              </div>

              <div className="col-12 col-md-6 col-xl-3">
                <Field
                  placeholder="ie: USER_xxxxxxxxxxxxxxx"
                  name="room"
                  label="Socket Room"
                  type="string"
                  component={renderInput}
                />
              </div>

              <div className="col-6  col-xl-3">
                <Field
                  name="location.latitude"
                  normalize={(value, previousValue, allValues) => value ? parseFloat(value) : 0}
                  label="Latitude"
                  type="number"
                  step="0.001"
                  component={renderInput}
                />
              </div>
              <div className="col-6  col-xl-3">
                <Field
                  name="location.longitude"
                  normalize={(value, previousValue, allValues) => value ? parseFloat(value) : 0}
                  label="Longitude"
                  type="number"
                  step="0.001"
                  component={renderInput}
                />
              </div>
            </div>

            { !sendOnPinDrop && (
              <GoogleMapLocationForm
                formName={FORM_NAME}
                noRadius
                lat={(formValues && formValues.location && formValues.location.latitude )|| 0}
                lng={(formValues && formValues.location && formValues.location.longitude) || 0}
                radius={10}
                onMarkerDrag={(lat, lng) => onMarkerDrag(lat, lng, false)}
                style={{height:'800px'}}
              />
            )}

            { sendOnPinDrop && (
              <GoogleMapLocationForm
                formName={FORM_NAME}
                style={{height:'800px'}}
                noRadius
                lat={(formValues && formValues.location && formValues.location.latitude )|| 0}
                lng={(formValues && formValues.location && formValues.location.longitude) || 0}
                radius={10}
                onMarkerDrag={(lat, lng) => onMarkerDrag(lat, lng, true)}
              />
            )}
          </div>
        </div>

        <div className="mt-3 mb-5">
          <label className="mb-4">
            <input
              onChange={(e) => {
                console.log(e.target.checked);
                setSendOnPinDrop(e.target.checked);
              }}
              name="sendOnPinDrop"
              type="checkbox"
            />
            {' '} Send event on pin drop
          </label> <br />

          <button className="btn btn-primary " type="submit">Send socket event</button>
        </div>
      </Form>

    </MainContent>
  );
};

export default connect(
  (state, ownProps) => {
    return {
      formValues: getFormValues(FORM_NAME)(state),
    }
  }
)(reduxForm(
  {
    initialValues: {
      message: 'LOCATION_UPDATE',
    },
    onSubmit,
    form: FORM_NAME,
  }
)(DebuggingTools));
