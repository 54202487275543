import React, {useContext} from "react";
import AccordionContext from "react-bootstrap/AccordionContext";
import {useAccordionToggle} from "react-bootstrap/AccordionToggle";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function CustomToggle({ children, eventKey, callback, clickText,style }) {
    const currentEventKey = useContext(AccordionContext);
    const decoratedOnClick = useAccordionToggle(
        eventKey,
        () => callback && callback(eventKey),
    );
    const isCurrentEventKey = currentEventKey === eventKey;

    return (
        <Row>
            <Col lg={9}>
                <button
                    type="button"
                    className={`collapse-button ${isCurrentEventKey?'open ':'closed '}`}
                    style={{...style}}
                    onClick={decoratedOnClick}
                >
                    {
                        //isCurrentEventKey?'open ':'closed '
                    }
                    {clickText}
                </button>
            </Col>
            <Col lg={3} className="d-flex justify-content-end">
                {children}
            </Col>
        </Row>
    );
}

export default CustomToggle;