import gamesApi from "../apis/gamesApi";
import history from "../history";
import {
    FETCH_GAME_INITIAL_STATE,
    DELETE_GAME_INITIAL_STATE,
} from "./types";
import {displayError, displaySuccess} from "../components/utils/notifications";


// export const createItem = (gameId, formValues) =>  async (dispatch) => {
//     return new Promise((resolve, reject) => {
//         gamesApi.post(`/api/design/items?gameId=${gameId}`,{...formValues, gameId})
//             .then(response => {
//                 dispatch({type:CREATE_ITEM, payload:response.data});
//                 displaySuccess(`Item ${response.data.name} created`);
//                 resolve();
//                 //history.push(`/games/${gameId}`);
//             })
//             .catch(err => {
//                 displayError(err, `Error creating item`);
//                 reject();
//             });
//     });
//
// };

export const fetchGameInitialState = (gameId, versionId) => async (dispatch) => {
    if (gameId) {
        return new Promise((resolve, reject) => {
            gamesApi.get(`/api/design/initialStates/${gameId}/${versionId}`)
                .then(response => {
                    dispatch({type:FETCH_GAME_INITIAL_STATE, payload:response.data});
                    resolve();
                })
                .catch(err => {
                    dispatch({ type:FETCH_GAME_INITIAL_STATE, payload: {}});
                    displayError(err, `Error retrieving game initial state`);
                    reject();
                });
        });
    }
};

export const deleteInitialStateItem = (gameId, versionId, path) =>  async (dispatch) => {
    return new Promise((resolve, reject) => {
        gamesApi.delete(`/api/design/initialStates/${gameId}/${versionId}/${path}`)
          .then(response => {
              dispatch({ type:DELETE_GAME_INITIAL_STATE, payload: path });
              displaySuccess(`Item deleted`, `${path} was deleted`);
              resolve();
          })
          .catch(err => {
              displayError(err, `Error deleting item ${path}`);
              reject();
          });
    });
};

export const setInitialStateItem = (gameId, versionId, formValues) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        gamesApi.post(`/api/design/initialStates/${gameId}/${versionId}`, formValues)
            .then(response => {
                displaySuccess(`Initial state updated`);
                resolve();
            })
            .catch(err => {
                displayError(err, `Error setting initial state`);
                reject();
            });
    });
}

export const setGameInstanceWorldStateItem = (gameInstanceId, formValues) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        gamesApi.post(`/api/instances/${gameInstanceId}/updateWorldStateItem`, formValues)
            .then(response => {
                displaySuccess(`World state updated`);
                resolve();
            })
            .catch(err => {
                displayError(err, `Error setting world state`);
                reject();
            });
    });
};

export const deleteGameInstanceWorldStateItem = (instanceId, path) =>  async (dispatch) => {
  return new Promise((resolve, reject) => {
    gamesApi.delete(`/api/instances/${instanceId}/deleteWorldStateItem/${path}`)
      .then(response => {
        dispatch({ type:DELETE_GAME_INITIAL_STATE, payload: path });
        displaySuccess(`Item deleted`, `${path} was deleted`);
        resolve();
      })
      .catch(err => {
        displayError(err, `Error deleting item ${path}`);
        reject();
      });
  });
};
// export const fetchItem = (id) =>  async (dispatch) => {
//     return new Promise((resolve, reject) => {
//         gamesApi.get(`/api/design/items/${id}`)
//             .then(response => {
//                 dispatch({type:FETCH_ITEM, payload:response.data});
//                 resolve();
//             })
//             .catch(err => {
//                 displayError(err, `Error retrieving item data for id ${id}`);
//                 reject();
//             });
//     });
// };
// export const editItem = (id, formValues) =>  async (dispatch) => {
//     return new Promise((resolve, reject) => {
//         gamesApi.patch(`/api/design/items/${id}`, formValues)
//             .then(response => {
//                 dispatch({type:EDIT_ITEM, payload:response.data});
//                 displaySuccess(`Item ${response.data.name} saved`);
//                 resolve();
//                 // history.push(`/games/${gameId}`);
//             })
//             .catch(err => {
//                 displayError(err, `Error saving item ${id}`);
//                 reject();
//             });
//     });
// };
// export const deleteItem = (id, redirectUrl) =>  async (dispatch) => {
//     return new Promise((resolve, reject) => {
//         gamesApi.delete(`/api/design/items/${id}`)
//             .then(response => {
//                 dispatch({type:DELETE_ITEM, payload:id});
//                 displaySuccess(`Item deleted`, `Item ${id} was deleted`);
//                 resolve();
//                 if (redirectUrl) { // ??????????????
//                     history.push(redirectUrl);
//                 }
//             })
//             .catch(err => {
//                 displayError(err, `Error deleting item ${id}`);
//                 reject();
//             });
//     });
// };
