//import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { ediOffer, createOffer, deleteOffer } from '../../actions/offersActions';

import history from '../../history';
import OfferForm from '../forms/OfferForm';
import { bconfirm } from '../utils/ConfirmAlert';

let lastId = Math.floor(Math.random() * 10000);
const generatedId = () => {
  lastId = lastId + 1;
  return lastId;
};

const OfferEdit = ({ dispatch, offer, game, onCancel, onSave, afterDelete }) => {
  const handleCancel = () => {
    if (onCancel) {
      onCancel();
      return;
    }
    history.push(`/games/${game.id}`); // if no specific handler is used, redirect to game design page
  };
  const handleDelete = async (id) => {
    const result = await bconfirm('Are you really sure?', {
      cancelButtonStyle: 'secondary',
    });
    if (result) {
      dispatch(deleteOffer(id))
        .then(() => {
          if (afterDelete) {
            afterDelete();
            return;
          } else {
            history.push(`/games/${game.id}`); // if no specific handler is used, redirect to game design page
          }
        })
        .catch((e) => {
          /* do nothing. A toastr notification should have been displayed */
        });
    }
  };
  const onSubmit = (formValues) => {
    if (offer) {
      dispatch(ediOffer(offer.id, formValues))
        .then(() => {
          if (onSave) {
            onSave();
            return;
          } else {
            history.push(`/games/${game.id}`); // if no specific handler is used, redirect to game design page
          }
        })
        .catch((e) => {
          /* do nothing. An error toastr should have been displayed */
        });
    } else {
      dispatch(createOffer(game.id, formValues))
        .then(() => {
          if (onSave) {
            onSave();
            return;
          } else {
            history.push(`/games/${game.id}`); // if no specific handler is used, redirect to game design page
          }
        })
        .catch((e) => {
          /* do nothing. An error toastr should have been displayed */
        });
    }
  };
  const defaultInitialValues = {
    gameId: game.id,
    redeemable: true,
    pickable: true,
    tradable: false,
    actionLabelAct: 'Take it',
    actionLabelContact: 'Open it',
    sideeffectsContact: [
      {
        notify: false,
        generatedId: generatedId(),
        actionName: 'showObject',
        actionArgs: {},
      },
    ],
    sideeffectsAct: [
      {
        notify: false,
        generatedId: generatedId(),
        actionName: 'pickObject',
        actionArgs: {},
      },
    ],
  };

  return (
    <div className=''>
      <OfferForm enableReinitialize={true} handleCancel={handleCancel} handleDelete={handleDelete} initialValues={offer ? offer : defaultInitialValues} onSubmit={onSubmit} game={game} />
    </div>
  );
};

export default connect(null, null)(OfferEdit);
