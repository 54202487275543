import React, {useState} from 'react';
import Modal from "react-bootstrap/Modal";
import MediaList from './MediaList';
import MediaEdit from './MediaEdit';
import MediaUpload from './MediaUpload';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const MediaModal = ({ openMediaModal, closeMediaModal, handleMediaChoosen, initialSelectedMedia, gameId}) => {
    const [selectedMedia, setSelectedMedia] = useState(initialSelectedMedia?initialSelectedMedia.id:null);
    const onMediaSelectHandler = (mediaId) => {
        setSelectedMedia(mediaId);
    }
    return (
        <Modal dialogClassName="modal-90w" size="lg" show={openMediaModal} onHide={closeMediaModal}>
            <Modal.Header closeButton>
                <Modal.Title>Media</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container fluid>

                    <Row>
                        <Col xs={8}>
                            <MediaUpload gameId={gameId}></MediaUpload>
                            <MediaList gameId={gameId} handleMediaChoosen={handleMediaChoosen} initialSelectedMedia={initialSelectedMedia} onMediaSelectHandler={onMediaSelectHandler}></MediaList>
                        </Col>
                        <Col xs={4}>
                            {selectedMedia&&<MediaEdit mediaIdToEdit={selectedMedia}></MediaEdit>}
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>
    );
};

export default MediaModal;