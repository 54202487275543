import _ from 'lodash';
import {
    DELETE_RULE,
    EDIT_RULE,
    FETCH_RULE,
    CREATE_RULE,
    FETCH_GAME_RULES
} from '../actions/types';

export default (state={},action) => {
    switch (action.type) {
        case DELETE_RULE:
            return _.omit(state, action.payload);
        case FETCH_GAME_RULES:
            // return only the data returned, and not the previous data
//            return {..._.mapKeys(action.payload, 'id')};
            return {...state, ..._.mapKeys(action.payload, 'id')};
        case FETCH_RULE:
            return {...state, [action.payload.id]:action.payload};
        case CREATE_RULE:
            return {...state, [action.payload.id]:action.payload};
        case EDIT_RULE:
            return {...state, [action.payload.id]:action.payload};
        default:
            return state;
    }
}