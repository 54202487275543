import React from 'react';

import {change, Field, reduxForm, submit} from 'redux-form';
import {MILESTONE_FORM_NAME as FORM_NAME} from "./formTypes";
import renderInput from "./renderers/renderInput";

import Row   from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import ConditionForm2 from "./ConditionForm2";
import FormButtons from "./FormButtons";
import {asyncValidateForm, g4mFormTotalFormValidate, g4mFormValidate} from "../utils/validationUtils";
//import {CONDITION_TYPE_VERBS} from './conditionConstants';
import {useTranslation} from "react-i18next";

//const selector = formValueSelector(FORM_NAME);

const MilestoneForm = ({error, dispatch, handleSubmit, onSubmit, game, handleCancel, handleDelete, initialValues}) => {
    const { t, i18n } = useTranslation();

    // βάσει https://github.com/redux-form/redux-form/issues/3701
    // για να μην κάνει propagate το event προς τα πάνω
    // γιατί παίζει να είναι react nested (όχι DOM nested) σε άλλη φόρμα
    // και σε αυτή την περίπτωση κάνει submit και την container form
    const formHandleSubmit = (form) => {
        return function handleSubmit(e) {
            e.preventDefault();
            e.stopPropagation();
            dispatch(submit(form));
        }
    }

    // έτσι κι αλλιώς, η onSubmit που έρχετε από τα props θα κληθεί αυτόματα
/*
    const myOnSubmit = (formValues) => {
        console.log('SKATA ----------- myOnSubmit MilestoneForm ', formValues);
        onSubmit(formValues);
    };
*/
    //                    <Form noValidate onSubmit={handleSubmit(myOnSubmit)}>
    return (
        <Form noValidate onSubmit={formHandleSubmit(FORM_NAME)}>

            <Field name="name" label={t('NAME_FIELD_LABEL')} tooltip={t('NAME_FIELD_TOOLTIP')} asCol={false} component={renderInput} />

            <fieldset className="border p-2 mb-3">
                <legend className="w-auto legend-small">Fulfillment conditions</legend>

                <ConditionForm2 dispatch={dispatch} scene={null} game={game}
                                baseFieldName={'condition'}
                                formName={FORM_NAME}
                ></ConditionForm2>
            </fieldset>
            <FormButtons validationGeneralError={error} handleCancel={handleCancel} objectId={initialValues.id} handleDelete={initialValues.id?handleDelete:null} />
        </Form>
    );

}

const validate = (formValues) => {
    const errors = {};
    g4mFormValidate(errors, formValues, ['condition']); // name is validated asynchronously
    g4mFormTotalFormValidate(errors);
    console.log('Milestone validate errors:',errors);
    return errors;
};


export default reduxForm({
    form: FORM_NAME,
    validate:validate,
    asyncValidate:asyncValidateForm,
    asyncBlurFields: [ 'name' ]
})(MilestoneForm);
