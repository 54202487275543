import React, { useContext } from 'react';

import { change, Field, FieldArray, formValueSelector, reduxForm } from 'redux-form';
import { connect, useSelector } from 'react-redux';
import { INTERACTION_FORM_NAME as FORM_NAME } from './formTypes';
import renderInput from './renderers/renderInput';
import { INTER_CONFIRM, INTER_INSPECT_INFO, INTER_ANSWER_QUESTION, INTER_QUIZ, INTER_CONVERSATION } from '../interactions/interactionTypes';
//import {ACTION_TYPE_SHARE} from "../interactions/interactionConstants";

import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//import Tab from "react-bootstrap/Tab";
//import Tabs from "react-bootstrap/Tabs";
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import renderSelect from './renderers/renderSelect';
import ImageChoose from './ImageChoose';
import '../../i18n';
import { useTranslation } from 'react-i18next';
import Question from './Question';
import FormButtons from './FormButtons';
import { asyncValidateForm, g4mFormTotalFormValidate, g4mFormValidate } from '../utils/validationUtils';
import renderError from './renderers/renderError';
import renderCheckbox from './renderers/renderCheckbox';
import FieldArrayButtons from './FieldArrayButtons';
import Prompt from './Prompt';
import ConditionForm2 from './ConditionForm2';
import SideEffectForm2 from './SideEffectForm2';
import Accordion from 'react-bootstrap/Accordion';

import Card from 'react-bootstrap/Card';

import { getUserRightsOnGame, shortenText, shortLabelForConversationStatement } from '../../utils/utils';
import RichTextEditorWYSIWYG from './renderers/RichTextEditorWYSIWYG';
import G4mTooltip from '../utils/G4mTooltip';
import CustomToggle from '../utils/CustomToggle';
const selector = formValueSelector(FORM_NAME);

let lastId = Math.floor(Math.random() * 10000);
const generatedId = () => {
  lastId = lastId + 1;
  return lastId;
};

const getActorOptions = (characters) => {
  let elementOptions = [];
  for (const element of characters) {
    elementOptions.push({ id: element.id, title: element.name });
  }
  return elementOptions;
};
const StatementOption = ({ index, dispatch, game, viewer, fieldNameBase, fields, currentStatementIndex }) => {
  const { t, i18n } = useTranslation();

  return (
    <Container fluid>
      <Row>
        <Col>
          <Field name={`${fieldNameBase}.actionLabel`} label='Option label' asCol={false} component={renderInput} />
        </Col>
      </Row>
      <Row>
        <Col>
          <fieldset className='border p-2 mb-3'>
            <legend className='w-auto legend-small'>
              Show option only if <G4mTooltip tooltip={t('STATEMENT_OPTION_IF_TOOLTIP')} />
            </legend>
            <ConditionForm2 dispatch={dispatch} scene={null} game={game} baseFieldName={`${fieldNameBase}.condition`} formName={FORM_NAME}></ConditionForm2>
          </fieldset>
        </Col>
      </Row>

      <Row>
        <Col>
          <fieldset className='border p-2'>
            <legend className='w-auto legend-small'>
              Option actions <G4mTooltip tooltip={t('STATEMENT_OPTION_ACTION_TOOLTIP')} />
            </legend>
            <div className={'sideeffectssection'}>
              <SideEffectForm2
                fieldName={`${fieldNameBase}.actions`}
                game={game}
                newInteractionHandler={null}
                formName={FORM_NAME}
                forArcheType={false}
                forConversation={true}
                currentStatementIndex={currentStatementIndex}></SideEffectForm2>
            </div>
          </fieldset>
        </Col>
      </Row>
    </Container>
  );
};

let RenderStatementOptions = ({ fields, meta, game, dispatch, conversationStatements, statementIndex }) => {
  const user = useSelector((state) => state.auth.user);
  let viewer = !getUserRightsOnGame(game, user).includes('edit');
  return (
    <>
      <Form.Row className='align-items-center'>
        <Col lg='12'>
          <Button
            className='mb-2'
            disabled={viewer}
            onClick={() =>
              fields.push({
                generatedId: 'statementOption' + generatedId(),
                gameId: game.id,
              })
            }>
            <i className='fas fa-plus'></i> New Statement Option
          </Button>
        </Col>
      </Form.Row>
      <Accordion>
        {fields.map((statementOption, index) => {
          const statementOptionObject = fields.get(index);
          // as key, I either use the mongodb id, or the generated id for new objects
          const key = statementOptionObject._id ? statementOptionObject._id : 'statement' + statementOptionObject.generatedId;
          return (
            <Card key={key}>
              <Card.Header>
                {
                  // need eventKey as string  !
                }
                <CustomToggle
                  eventKey={index + ''}
                  clickText={`Option ${index + 1}: ${conversationStatements[statementIndex].options[index].actionLabel ? conversationStatements[statementIndex].options[index].actionLabel : ''}`}>
                  <FieldArrayButtons rowName={'option'} fieldNameBase={statementOption} fields={fields} index={index} viewer={viewer} />
                </CustomToggle>
              </Card.Header>
              {
                // need eventKey as string !
              }
              <Accordion.Collapse className={'accordion-content pb-2'} eventKey={index + ''}>
                <StatementOption index={index} dispatch={dispatch} game={game} fieldNameBase={statementOption} currentStatementIndex={statementIndex} />
              </Accordion.Collapse>
            </Card>
          );
        })}
      </Accordion>
      {renderError(meta, true)}
    </>
  );
};
RenderStatementOptions = connect((state, ownProps) => {
  //const selector = formValueSelector(ownProps.formName);
  return {
    conversationStatements: selector(state, 'conversation.statements'),
  };
})(RenderStatementOptions);

let DirectionComponent = ({ fieldNameBase, isDirection }) => {
  return (
    <>
      <Row>
        <Col>
          <Field placementFix asCol={false} component={renderCheckbox} name={`${fieldNameBase ? fieldNameBase + '.' : ''}direction.isDirection`} type='checkbox' label='Show in directions' />
        </Col>

        {isDirection && (
          <Col>
            <Field name={`${fieldNameBase ? fieldNameBase + '.' : ''}direction.title`} label='Direction title' asCol={false} component={renderInput} />
          </Col>
        )}
      </Row>
    </>
  );
};

DirectionComponent = connect((state, ownProps) => {
  //const selector = formValueSelector(ownProps.formName);
  return {
    isDirection: selector(state, `${ownProps.fieldNameBase ? ownProps.fieldNameBase + '.' : ''}direction.isDirection`),
  };
})(DirectionComponent);

const Statement = ({ index, dispatch, game, fieldNameBase, fields }) => {
  const prompt = <Prompt fieldNameBase={fieldNameBase} gameId={game.id}></Prompt>;
  return (
    <Container fluid>
      <Row>{prompt}</Row>
      <DirectionComponent fieldNameBase={fieldNameBase} />
      <h3>Statement user options</h3>
      <FieldArray name={`${fieldNameBase}.options`} component={RenderStatementOptions} props={{ game: game, dispatch: dispatch, statementIndex: index }} />
    </Container>
  );
};

let RenderStatements = ({ fields, meta, game, dispatch, characters, conversationStatements }) => {
  const user = useSelector((state) => state.auth.user);
  let viewer = !getUserRightsOnGame(game, user).includes('edit');
  return (
    <>
      {renderError(meta, true)}
      <Form.Row className='align-items-center'>
        <Col lg='12'>
          <Button
            disabled={viewer}
            className='mb-2'
            onClick={() =>
              fields.push({
                generatedId: 'statement' + generatedId(),
                gameId: game.id,
              })
            }>
            <i className='fas fa-plus'></i> New statement
          </Button>
        </Col>
      </Form.Row>
      <Accordion>
        {fields.map((statement, index) => {
          const statementObject = fields.get(index);
          // as key, I either use the mongodb id, or the generated id for new objects
          const key = statementObject._id ? statementObject._id : 'statement' + statementObject.generatedId;
          return (
            <Card key={key}>
              <Card.Header className={'statement-header'}>
                {
                  // need eventKey as string  !
                }
                <CustomToggle eventKey={index + ''} clickText={`Statement ${index + 1}: ${shortLabelForConversationStatement(conversationStatements[index])}`}>
                  <FieldArrayButtons viewer={viewer} rowName={'statement'} fieldNameBase={statement} fields={fields} index={index} />
                </CustomToggle>
              </Card.Header>
              {
                // need eventKey as string !
              }
              <Accordion.Collapse className={'accordion-content pb-2'} eventKey={index + ''}>
                <Statement index={index} dispatch={dispatch} game={game} characters={characters} fieldNameBase={statement} />
              </Accordion.Collapse>
            </Card>
          );
        })}
      </Accordion>
    </>
  );
};
RenderStatements = connect((state, ownProps) => {
  //const selector = formValueSelector(ownProps.formName);
  return {
    conversationStatements: selector(state, 'conversation.statements'),
  };
})(RenderStatements);

let renderQuestions = ({ fields, meta, initialValues, singleQuestion, quizQuestions, viewer }) => {
  if (singleQuestion && (!fields || fields.length == 0)) {
    fields.unshift({ correctAnswerBinary: 'no', attempts: 1 });
  }
  if (singleQuestion) {
    return (
      <>
        {renderError(meta, true)}
        {fields.map((question, index) => (
          <Question key={index} question={question} fields={fields} index={index} gameId={initialValues && initialValues.gameId} singleQuestion={singleQuestion} viewer={viewer} />
        ))}
      </>
    );
  } else {
    // quiz --> accordion for questions
    return (
      <>
        <Form.Row className='align-items-center'>
          <Col lg='12'>
            <Button disabled={viewer} className='mb-2 ' onClick={() => fields.push({ correctAnswerBinary: 'no' })}>
              <i className='fas fa-plus'></i> New Question
            </Button>
          </Col>
        </Form.Row>
        {renderError(meta, true)}
        <Accordion>
          {fields.map((question, index) => {
            return (
              <Card key={index}>
                <Card.Header>
                  {
                    // need eventKey as string  !
                  }
                  <CustomToggle eventKey={index + ''} clickText={`Question ${index + 1}: ${shortenText(quizQuestions[index].title, 50, '-')}`}>
                    <FieldArrayButtons rowName={'question'} fieldNameBase={question} fields={fields} index={index} viewer={viewer} />
                  </CustomToggle>
                </Card.Header>
                {
                  // need eventKey as string !
                }
                <Accordion.Collapse className={'accordion-content pb-2'} eventKey={index + ''}>
                  <Question viewer={viewer} question={question} fields={fields} index={index} gameId={initialValues && initialValues.gameId} singleQuestion={singleQuestion} />
                </Accordion.Collapse>
              </Card>
            );
          })}
        </Accordion>
      </>
    );
  }
};
renderQuestions = connect((state, ownProps) => {
  return {
    quizQuestions: selector(state, 'questions'),
  };
})(renderQuestions);

const InteractionForm = ({ error, dispatch, handleSubmit, onSubmit, handleCancel, interactionType, mediaId, mediaIdSecondary, quizHasIntro, quizHasEpilogue, initialValues, game, handleDelete }) => {
  const { t, i18n } = useTranslation();
  const user = useSelector((state) => state.auth.user);
  const viewer = !getUserRightsOnGame(game, user).includes('edit');
  let allCharacters = useSelector((state) => state.characters);
  let gameCharacters = Object.values(allCharacters).filter(function (el) {
    return el.gameId === game.id;
  });
  const myOnSubmit = (formValues) => {
    onSubmit(formValues);
  };
  let typeComponent = <Field name={'type'} component={'input'} type={'hidden'} />;

  const mediaComponent = (
    <Field
      name='mediaId'
      label='Media'
      logoIdToShow={mediaId}
      imageWidth={300}
      handleRemoveMedia={() => dispatch(change(FORM_NAME, 'mediaId', null))}
      gameId={initialValues && initialValues.gameId}
      handleSetMediaId={(newId) => dispatch(change(FORM_NAME, 'mediaId', newId))}
      component={ImageChoose}
    />
  );
  const mediaComponentSecondary = (
    <Field
      name='mediaIdSecondary'
      label='Media'
      logoIdToShow={mediaIdSecondary}
      handleRemoveMedia={() => dispatch(change(FORM_NAME, 'mediaIdSecondary', null))}
      gameId={initialValues && initialValues.gameId}
      handleSetMediaId={(newId) => dispatch(change(FORM_NAME, 'mediaIdSecondary', newId))}
      imageWidth={300}
      component={ImageChoose}
    />
  );
  const nameComponent = <Field name='name' label={t('NAME_FIELD_LABEL')} tooltip={t('NAME_FIELD_TOOLTIP')} asCol={false} component={renderInput} />;
  const titleComponent = <Field name='title' label='Title' asCol={false} component={renderInput} />;
  const buttonLabelComponent = <Field name='buttonLabel' label='Button text' asCol={false} component={renderInput} />;
  const descriptionComponent = <Field label='Message' name='description' component={RichTextEditorWYSIWYG} />;

  const commonFormElements = (
    <>
      <Row>
        <Col sm={12}>
          {nameComponent}
          {typeComponent}
        </Col>
        {/*<Col sm={6}>*/}
        {/*    {typeComponent}*/}
        {/*</Col>*/}
      </Row>
      <Row>
        <Col sm={12}>
          <Field asCol={false} name='notes' type='textarea' label='Notes' tooltip={t('NOTES_FIELD_TOOLTIP')} component={renderInput} />
        </Col>
      </Row>
    </>
  );
  let formElements = <></>;
  switch (interactionType) {
    case INTER_INSPECT_INFO:
      formElements = (
        <>
          <Row>
            <Col sm={8}>{titleComponent}</Col>
            <Col sm={4}>{buttonLabelComponent}</Col>
          </Row>
          <Row>
            <Col lg={10} md={9} sm={8}>
              {descriptionComponent}
            </Col>
            <Col lg={2} md={3} sm={4}>
              {mediaComponent}
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <DirectionComponent />
            </Col>
          </Row>
        </>
      );
      break;
    case INTER_CONFIRM:
      formElements = (
        <>
          <Row>
            <Col sm={4}>{titleComponent}</Col>
            <Col sm={4}>
              <Field name='buttonLabel' label='Confirm button text' asCol={false} component={renderInput} />
            </Col>
            <Col sm={4}>
              <Field name='buttonLabelSecondary' label='Reject button text' asCol={false} component={renderInput} />
            </Col>
          </Row>
          <Form.Row>
            <Col lg={10} md={9} sm={8}>
              {descriptionComponent}
            </Col>
            <Col lg={2} md={3} sm={4}>
              {mediaComponent}
            </Col>
          </Form.Row>
        </>
      );
      break;
    case INTER_ANSWER_QUESTION:
      formElements = (
        <>
          <FieldArray name='questions' component={renderQuestions} props={{ singleQuestion: true, initialValues, viewer }} />
        </>
      );
      break;
    case INTER_CONVERSATION:
      formElements = (
        <>
          <Row>
            <Col sm={6}>{titleComponent}</Col>
            {/* <Col sm={3}>
              <Field
                asCol={false}
                placementFix
                component={renderCheckbox}
                name={`conversation.allowBack`}
                type='checkbox'
                label={`Allow back`}
                tooltip={'Allow player to go back to previous statement'}
                props={{}}
              />
            </Col> */}
            <Col sm={3}>
              <Field
                asCol={false}
                placementFix
                component={renderCheckbox}
                name={`conversation.resumable`}
                type='checkbox'
                label={`Resumable`}
                tooltip={'Allow the player to continue this conversation in case (s)he) leaves'}
                props={{}}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <FieldArray name='conversation.statements' component={RenderStatements} props={{ game: game, dispatch: dispatch, characters: gameCharacters }} />
            </Col>
          </Row>
        </>
      );
      break;
    case INTER_QUIZ:
      const introduction = (
        <fieldset className='border p-2'>
          <legend className='w-auto legend-small'>Introduction</legend>
          <Row>
            <Col>
              <Field asCol={true} component={renderCheckbox} name={`quizHasIntro`} type='checkbox' label={`Quiz has an introduction`} props={{}} />
            </Col>
          </Row>
          {quizHasIntro && (
            <>
              <Row>
                <Col lg={10} md={9} sm={8}>
                  {descriptionComponent}
                </Col>
                <Col lg={2} md={3} sm={4}>
                  {mediaComponent}
                </Col>
              </Row>
              <Row>
                <Col>
                  <Field name='buttonLabel' label='Ok button text' asCol={false} component={renderInput} />
                </Col>
                <Col>
                  <Field name='buttonLabelSecondary' label='Skip button text' asCol={false} component={renderInput} />
                </Col>
              </Row>
            </>
          )}
        </fieldset>
      );
      const epilogue = (
        <fieldset className='border p-2 mt-4'>
          <legend className='w-auto legend-small'>Epilogue</legend>
          <Row>
            <Col>
              <Field asCol={true} component={renderCheckbox} name={`quizHasEpilogue`} type='checkbox' label={`Quiz has an epilogue`} props={{}} />
            </Col>
          </Row>
          {quizHasEpilogue && (
            <Row>
              <Col lg={10} md={9} sm={8}>
                <Field label='Message' name='descriptionSecondary' component={RichTextEditorWYSIWYG} />
              </Col>
              <Col lg={2} md={3} sm={4}>
                {mediaComponentSecondary}
                <p>&nbsp;</p>
                <Field name='buttonLabelTertiary' label='Button text' asCol={false} component={renderInput} />
              </Col>
            </Row>
          )}
        </fieldset>
      );
      formElements = (
        <>
          <Row>
            <Col sm={12}>{titleComponent}</Col>
          </Row>
          <Row>
            <Col sm={12}>
              {introduction}
              <fieldset className='border p-2  mt-4'>
                <legend className='w-auto legend-small'>Questions</legend>
                <FieldArray name='questions' component={renderQuestions} props={{ initialValues, viewer }} />
              </fieldset>
              {epilogue}
            </Col>
          </Row>
        </>
      );
      break;
    default:
  }

  return (
    <Form noValidate onSubmit={handleSubmit(myOnSubmit)}>
      {commonFormElements}
      {formElements}
      <FormButtons validationGeneralError={error} handleCancel={handleCancel} objectId={initialValues.id} handleDelete={initialValues.id ? handleDelete : null} />
    </Form>
  );
};

const validate = (formValues) => {
  const errors = {};

  if (!formValues.type) {
    errors.type = 'Required';
  } else {
    switch (formValues.type) {
      case INTER_INSPECT_INFO:
        g4mFormValidate(errors, formValues, ['title', 'buttonLabel', 'direction']); // name is validated asynchronously
        if (!formValues.description && !formValues.mediaId) {
          errors.description = 'You need to provide at least a message or a media';
          errors.mediaId = 'You need to provide at least a message or a media';
        }
        break;
      case INTER_CONVERSATION:
        g4mFormValidate(errors, formValues, ['title', 'conversation']); // name is validated asynchronously
        break;
      case INTER_CONFIRM:
        g4mFormValidate(errors, formValues, ['title', 'buttonLabel', 'buttonLabelSecondary', 'description:wysiwyg']); // name is validated asynchronously
        break;
      case INTER_ANSWER_QUESTION:
        g4mFormValidate(errors, formValues, ['questions:single']); // name is validated asynchronously
        break;
      case INTER_QUIZ:
        //g4mFormValidate(errors, formValues, ['title','description:wysiwyg','descriptionSecondary:wysiwyg','buttonLabel','buttonLabelSecondary','buttonLabelTertiary','questions:multiple']); // name is validated asynchronously
        // intro and epilogue are completely optional
        g4mFormValidate(errors, formValues, ['title', 'questions:multiple']); // name is validated asynchronously
        break;
      default:
    }
  }
  g4mFormTotalFormValidate(errors);
  // if (formValues.type === INTER_INSPECT_INFO) {
  //     if (!formValues.description && !formValues.mediaId) {
  //         errors.description = "You need to provide at least a message or a media";
  //         errors.mediaId = "You need to provide at least a message or a media";
  //     }
  // }
  console.log('InteractionForm validation errors ', errors);
  return errors;
};

// export default reduxForm({
//     form: FORM_NAME,
//     validate:validate,
// })(InteractionForm);

export default connect((state, ownProps) => {
  return {
    interactionType: selector(state, 'type'),
    mediaId: selector(state, 'mediaId'),
    mediaIdSecondary: selector(state, 'mediaIdSecondary'),
    quizHasIntro: selector(state, 'quizHasIntro'),
    quizHasEpilogue: selector(state, 'quizHasEpilogue'),
  };
})(
  reduxForm({
    form: FORM_NAME,
    validate: validate,
    asyncValidate: asyncValidateForm,
    asyncBlurFields: ['name'],
  })(InteractionForm)
);
