//import _ from 'lodash';
import React from 'react';
import {connect} from 'react-redux';
import {editMedia, deleteMedia} from '../../actions/mediasActions';
import MediaForm from "../forms/MediaForm";
import {getOriginalUrl, getPreviewUrl} from '../utils/mediaUtils';
import Button from "react-bootstrap/Button";
import {bconfirm} from "../utils/ConfirmAlert";
const isEmpty = require("is-empty");

const MediaEdit = ({dispatch, editMedia, media, mediaIdToEdit, onCancel, onSave}) => {

    const handleCancel = () => {
        if (onCancel) {
            onCancel();
            return;
        }
    };
    const onMediaPropsFormSubmit = (formValues) => {
        //console.log("onMediaPropsFormSubmit");
        if (!isEmpty(mediaIdToEdit)) {
            dispatch(editMedia(media.id, formValues))
                .then(() => {
                    if (onSave) {
                        onSave();
                        return;
                    }
                })
                .catch((e) => {
                    /* do nothing. An error toastr should have been displayed */
                });
        }

    };
    const handleDeleteMedia = async(mediaId) => {
        const result = await bconfirm('Are you really sure?', {cancelButtonStyle: 'secondary'});
        if (result) {
            dispatch(deleteMedia(mediaId))
                .then(() => {
                    /* do nothing */
                })
                .catch((e) => {
                    /* do nothing. A toastr notification should have been displayed */
                });
        }
    };

    if (!media) {
        return <div>No media selected</div>;
    }
    const url = getPreviewUrl(media);
    return (
        <div className="">

            <MediaForm
                enableReinitialize={true}
                handleCancel={handleCancel}
                initialValues={media}
                handleFormSubmit={onMediaPropsFormSubmit} />
            <Button
                    variant="outline-danger"
                onClick={handleDeleteMedia.bind(this, media.id)}
            >
                Delete permanently
            </Button>
            <br/> id: {media.id}
            {
                // gameId: {media.gameId}
                // filenamePrefix: {media.filenamePrefix}
                // ending: {media.ending}
            }
            <img src={url} alt={""} />
            <br/> orig_filename: {media.orig_filename}
            <br/> <a href={getOriginalUrl(media)} target={"_blank"}>View original</a>
            <br/> mimetype: {media.mimetype}
            <br/> Size: {media.size} Bytes
            <br/> Width: {media.width}px
            <br/> Height: {media.height}px
        </div>
    );

}

const mapStateToProps = (state, ownProps) => {
    return {media:state.medias[ownProps.mediaIdToEdit]};
};
export default connect(mapStateToProps,
    (dispatch) => ({dispatch, deleteMedia:deleteMedia, editMedia: editMedia}))(MediaEdit);
