import _ from 'lodash';
import {
    DELETE_CHARACTER,
    EDIT_CHARACTER,
    FETCH_CHARACTER,
    CREATE_CHARACTER,
    FETCH_GAME_CHARACTERS
} from '../actions/types';

export default (state={},action) => {
    switch (action.type) {
        case DELETE_CHARACTER:
            return _.omit(state, action.payload);
        case FETCH_GAME_CHARACTERS:
            // return only the data returned, and not the previous data
//            return {..._.mapKeys(action.payload, 'id')};
            return {...state, ..._.mapKeys(action.payload, 'id')};
        case FETCH_CHARACTER:
            return {...state, [action.payload.id]:action.payload};
        case CREATE_CHARACTER:
            return {...state, [action.payload.id]:action.payload};
        case EDIT_CHARACTER:
            return {...state, [action.payload.id]:action.payload};
        default:
            return state;
    }
}