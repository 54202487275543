import React from 'react';
import { connect } from 'react-redux';
import history from '../../history';
import CollapseArchetypes from './CollapseArchetypes';

const ArchetypeList = ({ archetypes, game, displayAs, dispatch }) => {
  const newItemHandler = () => {
    history.push(`/games/${game.id}/archetypes/create/0`);
  };
  const editItemHandler = (id) => {
    history.push(`/games/${game.id}/archetypes/edit/${id}`);
  };

  const editObjectHandler = (id) => {
    history.push(`/games/${game.id}/initialState/edit/${id}/objects`);
  };

  const newObjectHandler = (archetypeId) => {
    history.push(`/games/${game.id}/initialState/create/${archetypeId}/objects`);
  };

  const archetypeList = [];
  for (const item of archetypes) {
    archetypeList.push({ id: item.id, name: item.name });
  }

  if (displayAs === 'collapse')
    return (
      <CollapseArchetypes
        game={game}
        identifier='archetypeslist'
        heading='Object Types'
        archetypes={archetypeList}
        newItemHandler={newItemHandler}
        newLabel='New Object Type'
        editItemHandler={editItemHandler}
        editObjectHandler={editObjectHandler}
        newObjectHandler={newObjectHandler}
        deleteItemHandler={null}
        editLabel='Settings'
        deleteLabel='Delete'
        additionalClass='sideBar'
      />
    );
  return <>No displayAs</>;
};

export default connect(null, null)(ArchetypeList);
