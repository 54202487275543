import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'

import {Provider} from 'react-redux';
import store from './store';
import ReduxToastr from 'react-redux-toastr'
import './i18n';

import G4m from './components/G4m';


import * as serviceWorker from './serviceWorker';

ReactDOM.render(

  <React.StrictMode>

      <Provider store={store}>
          <G4m />
          <ReduxToastr
              timeOut={10000}
              newestOnTop={false}
              preventDuplicates
              position="top-right"
              getState={(state) => state.toastr} // This is the default
              transitionIn="fadeIn"
              transitionOut="fadeOut"
              progressBar
              closeOnToastrClick/>
      </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
