import React from 'react';

import '../../i18n';
import { useTranslation } from 'react-i18next';
import {useDispatch, useSelector} from "react-redux";
import LinkContainer from "react-router-bootstrap/lib/LinkContainer";
//import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row   from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import {toggleSidebar} from "../../actions/appActions";

const G4mBreadcrumb = ({game, objecttype, objectaction, objectid, hasSideBar}) => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const objects = useSelector((state)=>state[objecttype]);
    let gameLink = <></>;
    let objectLink = <></>;


    if (game) {
        gameLink = (
            <li className="breadcrumb-item">
                <LinkContainer to={`/games/${game.id}`}>
                    <a>{game.title}</a>
                </LinkContainer>
            </li>
        )
    }
    if (objecttype && objectaction) {
        let objectText = '';
        if (!objects) {
            return 'loading...';
        }
        const object = objects[objectid];//.find(element => element.id === objectid);
        if (!object && objectid !== '0') {
            objectText ='loading...';
        }
        else {
            switch (objectaction) {
                case 'edit':
                    objectText =`${t(objecttype+'_breadobjecttype')} ${object.name?object.name:''}`;
                    break;
                case 'show':
                    objectText =`${t(objecttype+'_breadobjecttype')} ${object.name?object.name:''}`;
                    break;
                case 'create':
                    objectText =`${t(objecttype+'_breadobjecttype')}`;
                    break;
            }
            objectLink = (
                <li className="breadcrumb-item active">
                    <LinkContainer to={`/games/${game.id}/${objecttype}/${objectaction}/${objectid}`}>
                        <a>{objectText}</a>
                    </LinkContainer>
                </li>
            )
        }
    }

    let sideCollapse = null;
    if (hasSideBar) {
        // sideCollapse =  (
        //     <Button  type="button" id="sidebarCollapse" title="Toggle sidebar" variant="info" onClick={()=>dispatch(toggleSidebar())}>
        //         <i className="fas fa-align-left"></i>
        //     </Button>
        // )
    }

    const breadcrumb = (
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <LinkContainer to="/" >
                            <a>My Games</a>
                        </LinkContainer>
                    </li>
                    {gameLink}
                    {objectLink}
                    {
                     //   <li className="breadcrumb-item active" aria-current="page">Data</li>

                    }
                </ol>
            </nav>
    )

    return (
      <div>
        {breadcrumb}
      </div>
    )
}
export default G4mBreadcrumb;
