/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState } from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { connect, useDispatch, useSelector } from 'react-redux';
import { deleteGame, fetchMyGames } from '../../actions/gamesActions';
import '../../custom-table.css';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import LinkContainer from 'react-router-bootstrap/lib/LinkContainer';
import { Alert, ButtonGroup, Card, Form, ListGroup, Spinner } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import MainContent from '../layouts/MainContent';
import ModalEdit from '../utils/ModalEdit';
import GameEdit from './GameEdit';
import GameItem from './GameItem';
import { SET_GAMES_FILTER } from '../../actions/types';
const defPageSize = 10;

const GameList = ({ app, games, currentUserId, isAuthenticated, errors, fetchMyGames, deleteGame, dispatch }) => {
  const loading = useSelector((state) => state.app.gameLoading);
  const user = useSelector((state) => state.auth.user);
  const gamesFilter = useSelector((state) => state.app.gamesFilter);
  //const [gamesFilter, setGamesFilter] = useState(user.role === 'admin' ? 'all' : 'mine');
  const [openEditGameModal, setOpenEditGameModal] = useState(false);
  const [selectedGameIdToEdit, setSelectedGameIdToEdit] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState(games);
  const [displayedGames, setDisplayedGames] = useState(games);

  useEffect(() => {
    let results = [...filteredData];
    if (searchTerm?.length > 3) {
      const lcText = searchTerm.toLowerCase();
      try {
        results = filteredData.filter((g) => g.title.toLowerCase().includes(lcText) || g.userId?.name.toLowerCase().includes(lcText));
      } catch (err) {}
    }
    setDisplayedGames(results);
  }, [filteredData, searchTerm]);

  useEffect(() => {
    dispatch(fetchMyGames());
  }, [isAuthenticated, fetchMyGames, dispatch]);

  useEffect(() => {
    if (gamesFilter === 'mine') {
      setFilteredData(games.filter((g) => g.userId?.id === user?.id));
    } else if (gamesFilter === 'shared') {
      setFilteredData(games.filter((g) => g.additionalDesigners.includes(user?.id)));
    } else if (gamesFilter === 'public') {
      setFilteredData(games.filter((g) => ['view', 'clone'].includes(g.publicAccess))); //&& g.userId.id !== user?.id
    } else if (gamesFilter === 'all') {
      setFilteredData(games);
    }
  }, [games, gamesFilter, user]);

  const closeEditGameModal = () => {
    setOpenEditGameModal(false);
  };
  const cancelEditGameModal = () => {
    setOpenEditGameModal(false);
  };

  const editGame = (gameId) => {
    setSelectedGameIdToEdit(gameId);
    setOpenEditGameModal(true);
  };

  const columns = [
    {
      dataField: '',
      text: 'Games',
      formatter: (c, game) => {
        return <GameItem game={game} />;
      },
    },
  ];

  return (
    <>
      <MainContent hasSideBar={false}>
        <Row className='m-3 d-flex justify-content-between'>
          <LinkContainer to='/games/new'>
            <Button className={'mr-2'}>
              <i className='fas fa-plus'></i> New Game
            </Button>
          </LinkContainer>
          <ListGroup horizontal>            
            <ListGroup.Item className='pt-1 pb-1' action variant='secondary' onClick={() => dispatch({ type: SET_GAMES_FILTER, payload: 'mine' })} active={gamesFilter === 'mine'}>
              Mine
            </ListGroup.Item>
            <ListGroup.Item className='pt-1 pb-1' action variant='secondary' onClick={() => dispatch({ type: SET_GAMES_FILTER, payload: 'shared' })} active={gamesFilter === 'shared'}>
              <span className='no-wrap'>Shared with Me</span>
            </ListGroup.Item>
            <ListGroup.Item className='pt-1 pb-1' action variant='secondary' onClick={() => dispatch({ type: SET_GAMES_FILTER, payload: 'public' })} active={gamesFilter === 'public'}>
              Public
            </ListGroup.Item>
            {user.role === 'admin' && (
              <ListGroup.Item className='pt-1 pb-1' action variant='secondary' onClick={() => dispatch({ type: SET_GAMES_FILTER, payload: 'all' })} active={gamesFilter === 'all'}>
                All
              </ListGroup.Item>
            )}
          </ListGroup>
        </Row>
        <div>
          <Form.Group className='m-0 ml-3 mr-3'>
            <Form.Control type='text' value={searchTerm} placeholder='Search...' onChange={(e) => setSearchTerm(e.target.value)} />
          </Form.Group>
          <BootstrapTable
            keyField='id'
            data={displayedGames}
            columns={columns}
            bordered={false}
            wrapperClasses='m-0 p-0'
            headerClasses='d-none'
            noDataIndication={() =>
              loading ? (
                <Card.Body>
                  <Spinner animation='border' title='Loading...' />
                </Card.Body>
              ) : (
                <Card.Body>
                  <Alert variant='warning' className='d-flex justify-content-between' style={{ fontSize: 20 }}>
                    Nothing to see here...
                  </Alert>
                </Card.Body>
              )
            }
            pagination={paginationFactory({
              hidePageListOnlyOnePage: true,
              hideSizePerPage: displayedGames?.length <= defPageSize,
              sizePerPageList: [
                {
                  text: '10',
                  value: 10,
                },
                {
                  text: '20',
                  value: 20,
                },
                {
                  text: '50',
                  value: 50,
                },
                {
                  text: 'All',
                  value: filteredData.length,
                },
              ],
            })}
          />
        </div>
      </MainContent>

      <ModalEdit openEditModal={openEditGameModal} closeEditModal={closeEditGameModal} heading={'Edit Game'} portal='game'>
        <GameEdit gameidToEdit={selectedGameIdToEdit} onSave={closeEditGameModal} onCancel={cancelEditGameModal} />
      </ModalEdit>
    </>
  );
};

const mapStateToProps = (state) => {
  // to object.values pairnei ola ta properties enos object kai ta metatrepei ta values se array
  return {
    app: state.app,
    games: Object.values(state.games),
    currentUserId: state.auth.user.id,
    isAuthenticated: state.auth.isAuthenticated,
    errors: state.errors,
  };
};
export default connect(mapStateToProps, (dispatch) => ({
  dispatch,
  fetchMyGames: fetchMyGames,
  deleteGame: deleteGame,
}))(GameList);
