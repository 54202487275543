import gamesApi from "../apis/gamesApi";
import history from "../history";
import {
    FETCH_GAME_MILESTONES,
    FETCH_MILESTONE,
    EDIT_MILESTONE,
    CREATE_MILESTONE,
    DELETE_MILESTONE
} from "./types";
import {displayError, displaySuccess} from "../components/utils/notifications";


export const createMilestone = (gameId, formValues) =>  async (dispatch) => {
    return new Promise((resolve, reject) => {
        gamesApi.post(`/api/design/milestones?gameId=${gameId}`,{...formValues, gameId})
            .then(response => {
                dispatch({type:CREATE_MILESTONE, payload:response.data});
                displaySuccess(`Milestone ${response.data.name} created`);
                resolve(response.data);
                //history.push(`/games/${gameId}`);
            })
            .catch(err => {
                displayError(err, `Error creating milestone`);
                reject();
            });
    });

};

export const fetchGameMilestones = (gameId) => async (dispatch) => {
    if (gameId) {
        return new Promise((resolve, reject) => {
            gamesApi.get(`/api/design/milestones?gameId=${gameId}`)
                .then(response => {
                    dispatch({type:FETCH_GAME_MILESTONES, payload:response.data});
                    resolve();
                })
                .catch(err => {
                    displayError(err, `Error retrieving game milestones`);
                    reject();
                });
        });
    }
    else {
        return new Promise((resolve, reject) => {
            dispatch({type: FETCH_GAME_MILESTONES, payload: []});
            displayError(null, `No gameId id in fetchGameMilestones`);
            reject();
        });
    }
};

export const fetchMilestone = (id) =>  async (dispatch) => {
    return new Promise((resolve, reject) => {
        gamesApi.get(`/api/design/milestones/${id}`)
            .then(response => {
                dispatch({type:FETCH_MILESTONE, payload:response.data});
                resolve();
            })
            .catch(err => {
                displayError(err, `Error retrieving milestone data for id ${id}`);
                reject();
            });
    });
};
export const editMilestone = (id, formValues) =>  async (dispatch) => {
    return new Promise((resolve, reject) => {
        gamesApi.patch(`/api/design/milestones/${id}`, formValues)
            .then(response => {
                dispatch({type:EDIT_MILESTONE, payload:response.data});
                displaySuccess(`Milestone ${response.data.name} saved`);
                resolve();
                // history.push(`/games/${gameId}`);
            })
            .catch(err => {
                displayError(err, `Error saving milestone ${id}`);
                reject();
            });
    });
};
export const deleteMilestone = (id, redirectUrl) =>  async (dispatch) => {
    return new Promise((resolve, reject) => {
        gamesApi.delete(`/api/design/milestones/${id}`)
            .then(response => {
                dispatch({type:DELETE_MILESTONE, payload:id});
                displaySuccess(`Milestone deleted`, `Milestone ${id} was deleted`);
                resolve();
                if (redirectUrl) { // ??????????????
                    history.push(redirectUrl);
                }
            })
            .catch(err => {
                displayError(err, `Error deleting milestone ${id}`);
                reject();
            });
    });
};
