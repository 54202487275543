import React from 'react';
import renderError from './renderError';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import G4mTooltip from '../../utils/G4mTooltip';

const renderCheckbox = ({ input, label, meta, asCol, addonId, addonLabel, disabled, tooltip, noFormGroup = false, placementFix = false, placementFixInline = false }) => {
  let className = `${meta.error && meta.touched ? 'error' : ''}`;
  if (placementFix) {
    className += ' placement-fix';
  }
  if (placementFixInline) {
    className += ' placement-fix-inline';
  }
  const formGroupPros = {}; // for handling <Form.Group as={Col}>
  if (asCol) {
    formGroupPros.as = Col;
  }
  const disabledProp = {};
  if (disabled) {
    disabledProp.disabled = 'disabled'; //
  }
  const controlId = addonId ? input.name + addonId : input.name;
  const addonLabelElement = addonLabel ? <label>{addonLabel}</label> : '';
  let tooltipControl = null;
  if (tooltip) {
    tooltipControl = <G4mTooltip tooltip={tooltip} textBefore={label} />;
  }

  const controls = (
    <>
      {addonLabelElement}
      <Form.Check {...input} className={className} {...disabledProp} value={1} type='checkbox' label={tooltipControl ? tooltipControl : label} />
      {renderError(meta)}
    </>
  );
  if (noFormGroup) {
    return controls;
  }

  return (
    <>
      <Form.Group {...formGroupPros} controlId={controlId}>
        {controls}
      </Form.Group>
    </>
  );
};
export default renderCheckbox;
