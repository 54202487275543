import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MainContent from '../layouts/MainContent';
import { Alert, Button, Card, Modal } from 'react-bootstrap';
import { bconfirm } from '../utils/ConfirmAlert';
import { cancelUserRoleRequest, getUserAccount, logoutUser, requestUserRole, unRegisterUser } from '../../actions/authActions';
import history from '../../history';
import authapi from '../../apis/authapi';
import { GET_ERRORS } from '../../actions/types';
import { set } from 'lodash';

function VerticallyCenteredModal({ show, title, body, closeBtnText, onHideFn }) {
  return (
    <Modal show={show} size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
      <Modal.Header closeButton onHide={onHideFn}>
        <Modal.Title id='contained-modal-title-vcenter'>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        <Button onClick={onHideFn}>{closeBtnText ?? 'OK'}</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default function UserAccount() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserAccount());
  }, [dispatch]);

  const { account: user } = useSelector((state) => state.auth);
  const [showGoodbye, setShowGoodbye] = useState(false);

  const onDeactivate = async () => {
    const result = await bconfirm('Are you sure you want to deactivate your account? If yes, your account and your personal data will be prmanently deleted', {
      cancelButtonStyle: 'secondary',
    });
    console.log(result);
    if (result) {
      try {
        await authapi.delete('/api/users/account');
        setShowGoodbye(true);
      } catch (err) {
        dispatch({ type: GET_ERRORS, payload: err?.response?.data });
      }
    }
  };

  const kickout = () => {
    setShowGoodbye(false);
    //dispatch(logoutUser());
  };

  const requestDesignerRole = (role) => {
    dispatch(requestUserRole(role));
  };

  const cancelRoleRequest = () => {
    dispatch(cancelUserRoleRequest());
  };

  return (
    <MainContent hasSideBar={false}>
      <VerticallyCenteredModal
        show={showGoodbye}
        title={'Account Deleted'}
        body={
          <>
            <p>Your account has been deleted successfully and all your personal data have been removed from the platform. </p>
            <p>Some gamplay data may have been remained on the platfrom for consistency reasons. However, they have been anonymized and disassociated from your account</p>
            <p className='font-smaller'>Sorry to see you leaving. We hope to see you again in the future...</p>
          </>
        }
        onHideFn={kickout}
      />
      <Card className='shadow-sm'>
        <Card.Header className='d-flex font-weight-bold align-items-center bg-rlck-primary txt-white'>
          Rollick Account Info
          <div className='ml-auto row d-flex align-items-center'>
            <label className='mr-2  mt-2' htmlFor='show-type'>
              {user && !showGoodbye && (
                <Button variant='warning' onClick={onDeactivate}>
                  Remove Rollick Account
                </Button>
              )}
            </label>
          </div>
        </Card.Header>
        <Card.Body>
          {user && !showGoodbye && (
            <div className='col-sm'>
              <div className='my-4'>
                <span className='text-secondary'>Name:</span> {user.name}
              </div>
              <div className='my-4'>
                <span className='text-secondary'>Email:</span> {user.email}
              </div>
              <div className='d-flex flex-row my-4 align-items-center'>
                <div>
                  <span className='text-secondary'>Role:</span> {user.role}
                </div>
                {user.role === 'player' && !user.roleRequest && (
                  <div className='ml-2'>
                    <Button variant='outline-success' size='sm' onClick={() => requestDesignerRole('designer')}>
                      {`Upgrade to Designer`}
                    </Button>
                  </div>
                )}
                {user.roleRequest && (
                  <div className='ml-2'>
                    <Button variant='outline-danger' size='sm' onClick={cancelRoleRequest}>
                      {`Cancel ${user.roleRequest.role} request`}
                    </Button>
                  </div>
                )}
              </div>
              <div className='mt-2'>
                <span className='text-secondary'>Authentication provider(s):</span>
                {!user.facebookId && !user.googleId && !user.appleId && <span className='m-2 shadow-sm rounded p-1'>Rollick</span>}
                {user.facebookId && <span className='m-2 shadow-sm border px-2'>Facebook</span>}
                {user.googleId && <span className='m-2 shadow-sm border rounded px-2'>Google</span>}
                {user.appleId && <span className='m-2 shadow-sm border px-2'>Apple</span>}
              </div>
            </div>
          )}
          {showGoodbye && (
            <Alert variant='danger' onClose={() => setShowGoodbye(false)} dismissible>
              <Alert.Heading>Account deleted</Alert.Heading>
            </Alert>
          )}
        </Card.Body>
        <Card.Footer>
          <div className='mt-2'>
            {false && !user.facebookId && !user.googleId && !user.appleId && (
              <div>
                <Button>Change your Rollick Password</Button>
              </div>
            )}
          </div>
        </Card.Footer>
      </Card>
    </MainContent>
  );
}
