import React from 'react';
import { getProperty } from 'dot-prop';
import { Popover, OverlayTrigger, Button } from 'react-bootstrap';

import { formatDate, getTotalCount, extractData, exportToCsv } from '../../utils/utils';

const InfosSummaryWidget = ({ publishedGameVersion, analytics }) => {
  const exportInfoStats = () => {
    const { infos } = publishedGameVersion?.game?.spec?.resources;
    const columns = infos.map((i) => [i.systemName]);
    columns.forEach((c, idx) => {
      const info = infos[idx];
      analytics.forEach((userState) => {
        const when = getProperty(userState, `has.seen.info.${info.id}.when`);
        if (when) {
          const seenTime = formatDate(when);
          c.push(`${userState.avatar?.name}\n(${seenTime})`);
        }
      });
    });
    const maxLength = Math.max(...columns.map((c) => c.length));
    const rows = [];
    for (let i = 0; i < maxLength; i++) {
      const row = columns.map((c) => c[i]);
      rows.push(row); // Join row elements with commas
    }
    //console.log(rows);
    exportToCsv(`InfoStats.csv`, rows);
  };

  return (
    <>
      <h5 className='d-flex flex-row justify-content-between'>
        Info Stats
        <Button style={{ float: 'right' }} onClick={exportInfoStats}>
          <i className='fas fa-download mr-2' />
          CSV
        </Button>
      </h5>
      <ul className='list-group mt-3'>
        {publishedGameVersion.game.spec.resources.infos.map((i) => {
          return (
            <li key={i.id} className='list-group-item d-flex justify-content-between align-items-center'>
              {i.title}
              <small className='font-sm text-right'>
                Seen by{' '}
                <OverlayTrigger
                  trigger='click'
                  rootClose
                  placement='right'
                  overlay={
                    <Popover content>
                      <ul className='list-group list-group-flush '>
                        {extractData(analytics, `has.seen.info.${i.id}`).length === 0 && <li className='list-group-item'>-</li>}
                        {extractData(analytics, `has.seen.info.${i.id}`).map((userState) => {
                          return (
                            <li key={userState.userId} className='list-group-item'>
                              {(userState.avatar && userState.avatar.name) || '-'}

                              <small className='d-block text-muted'>{formatDate(getProperty(userState, `has.seen.info.${i.id}.when`))}</small>
                            </li>
                          );
                        })}
                      </ul>
                    </Popover>
                  }>
                  <a>
                    <strong>{getTotalCount(analytics, `has.seen.info.${i.id}`)} players</strong>
                  </a>
                </OverlayTrigger>
              </small>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default InfosSummaryWidget;
