import React from 'react';
import history from "../../history";
import CollapseItems from "../widgets/CollapseItems";
import {bconfirm} from "../utils/ConfirmAlert";
import {deleteAttribute} from "../../actions/attributesActions";
import {connect} from "react-redux";

const AttributeList = ({attributes, game, displayAs, dispatch}) => {
    const newItemHandler = () => {
        history.push(`/games/${game.id}/attributes/create/0`);
    }
    const editItemHandler = (id) => {
        history.push(`/games/${game.id}/attributes/edit/${id}`);
    }


    const itemList = [];
    for (const item of attributes) {
        itemList.push({id:item.id,name:item.name});
    }

    if (displayAs === 'collapse')
        return (
            <CollapseItems identifier="attributelist" heading="Attributes" items={itemList}
                           newItemHandler={newItemHandler}
                           newLabel="New"
                           editItemHandler={editItemHandler}
                           deleteItemHandler={null}
                           editLabel="Settings"
                           deleteLabel="Delete"
                           additionalClass="sideBar"
            />
        );
    return <>No displayAs</>
}

//export default AttributeList;

// const mapStateToProps = (state, ownProps) => {
//     return {
//         game:state.games[ownProps.gameId],
//         scenes:state.scenes[ownProps.gameId],
//     };
// };
// export default connect(mapStateToProps,
//     (dispatch) => ({dispatch, fetchGame: fetchGame, }))(AttributeList);


//export default connect(mapStateToProps,null)(GameDesign);
export default connect(null,null)(AttributeList);