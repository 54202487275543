/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useState } from 'react';
import { exportToCsv, extractData, formatDate, getTotalCount } from '../../utils/utils';
import { Button, Modal, Row } from 'react-bootstrap';
import { getProperty, hasProperty } from 'dot-prop';

const QuizzesSummaryWidget = ({ publishedGameVersion, analytics }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [quizData, setQuizData] = useState({ userState: null });

  const renderResponse = useCallback((question, answer) => {
    if (Array.isArray(answer)) {
      return answer.map((code) => question.options.find((o) => o.code === code).txt).join(', ');
    } else {
      return answer;
    }
  }, []);

  const extractQuizResponses = () => {
    const { userState } = quizData;
    const quiz = modalData;
    const responseData = getProperty(userState, `has.answered.quiz.${quiz.id}.question`);
    if (!responseData) {
      return;
    }

    return (
      <table className='table table-bordered'>
        <tr>
          <th>Question</th>
          <th>Answer</th>
        </tr>
        {quiz.questions.map((q) => (
          <tr key={q.id}>
            <td>{q.title}</td>
            <td>{renderResponse(q, getProperty(userState, `has.answered.quiz.${quiz.id}.question.${q.id}.response`))}</td>
          </tr>
        ))}
      </table>
    );
  };

  const exportQuizResonses = () => {
    const quiz = modalData;
    const qqIds = quiz.questions.map((q) => q.id);
    const headers = ['Player/Avatar'].concat(quiz.questions.map((q) => q.title));
    const quizAnalytics = extractData(analytics, `has.answered.quiz.${quiz.id}`);
    const data = quizAnalytics.map((userResponse) => {
      return [userResponse.avatar.name].concat(
        qqIds.map((qqId) => {
          return renderResponse(
            quiz.questions.find((q) => q.id === qqId),
            getProperty(userResponse, `has.answered.quiz.${quiz.id}.question.${qqId}.response`)
          );
        })
      );
    });
    console.log({ headers, data });
    exportToCsv(`quiz_${quiz.id}.csv`, [[...headers], ...data]);
  };

  return (
    <>
      <h5>Quizzes</h5>
      <ul className='list-group mt-3'>
        {publishedGameVersion.game.spec.resources.quizzes.map((q) => {
          return (
            <li key={q.id} className='list-group-item d-flex justify-content-between align-items-center'>
              <a
                className='text-primary'
                onClick={() => {
                  setModalOpen(true);
                  setModalData(q);
                }}>
                {q.title}
              </a>
              <small className='font-sm text-right'>
                Answered: <strong>{getTotalCount(analytics, `has.answered.quiz.${q.id}`)}</strong>
                {' / '}
                Completed: <strong>{getTotalCount(analytics, `has.completed.quiz.${q.id}`)}</strong>
              </small>
            </li>
          );
        })}
      </ul>
      <Modal
        size='lg'
        show={modalOpen && !quizData.quizId}
        onHide={() => {
          setModalOpen(false);
        }}
        className='analytics'>
        <Modal.Header className='' closeButton>
          <div style={{ width: '100%' }} className='d-flex flex-grow flex-grow-1 justify-content-between'>
            {/* <div className='full-width'>Title goes here...</div> */}
            <Modal.Title className='full-width'>Quiz: {modalData && modalData.title}</Modal.Title>
            <Button className='' onClick={exportQuizResonses}>
              <i className='fas fa-download mr-2' />
              CSV
            </Button>
          </div>
        </Modal.Header>

        <Modal.Body>
          {modalData && (
            <>
              <table className='table table-bordered table-striped'>
                <thead>
                  <tr>
                    <th>Player</th>
                    <th>Completed</th>
                    <th>Datetime</th>
                  </tr>
                </thead>

                <tbody>
                  {extractData(analytics, `has.answered.quiz.${modalData.id}`).map((userState) => {
                    return (
                      <tr key={useState.userId}>
                        <td>
                          <a
                            className='text-primary'
                            onClick={() => {
                              setQuizData({
                                userState,
                                quiz: modalData,
                              });
                              setModalOpen(false);
                            }}>
                            {(userState.avatar && userState.avatar.name) || '-'}
                          </a>
                        </td>
                        <td>{hasProperty(userState, `has.completed.quiz.${modalData.id}`) ? 'Yes' : 'No'}</td>
                        <td>{formatDate(getProperty(userState, `has.completed.quiz.${modalData.id}.when`) || getProperty(userState, `has.completed.quiz.${modalData.id}.current.when`))}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        size='lg'
        show={!!quizData.quiz}
        onHide={() => {
          setQuizData({ quiz: null, userState: null });
          setModalOpen(true);
        }}
        className='analytics'>
        <Modal.Header closeButton>
          <Modal.Title>
            Quiz: {quizData?.quiz?.title}. Responses by {quizData?.userState?.avatar?.name}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className='conversation-chat'>{extractQuizResponses()}</div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default QuizzesSummaryWidget;
