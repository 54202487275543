import React from 'react';

import { formValueSelector, reduxForm, submit } from 'redux-form';
import {MISSION_FORM_NAME as FORM_NAME} from "./formTypes";

import Row   from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import FormButtons from "./FormButtons";
import {asyncValidateForm, g4mFormTotalFormValidate, g4mFormValidate} from "../utils/validationUtils";

import {connect} from "react-redux";
import Mission from "./Mission";

const MissionForm = ({error, dispatch, game, handleCancel, fieldNameParentMission, handleDelete, initialValues}) => {
    const formHandleSubmit = (form) => {
        return function handleSubmit(e) {
            e.preventDefault()
            e.stopPropagation()
            dispatch(submit(form))
        }
    }
    return (
        <Form noValidate onSubmit={formHandleSubmit(FORM_NAME)}>
            <Mission error={error} dispatch={dispatch} game={game} fieldNameParentMission={fieldNameParentMission} />
            <FormButtons validationGeneralError={error} handleCancel={handleCancel} objectId={initialValues.id} handleDelete={initialValues.id?handleDelete:null} />

        </Form>
    );

}

const validate = (formValues) => {
    const errors = {};
    // SOS SOS SOS, accomplishmentValidation(), in case 'submissions',  must have the same set of fields
    g4mFormValidate(errors, formValues, ['title','description', 'accomplishment']); // name is validated asynchronously
    g4mFormTotalFormValidate(errors);
    console.log('Mission validate errors:',errors);

    return errors;
};


export default connect(
    (state, ownProps) => {
        const selector = formValueSelector(FORM_NAME);
        const fieldNamePrefix=ownProps.fieldNameParentMission?ownProps.fieldNameParentMission+'.':'';
        return {
            //mediaId:selector(state, fieldNamePrefix+'mediaId'),
        }
    }
)(reduxForm(
    {
        form: FORM_NAME,
        validate:validate,
        asyncValidate:asyncValidateForm,
        asyncBlurFields: [ 'name' ]
    }
)(MissionForm));
