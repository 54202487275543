import React from 'react';
import history from '../../history';
import CollapseItems from '../widgets/CollapseItems';
import { bconfirm } from '../utils/ConfirmAlert';
import { deleteCharacter } from '../../actions/charactersActions';
import { connect } from 'react-redux';

const CharacterList = ({ characters, game, displayAs, dispatch }) => {
  const newCharacterHandler = () => {
    history.push(`/games/${game.id}/characters/create/0`);
  };
  const editCharacterHandler = (id) => {
    history.push(`/games/${game.id}/characters/edit/${id}`);
  };

  const characterList = [];
  for (const character of characters) {
    if (!character.isPlayer) {
      characterList.push({ id: character.id, name: character.name });
    }
  }

  if (displayAs === 'collapse')
    return (
      <CollapseItems
        identifier='characterlist'
        heading='Characters (NPC)'
        items={characterList}
        newItemHandler={newCharacterHandler}
        newLabel='New'
        editItemHandler={editCharacterHandler}
        deleteItemHandler={null}
        editLabel='Settings'
        deleteLabel='Delete'
        additionalClass='sideBar'
      />
    );
  return <>No displayAs</>;
};

//export default CharacterList;

// const mapStateToProps = (state, ownProps) => {
//     return {
//         game:state.games[ownProps.gameId],
//         scenes:state.scenes[ownProps.gameId],
//     };
// };
// export default connect(mapStateToProps,
//     (dispatch) => ({dispatch, fetchGame: fetchGame, }))(CharacterList);

//export default connect(mapStateToProps,null)(GameDesign);
export default connect(null, null)(CharacterList);
