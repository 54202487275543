import React from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import G4mTooltip from "../../utils/G4mTooltip";

import renderError from "./renderError";
const renderInput = ({input, label, placeholder, meta, type, step, min, max, inputMode, pattern, readonly, asCol, hideLabel, noFormGroup, tooltip, addonId, additionalControlClass, no_separate_error}) => {
    const className = `form-control ${no_separate_error?'no_separate_error':''} ${meta.error && meta.touched ? 'invalid error':''} ${meta.asyncValidating ? 'async-validating':''}`+ (additionalControlClass?' '+additionalControlClass+'':'');
    if (typeof no_separate_error === 'undefined') {
        no_separate_error = false;
    }
    if (typeof type === 'undefined') {
        type='text';
    }
    const labelClassName=hideLabel?'sr-only':'';
    const controlId = addonId?input.name+addonId:input.name;

    const formGroupPros = {}; // for handling <Form.Group as={Col}>
    if (asCol) {
        formGroupPros.as = Col;
    }

    const stepProp = {};
    if (step) {
        stepProp.step = step;
    }
    const minProp = {};
    if (min) {
        minProp.min = min;
    }
    const maxProp = {};
    if (max) {
        maxProp.max = max;
    }
    const patternProp = {};
    if (pattern) {
        patternProp.pattern = pattern;
    }
    const inputModeProp = {};
    if (inputMode) {
        inputModeProp.inputMode = inputMode;
    }

    const readonlyProp = {};
    if (readonly) {
        readonlyProp.readOnly = 'readOnly'; //
    }
    let tooltipControl = null;
    if (tooltip) {
        tooltipControl = <G4mTooltip tooltip={tooltip} />
    }

    if (type === 'textarea') {
        // <Form.Group controlId="exampleForm.ControlTextarea1">
        const controls =                 (
            <>
                <Form.Label className={labelClassName}>{label} {tooltipControl}</Form.Label>
                <Form.Control as="textarea" rows="3" {...input} {...readonlyProp} className={className} placeholder={placeholder || label} />
                {!no_separate_error && renderError(meta)}
            </>
        )
        if (noFormGroup) {
            return controls;
        }
        return (
            <Form.Group controlId={controlId}>
                {controls}
            </Form.Group>
        );
    }


    const controls = (
        <>
            <Form.Label className={labelClassName}>{label} {tooltipControl}</Form.Label>
            <Form.Control {...input} className={className} type={type} {...stepProp} {...minProp}  {...maxProp}  {...readonlyProp} {...inputModeProp} {...patternProp} placeholder={placeholder || label} />
            {!no_separate_error && renderError(meta)}
        </>
    )
    if (noFormGroup) {
        return controls;
    }

    return (
        <>
            <Form.Group {...formGroupPros}>
                {controls}
            </Form.Group>
        </>
    );
};

export default renderInput;



