import React from 'react';

import {Field, formValueSelector, reduxForm, submit} from 'redux-form';
import {GAMEVERSION_FORM_NAME as FORM_NAME} from "./formTypes";
import renderInput from "./renderers/renderInput";

import Row   from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import FormButtons from "./FormButtons";
import {asyncValidateForm, g4mFormTotalFormValidate, g4mFormValidate} from "../utils/validationUtils";
import renderCheckbox from "./renderers/renderCheckbox";
import {connect} from "react-redux";

//const selector = formValueSelector(FORM_NAME);
const selector = formValueSelector(FORM_NAME);

const GameVersionForm = ({error, dispatch, handleSubmit, onSubmit, game, handleCancel, isLiveSpec, handleDelete, initialValues }) => {

    // βάσει https://github.com/redux-form/redux-form/issues/3701
    // για να μην κάνει propagate το event προς τα πάνω
    // γιατί παίζει να είναι react nested (όχι DOM nested) σε άλλη φόρμα
    // και σε αυτή την περίπτωση κάνει submit και την container form
    const formHandleSubmit = (form) => {
        return function handleSubmit(e) {
            e.preventDefault()
            e.stopPropagation()
            dispatch(submit(form))
        }
    }
    // έτσι κι αλλιώς, η onSubmit που έρχετε από τα props θα κληθεί αυτόματα
    /*
        const myOnSubmit = (formValues) => {
            console.log('SKATA ----------- myOnSubmit GameVersionForm ', formValues);
            onSubmit(formValues);
        };
    */
    //                    <Form noValidate onSubmit={handleSubmit(myOnSubmit)}>
    return (
        <Form noValidate onSubmit={formHandleSubmit(FORM_NAME)}>


            <Field name="version" readonly={true} label="Version" asCol={false} component={renderInput} />
            <Field name="versionNotes" type="textarea" label="Public Version Notes" asCol={false} component={renderInput} />


            <Form.Row>
                <Col>
                    <Field component={renderCheckbox} name="restrictAccessToDesigner" type="checkbox" label="Only for Game Owner" disabled={isLiveSpec} />
                </Col>
                <Col>
                    <Field component={renderCheckbox} name="multipleInstances" type="checkbox" label="Allow Multiple Instances" />
                </Col>
                <Col>
                    <Field component={renderCheckbox} name="isLiveSpec" type="checkbox" label="Live spec" disabled={true} />
                </Col>

            </Form.Row>
            <FormButtons saveButtonText="Publish" validationGeneralError={error} handleCancel={handleCancel} objectId={initialValues.id} handleDelete={initialValues.id?handleDelete:null} />


        </Form>
    );

}

const validate = (formValues) => {
    const errors = {};
    g4mFormValidate(errors, formValues, []); // name is validated asynchronously
    g4mFormTotalFormValidate(errors);
    //console.log('GameVersion validate errors:',errors);

    return errors;
};


// export default reduxForm({
//     form: FORM_NAME,
//     validate:validate,
//     asyncValidate:asyncValidateForm,
//     asyncBlurFields: [ 'name' ]
// })(GameVersionForm);


export default connect(
    (state, ownProps) => {
        return {
            isLiveSpec:selector(state, 'isLiveSpec'),
        }
    }
)(reduxForm(
    {
        form: FORM_NAME,
        validate:validate
    }
)(GameVersionForm));
