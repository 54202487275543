//import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
//import Col from "react-bootstrap/Col";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toggleSideBarMenuItem } from '../../actions/appActions';
import './CollapseItems.css';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { getUserRightsOnGame } from '../../utils/utils';

//const isEmpty = require("is-empty");
const STATE_COOKIE_NAME = 'g4mAccStates';

const CollapseItems = ({
  showCount,
  numbering,
  items,
  heading,
  identifier,
  newItemHandler,
  newItemArgument,
  newLabel,
  editItemHandler,
  editLabel,
  visitItemHandler,
  visitLabel,
  deleteItemHandler,
  deleteLabel,
  additionalClass,
  moveHandler,
  collapsible = true,
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const open = useSelector((state) => !!state.app.menu[identifier]);
  const toggleAccordion = () => {
    dispatch(toggleSideBarMenuItem(identifier));
  };
  const { gameId } = useParams();
  const game = useSelector((state) => state.games[gameId]);
  const user = useSelector((state) => state.auth.user);
  const viewer = !getUserRightsOnGame(game, user).includes('edit');
  const getItemList = () => {
    if (items.length === 0) {
      return <p>No items</p>;
    }
    return items.map((item, index) => {
      //const itemId = item.id;
      let label = (
        <span>
          {item.specialNamePrefixMarkup} {item.name}
        </span>
      );
      if (editItemHandler) {
        label = (
          <span className='clickable-collapsible-label' onClick={() => editItemHandler(item.id)} title={editLabel}>
            {`${numbering ? index + 1 + '.' : ''}`} {item.specialNamePrefixMarkup} {item.name}
          </span>
        );
      }
      return (
        <li key={item.id}>
          <div title={item.title ? item.title : ''}>{label}</div>

          <div className={'controls'}>
            {visitItemHandler && (
              <Button className={'mr-1'} onClick={() => visitItemHandler(item.id)} title={visitLabel}>
                <i className='fas fa-magic'></i>
                <span className={'controlLabel'}> {visitLabel}</span>
              </Button>
            )}
            {deleteItemHandler && (
              <Button disabled={viewer} className={'mr-1'} variant='outline-danger' onClick={() => deleteItemHandler(item.id)} title={deleteLabel}>
                <i className='fa fa-times'></i>
                <span className={'controlLabel'}> {deleteLabel}</span>
              </Button>
            )}
            {moveHandler && (
              <>
                <Button
                  style={{ borderWidth: '0px' }}
                  disabled={index === 0 || viewer}
                  className={'mr-1'}
                  variant='outline-secondary'
                  onClick={() => {
                    //idx, direction
                    moveHandler(index, 'up');
                  }}
                  title={`Move ${label} up`}>
                  <i className='fa fa-caret-up'></i>
                  <span className={'controlLabel sr-only'}> {`Move ${label} up`}</span>
                </Button>
                <Button
                  disabled={index === items.length - 1 || viewer}
                  style={{ borderWidth: '0px' }}
                  className={'mr-1'}
                  variant='outline-secondary'
                  onClick={() => {
                    //idx,direction
                    moveHandler(index, 'down');
                  }}
                  title={`Move ${label} down`}>
                  <i className='fa fa-caret-down'></i>
                  <span className={'controlLabel sr-only'}> {`Move ${label} down`}</span>
                </Button>
              </>
            )}
          </div>
        </li>
      );
    });
  };

  let collapsibleStateClass = 'closed';
  if (open) {
    collapsibleStateClass = 'opened';
  }
  let newItemControl = null;
  if (newItemHandler) {
    newItemControl = (
      <li title={newLabel} onClick={newItemArgument ? () => newItemHandler(newItemArgument) : newItemHandler}>
        <Button disabled={viewer} variant='success' style={{ minWidth: 150 }}>
          {' '}
          <i className='fas fa-plus'></i> {newLabel}
        </Button>
      </li>
    );
  }

  if (!collapsible) {
    return (
      <div id={identifier}>
        <ul className='g4mcollapsible-content'>
          {newItemControl}
          {getItemList()}
        </ul>
      </div>
    );
  }

  return (
    <div className={`g4mcollapsible col-12 ${collapsibleStateClass} ${additionalClass}`} id={identifier}>
      <div className='g4mcollapsible-heading'>
        <div onClick={toggleAccordion} aria-controls={identifier + '-collapse'} className={'font-weight-bold d-flex w-100'}>
          {heading} {showCount && `(${items.length})`}
          <span className='ml-auto'>
            <i className='fas fa-angle-down showopened'></i>
            <i className='fas fa-angle-right closedicon showclosed'></i>
          </span>
        </div>
      </div>
      <Collapse in={open}>
        <ul className='g4mcollapsible-content' id={identifier + '-collapse'}>
          {newItemControl}
          {getItemList()}
        </ul>
      </Collapse>
    </div>
  );
};
CollapseItems.propTypes = {
  showCount: PropTypes.bool,
};

CollapseItems.defaultProps = {
  showCount: true,
};

export default CollapseItems;
