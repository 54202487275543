import _ from 'lodash';
import {
    DELETE_SCENE,
    EDIT_SCENE,
    FETCH_SCENE,
    CREATE_SCENE,
    FETCH_GAME_SCENES
} from '../actions/types';

export default (state={},action) => {
    switch (action.type) {
        case DELETE_SCENE:
            return _.omit(state, action.payload);
        case FETCH_GAME_SCENES:
            // return only the data returned, and not the previous data
//            return {..._.mapKeys(action.payload, 'id')};
            return {...state, ..._.mapKeys(action.payload, 'id')};
        case FETCH_SCENE:
            return {...state, [action.payload.id]:action.payload};
        case CREATE_SCENE:
            return {...state, [action.payload.id]:action.payload};
        case EDIT_SCENE:
            return {...state, [action.payload.id]:action.payload};
        default:
            return state;
    }
}