//import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import history from '../../history';
import MarkerMap from '../gmap/MarkerMap';
import CollapseItems from '../widgets/CollapseItems';
import { bconfirm } from '../utils/ConfirmAlert';
import { deleteScene } from '../../actions/scenesActions';
import { ICON_URL_LOCATION } from '../gmap/iconUrls';

//const isEmpty = require("is-empty");

const SceneList = ({ scenes, locations, game, displayAs, dispatch }) => {
  const newItemHandler = () => {
    history.push(`/games/${game.id}/scenes/create/0`);
  };
  const editItemHandler = (sceneId) => {
    history.push(`/games/${game.id}/scenes/edit/${sceneId}`);
  };
  const visitItemHandler = (sceneId) => {
    history.push(`/games/${game.id}/scenes/show/${sceneId}`);
  };

  // const skata = () => {
  //         alert('skata');
  // }
  if (displayAs === 'map') {
    // calling allowedEditingFilter(object), will return a new object with only this specific attributes. Like laravel fillable
    const allowedEditingFilter = ({ name, lat, lng, radius }) => ({ name, lat, lng, radius });
    const markersData = [];
    //console.log("Number of scenes before markersData:",scenes.length);

    const locationObject = allowedEditingFilter(game);
    locationObject.onlyRadius = true;
    locationObject.typeLabel = 'Game center';
    locationObject.radiusColor = '#ffffff00';
    locationObject.strokeWeight = 3;
    markersData.push(locationObject);

    scenes.map((markerData) => {
      if (!markerData.universe) {
        const locationObject = allowedEditingFilter(markerData);
        locationObject.onlyRadius = true;
        locationObject.typeLabel = 'Scene';
        markersData.push(locationObject);
      }
    });
    //console.log("Number of locations before markersData:",locations.length);
    locations.map((markerData) => {
      const locationObject = allowedEditingFilter(markerData);
      locationObject.onlyRadius = true;
      locationObject.iconUrl = ICON_URL_LOCATION;
      locationObject.typeLabel = 'Location';
      locationObject.radiusColor = '#520040';

      //markerData.clickHandler = skata; // de douleyei ayto
      //console.log(markerData.clickHandler);
      //console.log('Pushing to markersData ', locationObject);
      markersData.push(locationObject);
    });
    //markersData.concat(locations);

    return (
      <>
        <MarkerMap markersData={markersData} height={'650px'}></MarkerMap>
      </>
    );
  }

  const itemList = [];
  for (const item of scenes) {
    //if (!item.universe) {
    itemList.push({ id: item.id, name: item.name });
    //}
  }

  if (displayAs === 'collapse') {
    // gia tis skhnes, to editItemHandler odhgei sto visit. Kai gia na kaneis edit settings, to kaneis afou kaneis visit me eidiko koumpi
    // opote to editItemHandler={visitItemHandler}
    return (
      <CollapseItems
        identifier='scenelist'
        heading='Scenes'
        items={itemList}
        newItemHandler={newItemHandler}
        newLabel='New'
        editItemHandler={visitItemHandler}
        deleteItemHandler={null}
        editLabel='Settings'
        visitLabel='View'
        deleteLabel='Delete'
        additionalClass='sideBar'
      />
    );
  }
  return <>No displayAs</>;
};

//export default SceneList;

// const mapStateToProps = (state, ownProps) => {
//     return {
//         game:state.games[ownProps.gameId],
//         scenes:state.scenes[ownProps.gameId],
//     };
// };
// export default connect(mapStateToProps,
//     (dispatch) => ({dispatch, fetchGame: fetchGame, }))(ItemList);

// I need dispatch for deleting a scene. So use connect just so that dispatch is passed to SceneList as a prop
export default connect(null, null)(SceneList);
