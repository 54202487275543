import React from "react";
import Form from "react-bootstrap/Form";

const  renderError = (meta, evenIfNotTouched) => {
    const error = meta.error;
    const touched = meta.touched;
    if (touched && error) {
        return (
            <Form.Text className="text-muted">
                <span className={"red-text"}>{error}</span>
            </Form.Text>
        );
    }
    else if (error && evenIfNotTouched) {
        return (
            <Form.Text className="text-muted">
                <span className={"red-text"}>{error}</span>
            </Form.Text>
        );
    }

};

export default renderError;


