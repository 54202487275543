import React from 'react';
import {connect} from "react-redux";

const SideBar = ({ children, sidebarHidden }) => {
    return (
        <nav id="sidebar" className={`${sidebarHidden?'active':''} p-3`}>

            {children}
        </nav>
    );
};

//export default SideBar;
const mapStateToProps = (state) => {
    return {
        sidebarHidden:state.app.sidebarHidden
    };
};
export default connect(mapStateToProps,null)(SideBar);
