import React, { useState } from 'react';

import { change, Field, formValueSelector, reduxForm } from 'redux-form';
import { connect, useSelector } from 'react-redux';
import { OFFER_FORM_NAME as FORM_NAME } from './formTypes';
import renderInput from './renderers/renderInput';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LimitAction from './LimitAction';
import Form from 'react-bootstrap/Form';
import FormButtons from './FormButtons';
import renderCheckbox from './renderers/renderCheckbox';
import ImageChoose from './ImageChoose';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { asyncValidateForm, g4mFormTotalFormValidate, g4mFormValidate, validateObjectBehavior } from '../utils/validationUtils';
import ConditionForm2 from './ConditionForm2';
import SideEffectForm2 from './SideEffectForm2';
import ModalEdit from '../utils/ModalEdit';
import InteractionEdit from '../interactions/InteractionEdit';

import { useTranslation } from 'react-i18next';
import RichTextEditorWYSIWYG from './renderers/RichTextEditorWYSIWYG';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import CustomToggle from '../utils/CustomToggle';

const selector = formValueSelector(FORM_NAME);
let lastId = Math.floor(Math.random() * 10000);
const generatedId = () => {
  lastId = lastId + 1;
  return lastId;
};

const OfferForm = ({
  error,
  dispatch,
  handleSubmit,
  onSubmit,
  handleCancel,
  initialValues,
  logoId,
  game,
  offerCover,
  offerorLogo,
  playerGenerated,
  archetypePickable,
  archetypeDestroyable,
  handleDelete,
}) => {
  const { t } = useTranslation();

  let allInteractions = useSelector((state) => state.interactions);
  let gameInteractions = Object.values(allInteractions).filter(function (el) {
    return el.gameId === game.id;
  });
  const [openEditInteractionModal, setOpenEditInteractionModal] = useState(false);
  const [newInteractionType, setNewInteractionType] = useState(null);
  const [newInteractionFieldToUpdate, setNewInteractionFieldToUpdate] = useState(null);
  const [selectedInteractionToEdit, setSelectedInteractionToEdit] = useState({});
  const handleInteractionSaved = (savedInteraction) => {
    closeEditInteractionModal();
    // we should select the saved interaction in the dropdown list
    // savedInteraction.id;

    //dispatch(change(FORM_NAME, "activateInteractionId", savedInteraction.id));
    dispatch(change(FORM_NAME, newInteractionFieldToUpdate, savedInteraction.id));
  };
  const closeEditInteractionModal = () => {
    setOpenEditInteractionModal(false);
  };
  const cancelEditInteractionModal = () => {
    setOpenEditInteractionModal(false);
  };

  const newInteractionHandler = (type, fieldToUpdateAfterSave) => {
    setSelectedInteractionToEdit(null);
    setNewInteractionType(type);
    setNewInteractionFieldToUpdate(fieldToUpdateAfterSave);
    setOpenEditInteractionModal(true);
    //history.push(`/games/${game.id}/interactions/create/0/${scene.id}`);
  };
  const editInteractionHandler = (id) => {
    const interaction = gameInteractions.find((element) => element.id === id);
    setSelectedInteractionToEdit(interaction);
    setOpenEditInteractionModal(true);
    //history.push(`/games/${game.id}/interactions/edit/${id}`);
  };
  const myOnSubmit = (formValues) => {
    onSubmit(formValues);
  };

  return (
    <>
      <Form noValidate onSubmit={handleSubmit(myOnSubmit)}>
        <Field name='name' label={t('NAME_FIELD_LABEL')} tooltip={t('NAME_FIELD_TOOLTIP')} asCol={false} component={renderInput} />

        <Field name='title' label='Enter title' asCol={false} component={renderInput} />

        <Form.Row>
          <Col lg={10} md={9} sm={8}>
            <Field label='Description' name='description' component={RichTextEditorWYSIWYG} />
          </Col>
          <Col lg={2} md={3} sm={4}>
            <Field
              name='logoId'
              label='Icon'
              logoIdToShow={logoId}
              handleRemoveMedia={() => dispatch(change(FORM_NAME, 'logoId', null))}
              gameId={initialValues && initialValues.gameId}
              handleSetMediaId={(newId) => dispatch(change(FORM_NAME, 'logoId', newId))}
              imageWidth={300}
              component={ImageChoose}
            />
          </Col>
        </Form.Row>

        <Field name='quantity' label='Quantity' asCol={false} component={renderInput} />

        <Field name='offerorName' label='Offeror Name' asCol={false} component={renderInput} />

        <Field name='offerorAddress' label='Offeror Address' asCol={false} component={renderInput} />

        <Field name='offerorUrl' label='Offeror Url' asCol={false} component={renderInput} />

        <Field label='Offer Text' name='offerText' component={RichTextEditorWYSIWYG} />

        <Field label='Offer Terms' name='offerTerms' component={RichTextEditorWYSIWYG} />

        <Form.Row>
          <Col sm={6}>
            <Field
              name='offerCover'
              label='Offer Cover'
              logoIdToShow={offerCover}
              handleRemoveMedia={() => dispatch(change(FORM_NAME, 'offerCover', null))}
              gameId={initialValues && initialValues.gameId}
              handleSetMediaId={(newId) => dispatch(change(FORM_NAME, 'offerCover', newId))}
              imageWidth={800}
              component={ImageChoose}
            />
          </Col>
          <Col sm={6}>
            <Field
              name='offerorLogo'
              label='Offeror Logo'
              logoIdToShow={offerorLogo}
              handleRemoveMedia={() => dispatch(change(FORM_NAME, 'offerorLogo', null))}
              gameId={initialValues && initialValues.gameId}
              handleSetMediaId={(newId) => dispatch(change(FORM_NAME, 'offerorLogo', newId))}
              imageWidth={800}
              component={ImageChoose}
            />
          </Col>
        </Form.Row>

        {/*----------------------*/}

        <Accordion defaultActiveKey='2' className={'mt-4'}>
          <Card>
            <Card.Header className={'archetypesub-header'}>
              <CustomToggle eventKey={1 + ''} clickText={`Creation`}>
                <></>
              </CustomToggle>
            </Card.Header>
            {
              // need eventKey as string !
            }
            <Accordion.Collapse className={'accordion-content  p-3'} eventKey={1 + ''}>
              <>
                <Form.Row>
                  <Col lg={4} sm={5}>
                    <Field placementFixInline disabled component={renderCheckbox} name='playerGenerated' type='checkbox' label='Player can create objects of this type' />
                  </Col>
                  <Col lg={8} sm={7}></Col>
                </Form.Row>
              </>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header className={'archetypesub-header'}>
              <CustomToggle eventKey={2 + ''} clickText={`Perception`}>
                <></>
              </CustomToggle>
            </Card.Header>
            {
              // need eventKey as string !
            }
            <Accordion.Collapse className={'accordion-content  p-3'} eventKey={2 + ''}>
              <>
                <Form.Row>
                  <Col>
                    <Field component={renderCheckbox} name='trackable' type='checkbox' label='Trackable' />
                  </Col>
                  <Col>
                    <Field component={renderCheckbox} name='visible' type='checkbox' label='Visible' />
                  </Col>
                </Form.Row>
                <fieldset className='border p-2 mb-3'>
                  <legend className='w-auto legend-small'>Perceived only if</legend>
                  <Form.Row>
                    <Col>
                      <ConditionForm2 dispatch={dispatch} scene={null} game={game} baseFieldName={'conditionPerception'} formName={FORM_NAME}></ConditionForm2>
                    </Col>
                  </Form.Row>
                </fieldset>
              </>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header className={'archetypesub-header'}>
              <CustomToggle eventKey={3 + ''} clickText={`Behavior`}>
                <></>
              </CustomToggle>
            </Card.Header>
            {
              // need eventKey as string !
            }
            <Accordion.Collapse className={'accordion-content p-3'} eventKey={3 + ''}>
              <>
                <Tabs defaultActiveKey='general' id='archetypetabs'>
                  <Tab className={'pt-4'} eventKey='general' title='General'>
                    <Row>
                      <Col sm={12}>
                        <Form.Row>
                          <Col>
                            <Field asCol={false} component={renderInput} type='number' step='1' min='5' name='radius' label='Activity Range (m)' tooltip={t('OBJECT_RADIUS_TOOLTIP')} />
                          </Col>
                        </Form.Row>
                        <Form.Row>
                          <Col lg={2}>
                            <Field placementFixInline disabled component={renderCheckbox} name='pickable' type='checkbox' label='Pickable' />
                          </Col>
                          <Col>
                            {archetypePickable && (
                              <LimitAction dispatch={dispatch} game={game} baseFieldName={'limitPicks'} formName={FORM_NAME} itemsLabel={'objects'} enableLimitLabel={'Restrictions'}></LimitAction>
                            )}
                          </Col>
                        </Form.Row>
                      </Col>
                    </Row>
                  </Tab>
                  <Tab className={'pt-4'} eventKey='oncontact' title='Upon contact'>
                    <Form.Row>
                      <Col>
                        <Field component={renderCheckbox} name='inRangeOnlyContact' type='checkbox' label='In range only' />
                      </Col>
                      <Col>
                        <Field component={renderCheckbox} name='automaticContact' type='checkbox' label='Automatic' />
                      </Col>
                    </Form.Row>

                    <fieldset className='border p-2 mb-3'>
                      <legend className='w-auto legend-medium'>Do the following</legend>
                      <Form.Row>
                        <Col>
                          <Field component={renderInput} name='actionLabelContact' label='Interaction label' />
                        </Col>
                      </Form.Row>
                      <Form.Row>
                        <Col>
                          <div className={'sideeffectssection'}>
                            <SideEffectForm2 fieldName='sideeffectsContact' game={game} newInteractionHandler={newInteractionHandler} formName={FORM_NAME} forArcheType={true}></SideEffectForm2>
                          </div>
                        </Col>
                      </Form.Row>
                    </fieldset>

                    <fieldset className='border p-2 mb-3'>
                      <legend className='w-auto legend-medium'>But only if</legend>
                      <Form.Row>
                        <Col>
                          <ConditionForm2 dispatch={dispatch} scene={null} game={game} baseFieldName={'conditionContact'} formName={FORM_NAME}></ConditionForm2>
                        </Col>
                      </Form.Row>
                    </fieldset>

                    <fieldset className='border p-2 mb-3'>
                      <legend className='w-auto legend-medium'>Else do the following</legend>
                      <Form.Row>
                        <Col>
                          <div className={'sideeffectssection'}>
                            <SideEffectForm2
                              fieldName='sideeffectselseContact'
                              game={game}
                              newInteractionHandler={newInteractionHandler}
                              formName={FORM_NAME}
                              forArcheType={true}
                              isElse={true}></SideEffectForm2>
                          </div>
                        </Col>
                      </Form.Row>
                    </fieldset>
                  </Tab>
                  <Tab className={'pt-4'} eventKey='onact' title='Upon action'>
                    <Form.Row>
                      <Col>
                        <Field placementFix component={renderInput} name='actionLabelAct' label='Interaction label' />
                      </Col>
                      <Col>
                        <Field placementFix component={renderCheckbox} name='inRangeOnlyAct' type='checkbox' label='In range only' />
                      </Col>
                      <Col>
                        <Field placementFix component={renderCheckbox} name='automaticAct' type='checkbox' label='Automatic' />
                      </Col>
                    </Form.Row>
                    <fieldset className='border p-2 mb-3'>
                      <legend className='w-auto legend-medium'>But only if</legend>
                      <Form.Row>
                        <Col>
                          <ConditionForm2 dispatch={dispatch} scene={null} game={game} baseFieldName={'conditionAct'} formName={FORM_NAME}></ConditionForm2>
                        </Col>
                      </Form.Row>
                    </fieldset>
                    <fieldset className='border p-2 mb-3'>
                      <legend className='w-auto legend-medium'>Do the following</legend>

                      <Form.Row>
                        <Col>
                          <div className={'sideeffectssection'}>
                            <SideEffectForm2 fieldName='sideeffectsAct' game={game} newInteractionHandler={newInteractionHandler} formName={FORM_NAME} forArcheType={true}></SideEffectForm2>
                          </div>
                        </Col>
                      </Form.Row>
                    </fieldset>
                    <fieldset className='border p-2 mb-3'>
                      <legend className='w-auto legend-medium'>Else do the following</legend>

                      <Form.Row>
                        <Col>
                          <div className={'sideeffectssection'}>
                            <SideEffectForm2 fieldName='sideeffectselseAct' game={game} newInteractionHandler={newInteractionHandler} formName={FORM_NAME} forArcheType={true}></SideEffectForm2>
                          </div>
                        </Col>
                      </Form.Row>
                    </fieldset>
                  </Tab>
                  <Tab className={'pt-4'} eventKey='onhold' title='While owned'>
                    <Form.Row>
                      <Col>
                        <Field placementFix disabled component={renderCheckbox} name='redropable' type='checkbox' label='Redropable' />
                      </Col>

                      {game.market && (
                        <>
                          <Col>
                            <Field placementFix component={renderCheckbox} name='tradable' type='checkbox' label='Tradeable' />
                          </Col>
                          <Col>
                            <Field placementFix component={renderCheckbox} name='redeemable' type='checkbox' label='Redeemable' />
                          </Col>
                          <Col>
                            <Field name='price' label={`Price (${game.currencyName})`} asCol={false} type='number' step='1' component={renderInput} />
                          </Col>
                        </>
                      )}
                    </Form.Row>
                  </Tab>
                </Tabs>
              </>
            </Accordion.Collapse>
          </Card>
        </Accordion>

        <FormButtons validationGeneralError={error} handleCancel={handleCancel} objectId={initialValues.id} handleDelete={initialValues.id ? handleDelete : null} />
      </Form>

      <ModalEdit openEditModal={openEditInteractionModal} closeEditModal={closeEditInteractionModal} heading={'Edit or create scene element'} portal='interaction'>
        <InteractionEdit game={game} interaction={selectedInteractionToEdit} newInteractionType={newInteractionType} onSave={handleInteractionSaved} onCancel={cancelEditInteractionModal} />
      </ModalEdit>
    </>
  );
};

const validate = (formValues) => {
  //console.log("FORMVALUES ",formValues);
  const errors = {};
  const fieldNames = [
    'title',
    'logoId',
    'conditionCreation',
    'conditionPerception',
    'conditionContact',
    'conditionAct',
    'sideeffectsContact',
    'sideeffectsAct',
    'sideeffectselseContact',
    'sideeffectselseAct',
    'actionLabelContact',
    'playerGenerated',
    'pickable',
    'destroyable',
  ];
  if (formValues['sideeffectsAct'] && formValues['sideeffectsAct'].length > 0) {
    fieldNames.push('actionLabelAct');
  }
  g4mFormValidate(errors, formValues, fieldNames); // name is validated asynchronously
  g4mFormTotalFormValidate(errors);

  const payloadSpecArrayErrors = [];
  if (formValues.payloadSpec && formValues.payloadSpec.length) {
    formValues.payloadSpec.forEach((aPayloadSpec, payloadSpecIndex) => {
      const payloadSpecErrors = {};
      if (!aPayloadSpec || !aPayloadSpec.type) {
        payloadSpecErrors.type = 'Required';
        payloadSpecArrayErrors[payloadSpecIndex] = payloadSpecErrors;
      }
      if (!aPayloadSpec || !aPayloadSpec.label) {
        payloadSpecErrors.label = 'Required';
        payloadSpecArrayErrors[payloadSpecIndex] = payloadSpecErrors;
      }
    });
  }
  validateObjectBehavior(errors, formValues);

  if (payloadSpecArrayErrors.length) {
    errors.payloadSpec = payloadSpecArrayErrors;
  }

  return errors;
};

export default connect((state, ownProps) => {
  return {
    logoId: selector(state, 'logoId'),
    offerCover: selector(state, 'offerCover'),
    offerorLogo: selector(state, 'offerorLogo'),
    playerGenerated: selector(state, 'playerGenerated'),
    archetypePickable: selector(state, 'pickable'),
    archetypeDestroyable: selector(state, 'destroyable'),
  };
})(
  reduxForm({
    form: FORM_NAME,
    validate: validate,
    asyncValidate: asyncValidateForm,
    asyncBlurFields: ['name'],
  })(OfferForm)
);
