import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { find } from 'lodash';
import ReactJson from 'react-json-view';
import { Card, Modal, Button, Col, Row, Nav, Tab, Alert, Image } from 'react-bootstrap';
import { sendMessageToGame } from '../../actions/gameInstancesActions';

export const PLAYER_STATUS = {
  PLAYING: 'playing',
  PAUSED: 'paused',
  LEFT_GAME: 'left game',
  JOINED_NEW_INSTANCE: 'movedTo',
};

export const extractStatusWhen = (state) => {
  let when = '';
  try {
    switch (state.status) {
      case PLAYER_STATUS.LEFT_GAME:
        when = state.has.left.game.when;
        break;
      case PLAYER_STATUS.PLAYING:
        when = state.has.started.game.when;
        break;
      case PLAYER_STATUS.PAUSED:
        when = state.lastPlayed;
        break;
      case PLAYER_STATUS.JOINED_NEW_INSTANCE:
        when = state.lastPlayed;
        break;
      default:
        when = null;
    }
  } catch (e) {}

  return when && moment(when).utc().format();
};

const UserInfoModal = ({ spec = {}, player, isOpen, gameId, onHide, isThinData = false, showStateTree = false, gameSpec }) => {
  const [isMessageUserModalOpen, setIsMessageUserModalOpen] = useState(false);
  const [message, setMessage] = useState(null);
  const [asyncMsg, setAsyncMsg] = useState(true);
  const dispatch = useDispatch();

  if (!player) {
    return null;
  }

  const sendMessage = () => {
    dispatch(
      sendMessageToGame(gameId, {
        userId: player.id,
        message,
        asynchronous: asyncMsg,
      })
    );
    setIsMessageUserModalOpen(false);
    setMessage(null);
  };

  const extractResource = (type, id) => {
    return find(spec.resources[type], (i) => i.id === id);
  };

  const scenes = gameSpec && gameSpec.spec && gameSpec.spec.script.scenes;

  const renderTree = (type) => {
    let items = [];
    let itemIds = [];

    switch (type) {
      case 'items':
        itemIds = player.state && player.state.owns && Object.keys(player.state.owns.item);
        items =
          itemIds &&
          itemIds.map((itemId) => ({
            object: extractResource(type, itemId) || {},
            value: player.state.owns.item[itemId],
            showCount: true,
          }));
        break;
      case 'objects':
        const objectTypeIds = player.state && player.state.owns && Object.keys(player.state.owns.object);
        const grouppedInstances = objectTypeIds && objectTypeIds.map((oId) => player.state.owns.object[oId]);
        items =
          grouppedInstances &&
          grouppedInstances.map((gi) => ({
            object: Object.values(gi)[0],
            value: Object.values(gi).length,
            showCount: true,
          }));
        break;
      case 'attributes':
        itemIds = player.state && player.state.is && player.state.is.attributed && player.state.is.attributed.attribute && Object.keys(player.state.is.attributed.attribute);
        items =
          itemIds &&
          itemIds.map((itemId) => ({
            itemId,
            object: extractResource(type, itemId) || {},
            value: player.state.is.attributed[itemId],
          }));
        break;
    }

    return (
      <ul className='list-group'>
        {items && items.length === 0 && (
          <li className='list-group-item'>
            <i>This user has no {type}.</i>
          </li>
        )}
        {items &&
          items.map(({ object: { iconUri, title }, showCount, value }) => (
            <li className='list-group-item'>
              {iconUri && <img height='25' className='mr-2' src={iconUri} />}
              {title} {!!showCount && `: ${value}`}
            </li>
          ))}
      </ul>
    );
  };

  return (
    <>
      <Modal show={isOpen && !isMessageUserModalOpen} onHide={onHide} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Player Info</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className='mb-2 d-flex'>
            {player.state && player.state.avatar && player.state.avatar.photo && player.state.avatar.photo.uri && <Image height={120} width={120} roundedCircle src={player.state.avatar.photo.uri} />}

            <div className='ml-auto'>
              <Button
                onClick={() => {
                  setIsMessageUserModalOpen(true);
                }}>
                <i className='mr-2 fa fa-envelope'></i>
                Message {player.name}
              </Button>
            </div>
          </div>

          <div className='mb-2'>
            <span className='text-secondary'>Name:</span> {player.name}
          </div>

          <div className='mb-2'>
            <span className='text-secondary'>Email:</span> {player.email}
          </div>

          <div className='mb-2'>
            <span className='text-secondary'>Status:</span> {isThinData ? player.status : player.state && player.state.status}
            {isThinData && player.statusWhen && (
              <span className='pl-2'>
                (<time>{moment(player.statusWhen).format('DD MMM YYYY, HH:mm')}</time>)
              </span>
            )}
            {!isThinData && player.state && (
              <span className='pl-2'>
                (<time>{moment(extractStatusWhen(player.state)).format('DD MMM YYYY, HH:mm')}</time>)
              </span>
            )}
          </div>

          {showStateTree && (
            <>
              <hr />

              <div className='row'>
                <div className='col-12'>
                  <Card>
                    <Card.Body>
                      <Tab.Container id='left-tabs-example' defaultActiveKey='items'>
                        <Row>
                          <Col sm={3}>
                            <Nav variant='pills' className='flex-column'>
                              <Nav.Item>
                                <Nav.Link eventKey='items'>Items</Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey='objects'>Objects</Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey='attributes'>Skills</Nav.Link>
                              </Nav.Item>
                            </Nav>
                          </Col>

                          <Col sm={9}>
                            <Tab.Content>
                              <Tab.Pane eventKey='items'>{renderTree('items')}</Tab.Pane>
                              <Tab.Pane eventKey='objects'>{renderTree('objects')}</Tab.Pane>
                              <Tab.Pane eventKey='attributes'>{renderTree('attributes')}</Tab.Pane>
                            </Tab.Content>
                          </Col>
                        </Row>
                      </Tab.Container>
                    </Card.Body>
                  </Card>
                </div>
              </div>

              <hr />

              <ReactJson enableClipboard={false} collapsed name={null} displayDataTypes={false} src={player.state} />
            </>
          )}
        </Modal.Body>
      </Modal>

      <Modal show={isMessageUserModalOpen} onHide={() => setIsMessageUserModalOpen(false)} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Message {player.name}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className='mb-2'>
            <form>
              <div className='form-group'>
                <label htmlFor='message'>Message</label>
                <textarea onChange={(e) => setMessage(e.target.value)} style={{ height: '200px' }} placeholder='Type your massage here...' name='message' className='form-control'></textarea>
                <div className='mt-3'>
                  <div className=' d-flex align-items-center'>
                    <div className='col-9'>
                      <input type='checkbox' name='async-message' checked={asyncMsg} onChange={() => setAsyncMsg(!asyncMsg)} />
                      <label style={{ paddingLeft: 5 }}>Asynchronous Message</label>
                    </div>
                    <div className='col-3 text-right'>
                      <button type='button' onClick={sendMessage} className='btn btn-primary'>
                        Send message
                      </button>
                    </div>
                  </div>
                </div>
                {!asyncMsg && (
                  <div className='mt-3'>
                    <Alert variant='warning' className='mb-4'>
                      Synchronous messages are delivered only if the player is online and has the Rollick App open.
                    </Alert>
                  </div>
                )}
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UserInfoModal;
