import gamesApi from "../apis/gamesApi";
import history from "../history";
import {
    FETCH_GAME_CHARACTERS,
    FETCH_CHARACTER,
    EDIT_CHARACTER,
    CREATE_CHARACTER,
    DELETE_CHARACTER
} from "./types";
import {displayError, displaySuccess} from "../components/utils/notifications";


export const createCharacter = (gameId, formValues) =>  async (dispatch) => {
    return new Promise((resolve, reject) => {
        gamesApi.post(`/api/design/characters?gameId=${gameId}`,{...formValues, gameId})
            .then(response => {
                dispatch({type:CREATE_CHARACTER, payload:response.data});
                displaySuccess(`Character ${response.data.name} created`);
                resolve();
                //history.push(`/games/${gameId}`);
            })
            .catch(err => {
                displayError(err, `Error creating character`);
                reject();
            });
    });

};

export const fetchGameCharacters = (gameId) => async (dispatch) => {
    if (gameId) {
        return new Promise((resolve, reject) => {
            gamesApi.get(`/api/design/characters?gameId=${gameId}`)
                .then(response => {
                    dispatch({type:FETCH_GAME_CHARACTERS, payload:response.data});
                    resolve();
                })
                .catch(err => {
                    displayError(err, `Error retrieving game characters`);
                    reject();
                });
        });
    }
    else {
        return new Promise((resolve, reject) => {
            dispatch({type: FETCH_GAME_CHARACTERS, payload: []});
            displayError(null, `No gameId id in fetchGameCharacters`);
            reject();
        });
    }
};

export const fetchCharacter = (id) =>  async (dispatch) => {
    return new Promise((resolve, reject) => {
        gamesApi.get(`/api/design/characters/${id}`)
            .then(response => {
                dispatch({type:FETCH_CHARACTER, payload:response.data});
                resolve();
            })
            .catch(err => {
                displayError(err, `Error retrieving character data for id ${id}`);
                reject();
            });
    });
};
export const editCharacter = (id, formValues) =>  async (dispatch) => {
    return new Promise((resolve, reject) => {
        gamesApi.patch(`/api/design/characters/${id}`, formValues)
            .then(response => {
                dispatch({type:EDIT_CHARACTER, payload:response.data});
                displaySuccess(`Character ${response.data.name} saved`);
                resolve();
                // history.push(`/games/${gameId}`);
            })
            .catch(err => {
                displayError(err, `Error saving character ${id}`);
                reject();
            });
    });
};
export const deleteCharacter = (id, redirectUrl) =>  async (dispatch) => {
    return new Promise((resolve, reject) => {
        gamesApi.delete(`/api/design/characters/${id}`)
            .then(response => {
                dispatch({type:DELETE_CHARACTER, payload:id});
                displaySuccess(`Character deleted`, `Character ${id} was deleted`);
                resolve();
                if (redirectUrl) { // ??????????????
                    history.push(redirectUrl);
                }
            })
            .catch(err => {
                displayError(err, `Error deleting character ${id}`);
                reject();
            });
    });
};
