import React from 'react';

import {change, Field, formValueSelector, reduxForm, submit} from 'redux-form';
import {MISSION_FORM_NAME as FORM_NAME} from "./formTypes";
import renderInput from "./renderers/renderInput";

import Row   from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import MissionAccomplishment from "./MissionAccomplishment";
import ImageChoose from "./ImageChoose";
import {connect} from "react-redux";
import {asyncValidateForm} from "../utils/validationUtils";
import Form from "react-bootstrap/Form";
import {useTranslation} from "react-i18next";

const Mission = ({error, dispatch, game, fieldNameParentMission, level}) => {
    const { t, i18n } = useTranslation();

    if (!level) {
        level = 0;
    }
    const fieldNamePrefix=fieldNameParentMission?fieldNameParentMission+'.':'';
    return (
        <Container fluid className={'p-'+(level===1?3:0)}>
                    {
                        level === 0 &&
                        <Row>
                            <Col lg={12}>
                                <Field name={`${fieldNamePrefix}name`} label={t('NAME_FIELD_LABEL')} tooltip={t('NAME_FIELD_TOOLTIP')} asCol={false} component={renderInput} />
                            </Col>
                        </Row>

                    }

                    <Row>
                        <Col>
                            <Field name="gameId" component="input" type="hidden" />
                            <Field name={`${fieldNamePrefix}title`} label="Title" asCol={false} component={renderInput} />
                            <Field name={`${fieldNamePrefix}description`} type="textarea" label="Description" component={renderInput} />
                        </Col>
                        {/*<Col>*/}
                        {/*    <Field name={`${fieldNamePrefix}mediaId`} label="Media" logoIdToShow={`${mediaId}`}*/}
                        {/*           handleRemoveMedia={()=>dispatch(change(FORM_NAME, fieldNamePrefix+'mediaId', null))}*/}
                        {/*           gameId={game.id}*/}
                        {/*           handleSetMediaId={(newId)=>{dispatch(change(FORM_NAME, fieldNamePrefix+'mediaId', newId))}}*/}
                        {/*           component={ImageChoose} />*/}
                        {/*</Col>*/}
                    </Row>


                    <MissionAccomplishment dispatch={dispatch} game={game}
                                           baseFieldName={`${fieldNamePrefix}accomplishment`}
                                           formName={FORM_NAME}
                                           level={level}
                    ></MissionAccomplishment>


        </Container>
    );

}


export default connect(
    (state, ownProps)=> {
        const selector = formValueSelector(FORM_NAME);
        const fieldNamePrefix=ownProps.fieldNameParentMission?ownProps.fieldNameParentMission+'.':'';

        return {
            //mediaId:selector(state, fieldNamePrefix+'mediaId')
        }
    })(Mission)


// export default connect(
//     (state, ownProps) => {
//         const selector = formValueSelector(FORM_NAME);
//         const fieldNamePrefix=ownProps.fieldNameParentMission?ownProps.fieldNameParentMission+'.':'';
//         return {
//             mediaId:selector(state, fieldNamePrefix+'mediaId'),
//         }
//     }
// )(reduxForm(
//     {
//         form: FORM_NAME,
//         validate:validate,
//         asyncValidate:asyncValidateForm,
//         asyncBlurFields: [ 'name' ]
//     }
// )(Mission));