import React from 'react';
import history from "../../history";
import CollapseItems from "../widgets/CollapseItems";
import {bconfirm} from "../utils/ConfirmAlert";
import {deleteItem} from "../../actions/itemsActions";
import {connect} from "react-redux";

const ItemList = ({items, game, displayAs, dispatch}) => {
    const newItemHandler = () => {
        history.push(`/games/${game.id}/items/create/0`);
    }
    const editItemHandler = (id) => {
        history.push(`/games/${game.id}/items/edit/${id}`);
    }
    // const deleteItemHandler = async (id) => {
    //     const result = await bconfirm('Are you really sure?', {cancelButtonStyle: 'secondary'});
    //     if (result) {
    //         dispatch(deleteItem(id))
    //             .then(() => {
    //                 /* do nothing */
    //             })
    //             .catch((e) => {
    //                 /* do nothing. A toastr notification should have been displayed */
    //             });
    //     }
    // }

    const itemList = [];
    for (const item of items) {
        if (item.isCurrency) {
            // do nothing. Do not show it in list
        }
        else {
            itemList.push({id:item.id,name:item.name});
//            itemList.push({id:item.id,name:item.name, specialNamePrefixMarkup:item.id === game.currency?<i className="fas fa-euro-sign"></i>:''});
        }

    }

    if (displayAs === 'collapse')
        return (
            <CollapseItems identifier="itemlist" heading="Items" items={itemList}
                           newItemHandler={newItemHandler}
                           newLabel="New"
                           editItemHandler={editItemHandler}
                           deleteItemHandler={null}
                           editLabel="Settings"
                           deleteLabel="Delete"
                           additionalClass="sideBar"
            />
        );
    return <>No displayAs</>
}

//export default ItemList;

// const mapStateToProps = (state, ownProps) => {
//     return {
//         game:state.games[ownProps.gameId],
//         scenes:state.scenes[ownProps.gameId],
//     };
// };
// export default connect(mapStateToProps,
//     (dispatch) => ({dispatch, fetchGame: fetchGame, }))(ItemList);


//export default connect(mapStateToProps,null)(GameDesign);
export default connect(null,null)(ItemList);