//import _ from 'lodash';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { editItem, createItem, deleteItem } from '../../actions/itemsActions';

import ItemForm from '../forms/ItemForm';
import history from '../../history';
import { bconfirm } from '../utils/ConfirmAlert';

const ItemEdit = ({ dispatch, item, game, onCancel, onSave, afterDelete }) => {
  const handleCancel = () => {
    if (onCancel) {
      onCancel();
      return;
    }
    history.push(`/games/${game.id}`); // if no specific handler is used, redirect to game design page
  };
  const handleDelete = async (id) => {
    const result = await bconfirm('Are you really sure?', { cancelButtonStyle: 'secondary' });
    if (result) {
      dispatch(deleteItem(id))
        .then(() => {
          if (afterDelete) {
            afterDelete();
            return;
          } else {
            history.push(`/games/${game.id}`); // if no specific handler is used, redirect to game design page
          }
        })
        .catch((e) => {
          /* do nothing. A toastr notification should have been displayed */
        });
    }
  };
  const onSubmit = (formValues) => {
    if (item) {
      dispatch(editItem(item.id, formValues))
        .then(() => {
          if (onSave) {
            onSave();
            return;
          } else {
            history.push(`/games/${game.id}`); // if no specific handler is used, redirect to game design page
          }
        })
        .catch((e) => {
          /* do nothing. An error toastr should have been displayed */
        });
    } else {
      dispatch(createItem(game.id, formValues))
        .then(() => {
          if (onSave) {
            onSave();
            return;
          } else {
            history.push(`/games/${game.id}`); // if no specific handler is used, redirect to game design page
          }
        })
        .catch((e) => {
          /* do nothing. An error toastr should have been displayed */
        });
    }
  };
  const defaultInitialValues = {
    limited: false,
    hidden: false,
    gameId: game.id,
  };

  return (
    <div className=''>
      <ItemForm enableReinitialize={true} handleCancel={handleCancel} handleDelete={handleDelete} game={game} initialValues={item ? item : defaultInitialValues} onSubmit={onSubmit} />
      {
        // initialValues={_.pick(game, 'title', 'description', 'lat', 'lng', 'radius')}
      }
    </div>
  );
};

export default connect(null, null)(ItemEdit);
