import gamesApi from "../apis/gamesApi";
import history from "../history";
import {
    FETCH_GAME_ARCHETYPES,
    FETCH_ARCHETYPE,
    EDIT_ARCHETYPE,
    CREATE_ARCHETYPE,
    DELETE_ARCHETYPE
} from "./types";
import {displayError, displaySuccess} from "../components/utils/notifications";


export const createArchetype = (gameId, formValues) =>  async (dispatch) => {
    return new Promise((resolve, reject) => {
        gamesApi.post(`/api/design/archetypes?gameId=${gameId}`,{...formValues, gameId})
            .then(response => {
                dispatch({type:CREATE_ARCHETYPE, payload:response.data});
                displaySuccess(`Object ${response.data.name} created`);
                resolve();
                //history.push(`/games/${gameId}`);
            })
            .catch(err => {
                displayError(err, `Error creating archetype`);
                reject();
            });
    });

};

export const fetchGameArchetypes = (gameId) => async (dispatch) => {
    if (gameId) {
        return new Promise((resolve, reject) => {
            gamesApi.get(`/api/design/archetypes?gameId=${gameId}`)
                .then(response => {
                    dispatch({type:FETCH_GAME_ARCHETYPES, payload:response.data});
                    resolve();
                })
                .catch(err => {
                    displayError(err, `Error retrieving game archetypes`);
                    reject();
                });
        });
    }
    else {
        return new Promise((resolve, reject) => {
            dispatch({type: FETCH_GAME_ARCHETYPES, payload: []});
            displayError(null, `No gameId id in fetchGameArchetypes`);
            reject();
        });
    }
};

export const fetchArchetype = (id) =>  async (dispatch) => {
    return new Promise((resolve, reject) => {
        gamesApi.get(`/api/design/archetypes/${id}`)
            .then(response => {
                dispatch({type:FETCH_ARCHETYPE, payload:response.data});
                resolve();
            })
            .catch(err => {
                displayError(err, `Error retrieving archetype data for id ${id}`);
                reject();
            });
    });
};
export const editArchetype = (id, formValues) =>  async (dispatch) => {
    return new Promise((resolve, reject) => {
        gamesApi.patch(`/api/design/archetypes/${id}`, formValues)
            .then(response => {
                dispatch({type:EDIT_ARCHETYPE, payload:response.data});
                displaySuccess(`Object ${response.data.name} saved`);
                resolve();
                // history.push(`/games/${gameId}`);
            })
            .catch(err => {
                displayError(err, `Error saving archetype ${id}`);
                reject();
            });
    });
};
export const deleteArchetype = (id, redirectUrl) =>  async (dispatch) => {
    return new Promise((resolve, reject) => {
        gamesApi.delete(`/api/design/archetypes/${id}`)
            .then(response => {
                dispatch({type:DELETE_ARCHETYPE, payload:id});
                displaySuccess(`Archetype deleted`, `Archetype ${id} was deleted`);
                resolve();
                if (redirectUrl) { // ??????????????
                    history.push(redirectUrl);
                }
            })
            .catch(err => {
                displayError(err, `Error deleting archetype ${id}`);
                reject();
            });
    });
};
