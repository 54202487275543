//import _ from 'lodash';
import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {editScene, createScene, deleteScene} from '../../actions/scenesActions';

import SceneForm from "../forms/SceneForm";
import history from "../../history";
import RuleForm from "../forms/RuleForm";
import {bconfirm} from "../utils/ConfirmAlert";

const SceneEdit = ({dispatch, scene, game, onCancel, onSave, afterDelete}) => {
    // useEffect(() => {
    //     if (sceneIdToEdit) {
    //         dispatch(fetchScene(sceneIdToEdit)); // we always re-retrieve this scene in order to have
    //     }
    // },[sceneIdToEdit,fetchScene, dispatch]);
    const handleCancel = () => {
        if (onCancel) {
            onCancel();
            return;
        }
        // if we were creating a new scene, return to game screen. Else return to scene view
        if (!scene) {
            history.push(`/games/${game.id}`); // if no specific handler is used, redirect to game design page
        }
        else {
            history.push(`/games/${game.id}/scenes/show/${scene.id}`)
        }

    };
    const handleDelete = async (id) => {
        const result = await bconfirm('Are you really sure?', {cancelButtonStyle: 'secondary'});
        if (result) {
            dispatch(deleteScene(id))
                .then(() => {
                    if (afterDelete) {
                        afterDelete();
                        return;
                    }
                    else {
                        history.push(`/games/${game.id}`); // if no specific handler is used, redirect to game design page
                    }
                })
                .catch((e) => {
                    /* do nothing. A toastr notification should have been displayed */
                });
        }
    }
    const onSubmit = (formValues) => {
        //console.log('submitting sceneedit ', formValues, sceneIdToEdit);
        if (scene) {
            dispatch(editScene(scene.id, formValues))
                .then(() => {
                    if (onSave) {
                        onSave();
                        return;
                    }
                    else {
                        history.push(`/games/${game.id}`); // if no specific handler is used, redirect to game design page
                    }
                })
                .catch((e) => {
                    /* do nothing. An error toastr should have been displayed */
                });
        }
        else {
            dispatch(createScene(game.id, formValues))
                .then(() => {
                    if (onSave) {
                        onSave();
                        return;
                    }
                    else {
                        history.push(`/games/${game.id}`); // if no specific handler is used, redirect to game design page
                    }
                })
                .catch((e) => {
                    /* do nothing. An error toastr should have been displayed */
                });
        }

    };

    // if (sceneIdToEdit && !scene) {
    //     return <div>Loading...</div>;
    // }

    const defaultInitialValues = {
        lat: game.lat,
        lng: game.lng,
        radius: 100,
        gameId: game.id,
        enabled: true
    };
    //if (scene && !scene.description) scene.description='';

    return (
        <div className="">

            <SceneForm
                enableReinitialize={true}
                handleCancel={handleCancel}
                handleDelete={handleDelete}

                initialValues={scene?scene:defaultInitialValues}
                onSubmit={onSubmit} />
                {
                    // initialValues={_.pick(game, 'title', 'description', 'lat', 'lng', 'radius')}
                }
        </div>
    );

}

const mapStateToProps = (state, ownProps) => {
    return {
        //scene:state.scenes[ownProps.sceneIdToEdit]
    };
};
export default connect(mapStateToProps,null)(SceneEdit);
