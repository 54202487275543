import _ from 'lodash';
import {
    DELETE_LOCATION,
    EDIT_LOCATION,
    FETCH_LOCATION,
    CREATE_LOCATION,
    FETCH_GAME_LOCATIONS
} from '../actions/types';

export default (state={},action) => {
    switch (action.type) {
        case DELETE_LOCATION:
            return _.omit(state, action.payload);
        case FETCH_GAME_LOCATIONS:
            // return only the data returned, and not the previous data
//            return {..._.mapKeys(action.payload, 'id')};
            return {...state, ..._.mapKeys(action.payload, 'id')};
        case FETCH_LOCATION:
            return {...state, [action.payload.id]:action.payload};
        case CREATE_LOCATION:
            return {...state, [action.payload.id]:action.payload};
        case EDIT_LOCATION:
            return {...state, [action.payload.id]:action.payload};
        default:
            return state;
    }
}