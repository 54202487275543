export const SET_ADMIN_STATS = 'SET_ADMIN_STATS';
export const SET_ROLE_REQUESTS = 'SET_ROLE_REQUESTS';
export const REMOVE_ROLE_REQUEST = 'REMOVE_ROLE_REQUEST';
export const SET_GAMES_FILTER = 'SET_GAMES_FILTER';

export const GET_ERRORS = 'GET_ERRORS';
export const USER_LOADING = 'USER_LOADING';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_USER_ACCOUNT = 'SET_USER_ACCOUNT';

export const DELETE_GAME = 'DELETE_GAME';
export const FETCH_MY_GAMES = 'FETCH_MY_GAMES';
export const GAME_LOADING = 'GAME_LOADING';
export const FETCH_GAME = 'FETCH_GAME';
export const FETCH_GAME_ANALYTICS = 'FETCH_GAME_ANALYTICS';
export const CREATE_GAME = 'CREATE_GAME';
export const EDIT_GAME = 'EDIT_GAME';

export const FETCH_GAME_SCENES = 'FETCH_GAME_SCENES';
export const FETCH_SCENE = 'FETCH_SCENE';
export const EDIT_SCENE = 'EDIT_SCENE';
export const CREATE_SCENE = 'CREATE_SCENE';
export const DELETE_SCENE = 'DELETE_SCENE';

export const DELETE_MEDIA = 'DELETE_MEDIA';
export const FETCH_GAME_MEDIAS = 'FETCH_GAME_MEDIAS';
export const FETCH_MEDIA = 'FETCH_MEDIA';
export const CREATE_MEDIA = 'CREATE_MEDIA';
export const EDIT_MEDIA = 'EDIT_MEDIA';

export const FETCH_GAME_MILESTONES = 'FETCH_GAME_MILESTONES';
export const FETCH_MILESTONE = 'FETCH_MILESTONE';
export const EDIT_MILESTONE = 'EDIT_MILESTONE';
export const CREATE_MILESTONE = 'CREATE_MILESTONE';
export const DELETE_MILESTONE = 'DELETE_MILESTONE';

export const FETCH_GAME_MISSIONS = 'FETCH_GAME_MISSIONS';
export const FETCH_MISSION = 'FETCH_MISSION';
export const EDIT_MISSION = 'EDIT_MISSION';
export const CREATE_MISSION = 'CREATE_MISSION';
export const DELETE_MISSION = 'DELETE_MISSION';

export const FETCH_GAME_GAMEVERSIONS = 'FETCH_GAME_GAMEVERSIONS';
export const FETCH_GAMEVERSION = 'FETCH_GAMEVERSION';
export const EDIT_GAMEVERSION = 'EDIT_GAMEVERSION';
export const CREATE_GAMEVERSION = 'CREATE_GAMEVERSION';
export const DELETE_GAMEVERSION = 'DELETE_GAMEVERSION';
export const ARCHIVE_GAMEVERSION = 'ARCHIVE_GAMEVERSION';

export const FETCH_GAME_INTERACTIONS = 'FETCH_GAME_INTERACTIONS';
export const FETCH_INTERACTION = 'FETCH_INTERACTION';
export const EDIT_INTERACTION = 'EDIT_INTERACTION';
export const CREATE_INTERACTION = 'CREATE_INTERACTION';
export const DELETE_INTERACTION = 'DELETE_INTERACTION';

export const FETCH_GAME_RULES = 'FETCH_GAME_RULES';
export const FETCH_RULE = 'FETCH_RULE';
export const EDIT_RULE = 'EDIT_RULE';
export const CREATE_RULE = 'CREATE_RULE';
export const DELETE_RULE = 'DELETE_RULE';

export const FETCH_GAME_LOCATIONS = 'FETCH_GAME_LOCATIONS';
export const FETCH_LOCATION = 'FETCH_LOCATION';
export const EDIT_LOCATION = 'EDIT_LOCATION';
export const CREATE_LOCATION = 'CREATE_LOCATION';
export const DELETE_LOCATION = 'DELETE_LOCATION';

export const FETCH_GAME_ARCHETYPES = 'FETCH_GAME_ARCHETYPES';
export const FETCH_ARCHETYPE = 'FETCH_ARCHETYPE';
export const EDIT_ARCHETYPE = 'EDIT_ARCHETYPE';
export const CREATE_ARCHETYPE = 'CREATE_ARCHETYPE';
export const DELETE_ARCHETYPE = 'DELETE_ARCHETYPE';

export const FETCH_GAME_OFFERS = 'FETCH_GAME_OFFERS';
export const FETCH_OFFER = 'FETCH_OFFER';
export const EDIT_OFFER = 'EDIT_OFFER';
export const CREATE_OFFER = 'CREATE_OFFER';
export const DELETE_OFFER = 'DELETE_OFFER';

export const FETCH_GAME_CHARACTERS = 'FETCH_GAME_CHARACTERS';
export const FETCH_CHARACTER = 'FETCH_CHARACTER';
export const EDIT_CHARACTER = 'EDIT_CHARACTER';
export const CREATE_CHARACTER = 'CREATE_CHARACTER';
export const DELETE_CHARACTER = 'DELETE_CHARACTER';

export const FETCH_GAME_ITEMS = 'FETCH_GAME_ITEMS';
export const FETCH_ITEM = 'FETCH_ITEM';
export const EDIT_ITEM = 'EDIT_ITEM';
export const CREATE_ITEM = 'CREATE_ITEM';
export const DELETE_ITEM = 'DELETE_ITEM';

export const FETCH_GAME_ATTRIBUTES = 'FETCH_GAME_ATTRIBUTES';
export const FETCH_ATTRIBUTE = 'FETCH_ATTRIBUTE';
export const EDIT_ATTRIBUTE = 'EDIT_ATTRIBUTE';
export const CREATE_ATTRIBUTE = 'CREATE_ATTRIBUTE';
export const DELETE_ATTRIBUTE = 'DELETE_ATTRIBUTE';

export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const TOGGLE_SIDEBAR_MENU = 'TOGGLE_SIDEBAR_MENU';

export const FETCH_GAME_INITIAL_STATE = 'FETCH_GAME_INITIAL_STATE';
export const DELETE_GAME_INITIAL_STATE = 'DELETE_GAME_INITIAL_STATE';
export const FETCH_GAME_INITIAL_STATE_SPEC = 'FETCH_GAME_INITIAL_STATE_SPEC';

export const FETCH_ACTIVE_INSTANCES = 'FETCH_ACTIVE_INSTANCES';
export const FETCH_INSTANCE = 'FETCH_INSTANCE';
