import _ from 'lodash';
import {
    DELETE_MILESTONE,
    EDIT_MILESTONE,
    FETCH_MILESTONE,
    CREATE_MILESTONE,
    FETCH_GAME_MILESTONES
} from '../actions/types';

export default (state={},action) => {
    switch (action.type) {
        case DELETE_MILESTONE:
            return _.omit(state, action.payload);
        case FETCH_GAME_MILESTONES:
            // return only the data returned, and not the previous data
//            return {..._.mapKeys(action.payload, 'id')};
            return {...state, ..._.mapKeys(action.payload, 'id')};
        case FETCH_MILESTONE:
            return {...state, [action.payload.id]:action.payload};
        case CREATE_MILESTONE:
            return {...state, [action.payload.id]:action.payload};
        case EDIT_MILESTONE:
            return {...state, [action.payload.id]:action.payload};
        default:
            return state;
    }
}