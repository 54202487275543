import _ from 'lodash';
import {
    DELETE_MEDIA,
    FETCH_GAME_MEDIAS,
    FETCH_MEDIA,
    CREATE_MEDIA,
    EDIT_MEDIA,
} from '../actions/types';

export default (state={},action) => {
    switch (action.type) {
        case DELETE_MEDIA:
            return _.omit(state, action.payload);
        case FETCH_GAME_MEDIAS:
            // return only the data returned, and not the previous data
            //return {...state, ..._.mapKeys(action.payload, 'id')};
            return {..._.mapKeys(action.payload, 'id')};
        case FETCH_MEDIA:
            return {...state, [action.payload.id]:action.payload};
        case CREATE_MEDIA:
            // // create media returns array of media objects, and not single object
            // let newState = {...state};
            // for (let count=0; count<action.payload.length; count++) {
            //     newState = {...newState,[action.payload[count].id]: action.payload[count]};
            // }
            // return newState;
            return {...state, ..._.mapKeys(action.payload, 'id')};
        case EDIT_MEDIA:
            return {...state, [action.payload.id]:action.payload};
        default:
            return state;
    }
}