//import _ from 'lodash';
import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {editInteraction, createInteraction, deleteInteraction} from '../../actions/interactionsActions';

import InteractionForm from "../forms/InteractionForm";
import history from "../../history";
import GameVersionForm from "../forms/GameVersionForm";
import {bconfirm} from "../utils/ConfirmAlert";

const InteractionEdit = ({dispatch, interaction, game, onCancel, onSave, afterDelete, newInteractionType, characters}) => {
    const handleCancel = () => {
        if (onCancel) {
            onCancel();
            return;
        }
        history.push(`/games/${game.id}`); // if no specific handler is used, redirect to game design page
    };
    const handleDelete = async (id) => {
        const result = await bconfirm('Are you really sure?', {cancelButtonStyle: 'secondary'});
        if (result) {
            dispatch(deleteInteraction(id))
                .then(() => {
                    if (afterDelete) {
                        afterDelete();
                        return;
                    }
                    else {
                        history.push(`/games/${game.id}`); // if no specific handler is used, redirect to game design page
                    }
                })
                .catch((e) => {
                    /* do nothing. A toastr notification should have been displayed */
                });
        }
    }
    const onSubmit = (formValues) => {
        if (interaction) {
            dispatch(editInteraction(interaction.id, formValues))
                .then((value) => {
                    if (onSave) {
                        onSave(value);
                        return;
                    }
                    else {
                        history.push(`/games/${game.id}`); // if no specific handler is used, redirect to game design page
                    }
                })
                .catch((e) => {
                    /* do nothing. An error toastr should have been displayed */
                });
        }
        else {
            dispatch(createInteraction(game.id, formValues))
                .then((value) => {
                    if (onSave) {
                        onSave(value);
                        return;
                    }
                    else {
                        history.push(`/games/${game.id}`); // if no specific handler is used, redirect to game design page
                    }
                })
                .catch((e) => {
                    /* do nothing. An error toastr should have been displayed */
                });
        }

    };
    const defaultInitialValues = {
        gameId:game.id,
        buttonLabel: 'Ok'
    };

    if (!interaction) { // when creating a new interaction, allow to specify the initial type
        defaultInitialValues.type = newInteractionType;
    }
    return (
        <div className="">

            <InteractionForm
                enableReinitialize={true}
                handleCancel={handleCancel}
                handleDelete={handleDelete}
                game={game}
                initialValues={interaction?interaction:defaultInitialValues}
                characters={characters}
                onSubmit={onSubmit} />
            {
                // initialValues={_.pick(game, 'title', 'description', 'lat', 'lng', 'radius')}
            }
        </div>
    );

}

export default connect(null,null)(InteractionEdit);
