export const GAME_FORM_NAME = 'GAME_FORM';
export const MEDIA_FORM_NAME = 'MEDIA_FORM';
export const SCENE_FORM_NAME = 'SCENE_FORM';
export const GAME_PERIOD_FORM = 'GAME_PERIOD_FORM';
export const ATTRIBUTE_FORM_NAME = 'ATTRIBUTE_FORM';
export const ITEM_FORM_NAME = 'ITEM_FORM';
export const CHARACTER_FORM_NAME = 'CHARACTER_FORM';
export const ARCHETYPE_FORM_NAME = 'ARCHETYPE_FORM';
export const OFFER_FORM_NAME = 'OFFERFORM';
export const MILESTONE_FORM_NAME = 'MILESTONE_FORM';
export const MISSION_FORM_NAME = 'MISSION_FORM';
export const GAMEVERSION_FORM_NAME = 'GAMEVERSION_FORM';
export const INTERACTION_FORM_NAME = 'INTERACTION_FORM';
export const RULE_FORM_NAME = 'RULE_FORM';
export const LOCATION_FORM_NAME = 'LOCATION_FORM';
export const INITIAL_STATE_ITEM_FORM_NAME = 'INITIAL_STATE_ITEM_FORM';



//export const CONDITION_FORM_NAME = 'CONDITION_FORM';
