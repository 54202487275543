import authapi from "../apis/authapi";
import gamesApi from "../apis/gamesApi";

const setAuthToken = token => {
    //console.log("setAuthToken SETTING TOKEN TO "+token);
    if (token) {
        // Apply authorization token to every request if logged in
        //axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        authapi.defaults.headers.common["Authorization"] = `${token}`; // token should already include a "Bearer " prefix
        gamesApi.defaults.headers.common["Authorization"] = `${token}`; // token should already include a "Bearer " prefix
    } else {
        // Delete auth header
        //delete axios.defaults.headers.common["Authorization"];
        delete authapi.defaults.headers.common["Authorization"];
        delete gamesApi.defaults.headers.common["Authorization"];
    }
};
export default setAuthToken;