import React from 'react';

import { change, Field, formValueSelector, reduxForm } from 'redux-form';
import { connect, useSelector } from 'react-redux';
import GoogleMapLocationForm from '../gmap/GoogleMapLocationForm';
import { GAME_FORM_NAME as FORM_NAME } from './formTypes';
import renderInput from './renderers/renderInput';
import ImageChoose from './ImageChoose';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import renderCheckbox from './renderers/renderCheckbox';
import renderSelect from './renderers/renderSelect';
import FormButtons from './FormButtons';
import PlayingPeriod2 from './PlayingPeriod2';
import { g4mFormTotalFormValidate, g4mFormValidate } from '../utils/validationUtils';
import RichTextEditorWYSIWYG from './renderers/RichTextEditorWYSIWYG';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

const selector = formValueSelector(FORM_NAME);

const GameForm = ({
  error,
  dispatch,
  handleSubmit,
  onSubmit,
  handleCancel,
  initialValues,
  logoId,
  currencyIcon,
  market,
  rankingsAvailable,
  multiplayer,
  endPoliciesPickOffers,
  userage,
  handleDelete,
}) => {
  // let allItems = useSelector((state)=>state.items);
  // let gameItems = Object.values(allItems).filter(function (el) {return el.gameId === initialValues.id;});

  const onMarkerDrag = (lat, lng) => {
    dispatch(change(FORM_NAME, 'lat', lat));
    dispatch(change(FORM_NAME, 'lng', lng));
  };
  const myOnSubmit = (formValues) => {
    onSubmit(formValues);
  };

  return (
    <>
      <Form noValidate onSubmit={handleSubmit(myOnSubmit)}>
        <Tabs defaultActiveKey='general' id='gametabs2'>
          <Tab eventKey='general' title='General'>
            <fieldset className='border p-2 mb-3 mt-1'>
              <Form.Row>
                <Col lg={8}>
                  <Field name='title' label='Game title' asCol={true} component={renderInput} />
                  <Field name={'language'} noFormGroup={false} asCol={true} component={renderSelect} label={'Language'}>
                    <option key='empty' value={''}>
                      -- select --
                    </option>
                    {[
                      { value: 'el-Gr', name: 'Greek' },
                      { value: 'en-Us', name: 'English' },
                    ].map((option, index) => (
                      <option key={option.value} value={option.value}>
                        {option.name}
                      </option>
                    ))}
                  </Field>
                  <Row>
                    <Col>
                      <Field
                        asCol={true}
                        component={renderCheckbox}
                        onChange={(e) => {
                          if (!e.target.checked) {
                            dispatch(change(FORM_NAME, 'endPolicies.tradeAssets', false));
                          }
                        }}
                        name='multiplayer'
                        type='checkbox'
                        label='Multiplayer game'
                      />
                    </Col>
                    <Col>
                      <Field
                        asCol={false}
                        component={renderCheckbox}
                        name={'market'}
                        type='checkbox'
                        tooltip='If market is available, players can bye and sell stuff using a currency'
                        label='Market available'
                      />
                    </Col>
                  </Row>
                </Col>
                <Col>
                  {initialValues.id && (
                    <Field
                      name='logoId'
                      label='Logo'
                      logoIdToShow={logoId}
                      handleRemoveMedia={() => dispatch(change(FORM_NAME, 'logoId', null))}
                      gameId={initialValues && initialValues.id}
                      imageWidth={300}
                      handleSetMediaId={(newId) => dispatch(change(FORM_NAME, 'logoId', newId))}
                      component={ImageChoose}
                    />
                  )}
                  {!initialValues.id && <p>You will be able to upload and set a logo for the game after you first save it</p>}
                </Col>
                <Col>
                  {market && (
                    <Col>
                      <Field
                        name='currencyIcon'
                        label='Currency Icon'
                        logoIdToShow={currencyIcon}
                        handleRemoveMedia={() => dispatch(change(FORM_NAME, 'currencyIcon', null))}
                        gameId={initialValues && initialValues.id}
                        imageWidth={300}
                        handleSetMediaId={(newId) => dispatch(change(FORM_NAME, 'currencyIcon', newId))}
                        component={ImageChoose}
                      />

                      <div className={'mt-2'}>
                        <Field name='currencyName' label='Currency name' asCol={false} component={renderInput} />
                      </div>
                    </Col>
                  )}
                </Col>
              </Form.Row>
            </fieldset>

            <fieldset className='border p-2 mb-3'>
              <legend className='w-auto legend-small'>Player age</legend>
              <Form.Row>
                <Col>
                  <Field asCol={true} name='minAge' label='Suggested min age' type='number' step='1' component={renderInput} />
                </Col>
                <Col>
                  <Field asCol={true} name='maxAge' label='Suggested max age' type='number' step='1' component={renderInput} />
                </Col>
                <Col>
                  <Field placementFix asCol={true} component={renderCheckbox} name={'adultsOnly'} type='checkbox' label='Adults only' />
                </Col>
              </Form.Row>
            </fieldset>
            <fieldset className='border p-2 mb-3'>
              <legend className='w-auto legend-small'>Player avatar</legend>
              <Form.Row>
                <Col>
                  <Field asCol={true} component={renderCheckbox} name='photoRequired' type='checkbox' label='Player photo required' />
                </Col>
                <Col>
                  <Field asCol={true} component={renderCheckbox} name={'sexRequired'} type='checkbox' label='Player sex required' />
                </Col>
              </Form.Row>
            </fieldset>
          </Tab>
          <Tab eventKey='description' title='Description'>
            <Field label='Description' name='description' hideLabel={true} component={RichTextEditorWYSIWYG} />
          </Tab>
          <Tab eventKey='playground' title='Playground'>
            <fieldset className='border p-2 mb-3 mt-1'>
              <legend className='w-auto legend-small'>Map properties</legend>
              <Form.Row>
                <Field name={'pgCfg.mapType'} noFormGroup={false} asCol={true} component={renderSelect} label={'Type of map'}>
                  {[
                    { value: 'hybrid', name: 'Hybrid' },
                    { value: 'standard', name: 'Standard' },
                  ].map((option, index) => (
                    <option key={option.value} value={option.value}>
                      {option.name}
                    </option>
                  ))}
                </Field>
                <Field asCol={true} placementFix component={renderCheckbox} name={'pgCfg.simulate'} type='checkbox' label='Simulate Move on Map' />
                <Field asCol={true} placementFix component={renderCheckbox} name={'pgCfg.showScenes'} type='checkbox' label='Show Scenes On Map' />
              </Form.Row>
            </fieldset>

            <fieldset className='border p-2 mb-3'>
              <legend className='w-auto legend-small'>Game area</legend>
              <Form.Row>
                <Col lg={4}>
                  <Field name='playgroundtitle' label='Title of game area' asCol={false} component={renderInput} />
                </Col>
                <Field asCol={true} name='lat' normalize={(value, previousValue, allValues) => (value ? parseFloat(value) : 0)} label='Latitude' type='number' step='0.001' component={renderInput} />
                <Field asCol={true} name='lng' normalize={(value, previousValue, allValues) => (value ? parseFloat(value) : 0)} label='Longitude' type='number' step='0.001' component={renderInput} />
                <Field asCol={true} name='radius' label='Area radius (m)' tooltip="This set's the general area the game is played in" type='number' step='10' component={renderInput} />
              </Form.Row>
              <GoogleMapLocationForm formName={FORM_NAME} lat={initialValues.lat} radius={initialValues.radius} lng={initialValues.lng} onMarkerDrag={onMarkerDrag} style={{ height: '700px' }} />
            </fieldset>
          </Tab>
          <Tab eventKey='policies' title='Policies'>
            <fieldset className='border p-2 mb-3'>
              <legend className='w-auto legend-small'>Player capabilities</legend>
              <Form.Row>
                <Col>
                  <fieldset className='border p-2 mb-3'>
                    <legend className='w-auto legend-small'>While playing</legend>
                    <Field asCol={true} component={renderCheckbox} name={'capabilities.seeBackPack'} type='checkbox' label='See their backpack' />
                    <Field asCol={true} component={renderCheckbox} name={'capabilities.seeSkills'} type='checkbox' label='See their skills' />
                    <Field asCol={true} component={renderCheckbox} name={'capabilities.scanCodes'} type='checkbox' label='Scan codes' />
                    <Field asCol={true} component={renderCheckbox} name={'capabilities.typeCodes'} type='checkbox' label='Type codes' />
                    <Field asCol={true} component={renderCheckbox} name={'capabilities.seeDirections'} type='checkbox' label='See instructions' />
                    <Field asCol={true} component={renderCheckbox} name={'capabilities.seeTasks'} type='checkbox' label='See tasks' />
                  </fieldset>
                </Col>
                <Col>
                  {market && (
                    <fieldset className='border p-2 mb-3'>
                      <legend className='w-auto legend-small'>After game ends</legend>
                      <Field asCol={true} component={renderCheckbox} name={'endPolicies.redeemAssets'} type='checkbox' label='Allow to Redeem Assets' />
                      <Field
                        asCol={true}
                        component={renderCheckbox}
                        onChange={(e) => {
                          if (e.target.checked) {
                            dispatch(change(FORM_NAME, 'endPolicies.redeemOffers', true));
                          }
                        }}
                        name={'endPolicies.pickOffers'}
                        type='checkbox'
                        label='Allow to Pick Offers'
                      />
                      <Field asCol={true} disabled={endPoliciesPickOffers} component={renderCheckbox} name={'endPolicies.redeemOffers'} type='checkbox' label='Allow to Redeem Offers' />
                      <Field asCol={true} disabled={!multiplayer} component={renderCheckbox} name={'endPolicies.tradeAssets'} type='checkbox' label='Allow to Trade Assets' />
                    </fieldset>
                  )}
                </Col>
              </Form.Row>
            </fieldset>
            <fieldset className='border p-2 mb-3'>
              <legend className='w-auto legend-small'>Player ranking</legend>
              <Form.Row>
                <Field asCol={true} component={renderCheckbox} name={'rankings.available'} type='checkbox' label='Leaderboard is available' />
                {rankingsAvailable && <Field asCol={true} component={renderCheckbox} name={'rankings.showBeforeComplete'} type='checkbox' label='Show before game end' />}
              </Form.Row>
            </fieldset>
          </Tab>
          <Tab eventKey='terms' title='Terms'>
            <Field
              label='Terms'
              name='terms'
              hideLabel={true}
              component={RichTextEditorWYSIWYG}
              value={initialValues.terms !== null && typeof initialValues.terms !== 'undefined' ? initialValues.terms : ''}
            />{' '}
          </Tab>
          <Tab eventKey='instructions' title='Instructions'>
            <Field
              label='Instructions'
              name='instructions'
              hideLabel={true}
              component={RichTextEditorWYSIWYG}
              value={initialValues.instructions !== null && typeof initialValues.instructions !== 'undefined' ? initialValues.instructions : ''}
            />
          </Tab>
          <Tab eventKey='access' title='Access'>
            <fieldset className='border p-2 mb-3 mt-1'>
              <legend className='w-auto legend-small'>Access restrictions</legend>
              <Form.Row>
                <Col lg={3} sm={6}>
                  <Field name='additionalDesignersEmailsText' type='textarea' label='Co-Designers' tooltip='One email address of the account per line' component={renderInput} />
                </Col>
                <Col lg={3} sm={6}>
                  <Field
                    name='additionalDesignerOnlyAccessEmailsText'
                    type='textarea'
                    label='Authorized Testers (working version)'
                    tooltip='One email address of the account per line'
                    component={renderInput}
                  />
                </Col>
                <Col lg={3} sm={6}>
                  <Field
                    name='restrictAccessToEmailsText'
                    type='textarea'
                    label='Authorised Players (public version)'
                    tooltip='One email address of the account per line. Only these players are allowed to play the game. Leave empty for everyone to have access.'
                    component={renderInput}
                  />
                </Col>
                <Col lg={3} sm={6}>
                  <Field name={'publicAccess'} noFormGroup={false} asCol={true} tooltip='Allow other users to View and/or Clone this game' component={renderSelect} label={'Public Access'}>
                    <option key='empty' value={''}>
                      -- select --
                    </option>
                    {[
                      { value: 'no', name: 'Disabled' },
                      { value: 'view', name: 'View only' },
                      { value: 'clone', name: 'View & Clone' },
                    ].map((option, index) => (
                      <option key={option.value} value={option.value}>
                        {option.name}
                      </option>
                    ))}
                  </Field>
                </Col>
              </Form.Row>
              <Form.Row></Form.Row>
            </fieldset>
            <fieldset className='border p-2 mb-3'>
              <legend className='w-auto legend-small'>Device Restrictions</legend>
              <Form.Row>
                <Field asCol={true} component={renderCheckbox} name={'enforceSingleDevice'} type='checkbox' label='Players cannot change device' />
              </Form.Row>
            </fieldset>
            <fieldset className='border p-2 mb-3'>
              <legend className='w-auto legend-small'>Time restrictions</legend>
              <Field name='playingPeriod' reduxFieldName={'playingPeriod'} component={PlayingPeriod2} formName={FORM_NAME} />
            </fieldset>
          </Tab>
          <Tab eventKey='advanced' title='Advanced' className={'pt-2'}>
            <Field
              name='addonSpec'
              label={'Additional game spec'}
              type='textarea'
              defaultValue={'{}'}
              tooltip={'Additional keys and values for the game spec. Must be a string evaluating to a valid json object'}
              component={renderInput}
            />
          </Tab>
        </Tabs>

        <Container fluid>
          {/*<PlayingPeriod reduxFieldName="playingPeriod" formName={FORM_NAME}></PlayingPeriod>*/}

          <Form.Row>
            <Col>
              <FormButtons validationGeneralError={error} handleCancel={handleCancel} objectId={initialValues.id} handleDelete={initialValues.id ? handleDelete : null} />
            </Col>
          </Form.Row>
        </Container>
      </Form>
    </>
  );
};

const validate = (formValues) => {
  const errors = {};
  const validationFields = ['title', 'description:wysiwyg', 'playgroundtitle', 'radius', 'minAge', 'maxAge', 'adultsOnly', 'language'];
  if (formValues.id) {
    validationFields.push('logoId');
  }
  g4mFormValidate(errors, formValues, validationFields);
  g4mFormTotalFormValidate(errors);

  if (formValues.market) {
    if (!formValues.currencyName || formValues.currencyName === '') {
      errors.currencyName = 'required';
    }
    if (!formValues.currencyIcon || formValues.currencyIcon === '') {
      errors.currencyIcon = 'required';
    }
  }
  console.log('addOnSpec: ' + formValues.addonSpec);
  if (formValues.addonSpec && formValues.addonSpec !== '') {
    try {
      JSON.parse(formValues.addonSpec);
    } catch (e) {
      errors.addonSpec = 'Invalid JSON object';
    }
  }

  if (!formValues.playingPeriod || !formValues.playingPeriod.start || !formValues.playingPeriod.timezone) {
    errors.playingPeriod = 'Please specify at least a start date and time with timezone';
  } else {
    if (formValues.playingPeriod.end && formValues.playingPeriod.start > formValues.playingPeriod.end) {
      errors.playingPeriod = 'End time must be after the start time';
    }
  }
  return errors;
};

// export default reduxForm({
//     form: FORM_NAME,
//     validate:validate,
//  })(GameForm);

export default connect((state, ownProps) => {
  return {
    logoId: selector(state, 'logoId'),
    currencyIcon: selector(state, 'currencyIcon'),
    market: selector(state, 'market'),
    multiplayer: selector(state, 'multiplayer'),
    endPoliciesPickOffers: selector(state, 'endPolicies.pickOffers'),
    rankingsAvailable: selector(state, 'rankings.available'),
    userage: selector(state, 'userage'),
  };
})(
  reduxForm({
    form: FORM_NAME,
    validate: validate,
  })(GameForm)
);
