import gamesApi from '../apis/gamesApi';
import history from '../history';
import { FETCH_GAME_ITEMS, FETCH_ITEM, EDIT_ITEM, CREATE_ITEM, DELETE_ITEM, SET_ROLE_REQUESTS, REMOVE_ROLE_REQUEST, SET_ADMIN_STATS } from './types';
import { displayError, displaySuccess } from '../components/utils/notifications';

export const fetchDashboardStats = () => async (dispatch) => {
  return new Promise((resolve, reject) => {
    gamesApi
      .get(`/api/admin/dashboard-stats`)
      .then((response) => {
        dispatch({ type: SET_ADMIN_STATS, payload: response.data });
        resolve();
      })
      .catch((err) => {
        displayError(err, `Error retrieving dashboard stats`);
        reject();
      });
  });
};

export const fetchRoleRequests = () => async (dispatch) => {
  return new Promise((resolve, reject) => {
    gamesApi
      .get(`/api/admin/role-requests`)
      .then((response) => {
        dispatch({ type: SET_ROLE_REQUESTS, payload: response.data });
        resolve();
      })
      .catch((err) => {
        displayError(err, `Error retrieving role requests`);
        reject();
      });
  });
};

export const respondRoRoleRequest = (email, action, reason) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    gamesApi
      .patch(`/api/admin/role-requests`, { email, action, reason })
      .then((response) => {
        dispatch({ type: REMOVE_ROLE_REQUEST, payload: { email } });
        displaySuccess(`Request ${action}ed`);
        resolve();
      })
      .catch((err) => {
        displayError(err, `Error updating request`);
        reject();
      });
  });
};
