import {useTranslation} from "react-i18next";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Field, formValueSelector} from "redux-form";
import renderCheckbox from "./renderers/renderCheckbox";
import Form from "react-bootstrap/Form";
import renderInput from "./renderers/renderInput";
import renderSelect from "./renderers/renderSelect";
import {connect} from "react-redux";
import React from "react";

let LimitAction = ({ baseFieldName, enableLimitLabel, itemsLabel, enabled }) => {
    const { t } = useTranslation();
    return (
        <Row>
            <Col lg={'auto'}>
                <Field placementFixInline component={renderCheckbox} name={baseFieldName+'.enabled'} type="checkbox" label={enableLimitLabel} />
            </Col>
            {
                enabled && (
                    <>
                        <Col>
                            <Row>
                                <Col lg={'auto'}>
                                    <Form.Label className={'placement-fix-inline'}>
                                        Max
                                    </Form.Label>
                                </Col>
                                <Col lg={3}>
                                    <Field component={renderInput} type="number" step="1" hideLabel={true} name={baseFieldName+'.maxItems'} label={'Max '+itemsLabel} />
                                </Col>
                                <Col lg={'auto'}>
                                    <Field name={baseFieldName+'.timeUnit'} component={renderSelect} hideLabel={true} label={'Time restriction'}>
                                        <option key={'empty'} value={''}>-- select one --</option>
                                        <option key={'minute'} value={'minute'}>{t('per minute')}</option>
                                        <option key={'hour'} value={'hour'}>{t('per hour')}</option>
                                        <option key={'day'} value={'day'}>{t('per day')}</option>
                                        <option key={'total'} value={'total'}>{t('in total')}</option>
                                    </Field>
                                </Col>
                            </Row>
                        </Col>
                    </>
                )
            }
        </Row>
    );
}
LimitAction = connect(
    (state, ownProps) => {
        let fieldName = ownProps.baseFieldName;
        const selector = formValueSelector(ownProps.formName);
        const enabled = selector(state, `${fieldName}.enabled`);
        return {
            enabled
        }
    }
)(LimitAction);

export default LimitAction;
