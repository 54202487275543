import gamesApi from "../apis/gamesApi";

import {displayError, displaySuccess} from "../components/utils/notifications";
import {
    DELETE_MEDIA,
    FETCH_GAME_MEDIAS,
    FETCH_MEDIA,
    CREATE_MEDIA,
    EDIT_MEDIA,
} from "./types";

export const deleteMedia = (id) =>  async (dispatch) => {
    return new Promise((resolve, reject) => {
        gamesApi.delete(`/api/design/medias/${id}`)
            .then(response => {
                dispatch({type:DELETE_MEDIA, payload:id});
                displaySuccess(`Media was deleted`);
                resolve();
            })
            .catch(err => {
                displayError(err, `Error deleting media ${id}`);
                reject();
            });
    });
};

export const fetchMedia = (id) =>  async (dispatch) => {
    return new Promise((resolve, reject) => {
        gamesApi.get(`/api/design/games/${id}`)
            .then(response => {
                dispatch({type:FETCH_MEDIA, payload:response.data});
                resolve();
            })
            .catch(err => {
                displayError(err, `Error retrieving media data for id ${id}`);
                reject();
            });
    });
};

export const fetchGameMedias = (gameId) =>  async (dispatch, getState) => {
//    const userId = getState().auth.user.id;
    if (gameId) {
        return new Promise((resolve, reject) => {
            gamesApi.get(`/api/design/medias?gameId=${gameId}`)
                .then(response => {
                    dispatch({type:FETCH_GAME_MEDIAS, payload:response.data});
                    resolve();
                })
                .catch(err => {
                    displayError(err, `Error retrieving game medias for game ${gameId}`);
                    reject();
                });
        });
    }
};
export const editMedia = (id, formValues) =>  async (dispatch) => {
    return new Promise((resolve, reject) => {
        gamesApi.patch(`/api/design/medias/${id}`, formValues)
            .then(response => {
                dispatch({type:EDIT_MEDIA, payload:response.data});
                displaySuccess(`Media saved`);
                resolve();
            })
            .catch(err => {
                displayError(err, `Error saving media ${id}`);
                reject();
            });
    });
};

export const createMedia = (formValues, gameId) =>  async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        gamesApi.post(`/api/design/medias?gameId=${gameId}`,formValues)
            .then(response => {
                dispatch({type:CREATE_MEDIA, payload:response.data});
                displaySuccess(`Media uploaded`);
                resolve();
            })
            .catch(err => {
                displayError(err, `Error uploading media`);
                reject();
            });
    });

};