import React from 'react';
import history from "../../history";
import CollapseItems from "../widgets/CollapseItems";
import {bconfirm} from "../utils/ConfirmAlert";
import {deleteGameVersion} from "../../actions/gameVersionsActions";
import {connect} from "react-redux";
import {getDatePart} from "../../utils/utils";

const GameVersionList = ({gameVersions, game, displayAs, dispatch}) => {
    const newItemHandler = () => {
        history.push(`/games/${game.id}/gameVersions/create/0`);
    }
    const editItemHandler = (id) => {

        history.push(`/games/${game.id}/gameVersions/edit/${id}`);
    }


    const itemList = [];
    for (const item of gameVersions) {
        itemList.push({id:item.id,name:'v'+item.version + ' ' + getDatePart(item.createdAt), title:item.createdAt});
    }

    if (displayAs === 'collapse')
        return (
            <CollapseItems identifier="gameVersionlist" heading="GameVersions" items={itemList}
               newItemHandler={newItemHandler}
               newLabel="New"
               editItemHandler={editItemHandler}
               deleteItemHandler={null}
               editLabel="Settings"
               deleteLabel="Delete"
               additionalClass="sideBar"
            />
        );
    return <>No displayAs</>
}

export default connect(null,null)(GameVersionList);
