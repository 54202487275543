export const USER_ROLE = {
  ADMIN: 'admin',
  DESIGNER: 'designer',
  PLAYER: 'player',
};

export const GAME_RIGHTS = {
  ADMIN: ['edit', 'view', 'clone', 'manage'],
  DESIGNER: ['edit', 'view', 'clone', 'manage'],
  CLONER: ['view', 'clone'],
  VIEWER: ['view'],
};
