import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { INTER_ANSWER_QUESTION, INTER_INSPECT_INFO, INTER_QUIZ, INTER_CONFIRM, INTER_CONVERSATION } from '../interactions/interactionTypes';
import { change, Field, formValueSelector } from 'redux-form';
import { CONDITION_TYPE_SELECT_OPTIONS, CONDITION_TYPE_VERBS } from './conditionConstants';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import renderSelect from './renderers/renderSelect';
import renderInput from './renderers/renderInput';
import Button from 'react-bootstrap/Button';
import React, { useState } from 'react';
import { RULE_FORM_NAME as FORM_NAME } from './formTypes';
import ModalEdit from '../utils/ModalEdit';
import MilestoneEdit from '../milestones/MilestoneEdit';
import LocationEdit from '../locations/LocationEdit';
import { getUserRightsOnGame, shortenText, stringIsNotEmpty } from '../../utils/utils';
import { bconfirm } from '../utils/ConfirmAlert';
import QrCodeForm from '../qr-codes/QrCodeForm';
import { isEmpty } from 'lodash';
import { Form } from 'react-bootstrap';

let Condition = ({ dispatch, fields, index, condition, operator, scene, game, conditionType, triggerCode, elementId, formName, isTriggerCondition }) => {
  const { t, i18n } = useTranslation();
  const conditionTypeVerbsKeyPostfix = isTriggerCondition ? '_TRIGGER' : '';
  const user = useSelector((state) => state.auth.user);
  const viewer = !getUserRightsOnGame(game, user).includes('edit');
  const renderOptions = (options) => {
    return options.map((option) => {
      if (
        CONDITION_TYPE_VERBS[option.value + conditionTypeVerbsKeyPostfix] &&
        CONDITION_TYPE_VERBS[option.value + conditionTypeVerbsKeyPostfix].length &&
        ((game.market && option.value.includes('CURRENCY')) || !option.value.includes('CURRENCY'))
      ) {
        return (
          <option key={option.value} value={option.value}>
            {t(option.title)}
          </option>
        );
      } else {
        return <React.Fragment key={option.value}></React.Fragment>;
      }
    });
  };
  const [openEditQrCodeModal, setOpenEditQrCodeModal] = useState(false);
  const [openEditMilestoneModal, setOpenEditMilestoneModal] = useState(false);
  const [selectedMilestoneToEdit, setSelectedMilestoneToEdit] = useState({});
  const [openEditLocationModal, setOpenEditLocationModal] = useState(false);
  const [selectedLocationToEdit, setSelectedLocationToEdit] = useState({});

  const handleMilestoneSaved = (savedModal) => {
    closeEditMilestoneModal();
    // we should select the saved interaction in the dropdown list
    // savedModal.id;
    dispatch(change(FORM_NAME, `${condition}.elementId`, savedModal.id));
  };
  const handleLocationSaved = (savedModal) => {
    closeEditLocationModal();
    // we should select the saved interaction in the dropdown list
    // savedModal.id;
    dispatch(change(FORM_NAME, `${condition}.elementId`, savedModal.id));
  };
  const closeEditQrCodeModal = () => {
    setOpenEditQrCodeModal(false);
  };
  const closeEditMilestoneModal = () => {
    setOpenEditMilestoneModal(false);
  };
  const cancelEditMilestoneModal = () => {
    setOpenEditMilestoneModal(false);
  };
  const closeEditLocationModal = () => {
    setOpenEditLocationModal(false);
  };
  const cancelEditLocationModal = () => {
    setOpenEditLocationModal(false);
  };
  const newMilestoneHandler = (type) => {
    console.log('in newMilestoneHandler');
    setSelectedMilestoneToEdit(null);
    setOpenEditMilestoneModal(true);
  };
  const newLocationHandler = (type) => {
    setSelectedLocationToEdit(null);
    setOpenEditLocationModal(true);
  };

  let allItems = useSelector((state) => state.items);
  let allArchetypes = useSelector((state) => state.archetypes);
  let allAttributes = useSelector((state) => state.attributes);
  let allInteractions = useSelector((state) => state.interactions);
  let allMilestones = useSelector((state) => state.milestones);
  let allLocations = useSelector((state) => state.locations);
  let allScenes = useSelector((state) => state.scenes);
  let allMissions = useSelector((state) => state.missions);
  let allCharacters = useSelector((state) => state.characters);

  let gameInteractions = Object.values(allInteractions).filter(function (el) {
    return el.gameId === game.id;
  });
  let gameItems = Object.values(allItems).filter(function (el) {
    return el.gameId === game.id && !el.isCurrency;
  });
  let gameArchetypes = Object.values(allArchetypes).filter(function (el) {
    return el.gameId === game.id;
  });
  let gameAttributes = Object.values(allAttributes).filter(function (el) {
    return el.gameId === game.id;
  });
  let gameCharacters = Object.values(allCharacters).filter(function (el) {
    return el.gameId === game.id;
  });
  let gameMilestones = Object.values(allMilestones).filter(function (el) {
    return el.gameId === game.id;
  });
  let gameLocations = Object.values(allLocations).filter(function (el) {
    return el.gameId === game.id;
  });
  let gameScenes = Object.values(allScenes).filter(function (el) {
    return el.gameId === game.id;
  });
  let gameMissions = Object.values(allMissions).filter(function (el) {
    return el.gameId === game.id;
  });

  const someOptions = [
    { value: 'at_least', title: 'at least' },
    { value: 'at_most', title: 'at most' },
    { value: 'exact', title: 'exact' },
  ];
  const someOptionsGameStatus = [
    { value: 'completed', title: 'completed' },
    { value: 'active', title: 'active' },
    { value: 'abandoned', title: 'abandoned' },
  ];
  const getElementOptions = (elements, fieldForTitle) => {
    if (typeof fieldForTitle === 'undefined') {
      fieldForTitle = 'name';
    }
    let elementOptions = [];
    if (!elements || elements.length === 0) {
      return elementOptions;
    }
    for (const element of elements) {
      elementOptions.push({ value: element._id ? element._id : element.id, title: shortenText(element[fieldForTitle], 150, '-') });
    }
    return elementOptions;
  };
  const isSingleQuestion = (conditionType) => {
    if (conditionType && !conditionType.includes('QUIZ') && conditionType.includes('QUESTION')) {
      return true;
    }
    return false;
  };
  const isSingleQuiz = (conditionType) => {
    if (conditionType && conditionType.includes('QUIZ') && !conditionType.includes('QUESTION')) {
      return true;
    }
    return false;
  };
  const isQuizWithQuestion = (conditionType) => {
    if (conditionType && conditionType.includes('QUIZ') && conditionType.includes('QUESTION')) {
      return true;
    }
    return false;
  };
  let elementOptions = null;
  let subElementOptions = null;
  let elementLabel = null;
  let subElementLabel = null;
  let subElementFirstEntryIfEmpty = null;
  let somePostfix = null;
  let showDesignerInputString = false;

  if (conditionType) {
    if (
      conditionType.startsWith('CONDITION_TYPE_PLAYER_') ||
      conditionType.startsWith('CONDITION_TYPE_OTHER_') ||
      conditionType.startsWith('CONDITION_TYPE_WORLD_') ||
      conditionType.startsWith('CONDITION_TYPE_GAME_')
    ) {
      if (isSingleQuiz(conditionType) || isQuizWithQuestion(conditionType)) {
        elementOptions = getElementOptions(
          gameInteractions.filter(function (el) {
            return el.type === INTER_QUIZ;
          })
        );
        elementLabel = 'quiz';
        if (isQuizWithQuestion(conditionType)) {
          elementLabel = 'of quiz';
        }
        if (conditionType === 'CONDITION_TYPE_PLAYER_VERB_SOME_QUIZ') {
          somePostfix = 'questions';
          elementLabel = 'of quiz';
        }
      } else if (isSingleQuestion(conditionType)) {
        elementOptions = getElementOptions(
          gameInteractions.filter(function (el) {
            return el.type === INTER_ANSWER_QUESTION;
          })
        );
        elementLabel = 'question';
      } else if (conditionType.includes('CONVERSATION')) {
        elementOptions = getElementOptions(
          gameInteractions.filter(function (el) {
            return el.type === INTER_CONVERSATION;
          })
        );
        elementLabel = 'conversation';
      } else if (conditionType.includes('INFO')) {
        elementOptions = getElementOptions(
          gameInteractions.filter(function (el) {
            return el.type === INTER_INSPECT_INFO;
          })
        );
        elementLabel = 'info';
      } else if (conditionType.includes('CODE')) {
        showDesignerInputString = true;
        elementLabel = 'code';
      } else if (conditionType.includes('CONFIRMATION')) {
        elementOptions = getElementOptions(
          gameInteractions.filter(function (el) {
            return el.type === INTER_CONFIRM;
          })
        );
        elementLabel = 'confirmation';
      } else if (conditionType.includes('MISSION')) {
        // for missions, we want also the submissions. They do not have a name, so construct the list on the fly
        const constructedElements = [];
        if (gameMissions && gameMissions.length > 0) {
          for (const element of gameMissions) {
            constructedElements.push({ _id: element._id ? element._id : element.id, name: element.name });
            // check if this mission has submissions, and add them
            const mission = allMissions[element._id ? element._id : element.id];
            if (
              mission &&
              mission.accomplishment &&
              mission.accomplishment.accomplishmentType === 'submissions' &&
              mission.accomplishment.submissions &&
              mission.accomplishment.submissions.length > 0
            ) {
              for (const submission of mission.accomplishment.submissions) {
                constructedElements.push({ _id: submission._id ? submission._id : submission.id, name: ' ---submission ' + submission.title });
              }
            }
          }
        }
        elementOptions = getElementOptions(constructedElements);
        elementLabel = 'mission';
      } else if (conditionType.includes('SCENE')) {
        if (isTriggerCondition) {
          elementOptions = getElementOptions([scene]);
          elementLabel = 'current scene';
        } else {
          elementOptions = getElementOptions(gameScenes);
          elementLabel = 'scene';
        }
      } else if (conditionType.includes('LOCATION')) {
        elementOptions = getElementOptions(gameLocations);
        elementLabel = 'place';
      } else if (conditionType.includes('ARCHETYPE')) {
        elementOptions = getElementOptions(gameArchetypes);
        elementLabel = 'objects of type';
      } else if (conditionType.includes('ITEM')) {
        elementOptions = getElementOptions(gameItems);
        elementLabel = 'items of type';
      } else if (conditionType.includes('CURRENCY')) {
        elementOptions = null;
        elementLabel = 'currency';
      } else if (conditionType.includes('ATTRIBUTE')) {
        elementOptions = getElementOptions(gameAttributes);
        elementLabel = 'attribute';
      } else if (conditionType.includes('CHARACTER')) {
        elementOptions = getElementOptions(gameCharacters);
        elementLabel = 'to character';
      } else if (conditionType.includes('MILESTONE')) {
        elementOptions = getElementOptions(gameMilestones);
        elementLabel = 'milestone';
      }
      // no else
      if (isQuizWithQuestion(conditionType)) {
        // must have selected a quiz first
        subElementLabel = 'question';
        subElementFirstEntryIfEmpty = '- select quiz first -';
        if (!elementId || elementId === '') {
          subElementOptions = [];
        } else {
          // get questions of selected quiz
          //for (let count=0; count<)
          subElementOptions = [];
          if (allInteractions[elementId]) {
            subElementOptions = getElementOptions(allInteractions[elementId].questions, 'title'); // quiz questions do not have a system name
          }
        }
      }
    }
  }

  const handleConditionVerbChange = (event, newValue, previousValue, name) => {
    // for rule triggers
    // if new verb is enter scene or leave scene, set elementId to the id of the current scene (which is the only scene in the combo)
    if (isTriggerCondition && newValue && ['has_entered', 'has_exited'].includes(newValue)) {
      const conditionFieldName = name.substring(0, name.indexOf('verb'));
      dispatch(change(formName, conditionFieldName + 'elementId', scene.id)); // NOT empty string!!!
    }
  };
  const handleConditionTypeChange = (event, newValue, previousValue, name) => {
    // the base name of the fields will be the name, cut off conditionType from the end
    const conditionFieldName = name.substring(0, name.indexOf('conditionType'));

    // when changed, we have to reset everything elementId, some, someNumber, subElementId, verb
    dispatch(change(formName, conditionFieldName + 'elementId', null)); // NOT empty string!!!
    dispatch(change(formName, conditionFieldName + 'subElementId', null));
    dispatch(change(formName, conditionFieldName + 'some', null));
    dispatch(change(formName, conditionFieldName + 'someNumber', null));
    dispatch(change(formName, conditionFieldName + 'verb', null));

    // handle verb with only a single value
    if (CONDITION_TYPE_VERBS[newValue + conditionTypeVerbsKeyPostfix] && CONDITION_TYPE_VERBS[newValue + conditionTypeVerbsKeyPostfix].length === 1) {
      dispatch(change(formName, conditionFieldName + 'verb', CONDITION_TYPE_VERBS[newValue + conditionTypeVerbsKeyPostfix][0]));
    }
  };

  return (
    <Row className={'condition_row'}>
      <Col lg='11' className={'condition_type mb-2'}>
        <Field
          onChange={handleConditionTypeChange}
          name={`${condition}.conditionType`}
          noFormGroup={true}
          asCol={false}
          component={renderSelect}
          hideLabel={true}
          label='Condition type'
          no_separate_error={true}>
          <option value={''}>- Select one -</option>
          {Object.keys(CONDITION_TYPE_SELECT_OPTIONS).map((group, index) => {
            let groupOptions = CONDITION_TYPE_SELECT_OPTIONS[group];
            groupOptions = groupOptions.filter((c) => !c.showOnlyInForm || c.showOnlyInForm.includes(formName));
            return (
              <optgroup key={index} label={t(group)}>
                {renderOptions(groupOptions)}
              </optgroup>
            );
          })}
        </Field>
      </Col>

      <Col lg={1} className={'text-right'}>
        {!isTriggerCondition && (
          <Button
            style={{ borderWidth: '0px' }}
            disabled={viewer}
            className={' btn-clear'}
            variant='outline-danger'
            onClick={async () => {
              const result = await bconfirm('Are you really sure?', { cancelButtonStyle: 'secondary' });
              if (result) {
                fields.remove(index);
              }
            }}
            title='Remove Condition'>
            <i className='fa fa-times'></i>
            <span className={'controlLabel sr-only'}> Remove Condition</span>
          </Button>
        )}
      </Col>

      {/*<Col lg="1">*/}
      {/*   &nbsp;*/}
      {/*</Col>*/}
      <Col className={'condition_definition'}>
        {conditionType && conditionType.startsWith('CONDITION_TYPE_PLAYER_') && <>Player</>}
        {conditionType && conditionType.startsWith('CONDITION_TYPE_WORLD_') && <>World</>}
        {conditionType && conditionType.startsWith('CONDITION_TYPE_GAME_') && <>Game</>}
        {conditionType && conditionType.startsWith('CONDITION_TYPE_OTHER_') && <></>}
        {conditionType && conditionType.includes('VERB') && CONDITION_TYPE_VERBS[conditionType + conditionTypeVerbsKeyPostfix].length === 1 && (
          <>
            &nbsp;{t(CONDITION_TYPE_VERBS[conditionType + conditionTypeVerbsKeyPostfix][0] + conditionTypeVerbsKeyPostfix)}&nbsp;
            <Field name={`${condition}.verb`} component='input' type='hidden' />
          </>
        )}
        {conditionType && conditionType.includes('VERB') && CONDITION_TYPE_VERBS[conditionType + conditionTypeVerbsKeyPostfix].length > 1 && (
          <>
            <Field
              name={`${condition}.verb`}
              noFormGroup={true}
              asCol={false}
              onChange={handleConditionVerbChange}
              component={renderSelect}
              hideLabel={true}
              label={'verb'}
              additionalControlClass='verb'
              no_separate_error={true}>
              {['- Select one -', ...CONDITION_TYPE_VERBS[conditionType + conditionTypeVerbsKeyPostfix]].map((option, index) => (
                <option key={index === 0 ? '' : option} value={index === 0 ? '' : option}>
                  {t(option + conditionTypeVerbsKeyPostfix)}
                </option>
              ))}
            </Field>
          </>
        )}
        {conditionType && conditionType.includes('SOME') && (
          <>
            <Field name={`${condition}.some`} noFormGroup={true} asCol={false} component={renderSelect} hideLabel={true} label={'some'} additionalControlClass='some' no_separate_error={true}>
              {[{ value: '', title: '- Select one -' }, ...someOptions].map((option, index) => (
                <option key={option.value} value={option.value}>
                  {option.title}
                </option>
              ))}
            </Field>
            <Field
              name={`${condition}.someNumber`}
              label='Number'
              hideLabel={true}
              asCol={false}
              type='number'
              step='1'
              component={renderInput}
              additionalControlClass='someNumber'
              noFormGroup={true}
              no_separate_error={true}
            />
            {somePostfix}
          </>
        )}
        {conditionType && conditionType === 'CONDITION_TYPE_GAME_VERB_STATUS' && (
          <>
            <Field name={`${condition}.some`} noFormGroup={true} asCol={false} component={renderSelect} hideLabel={true} label={'some'} additionalControlClass='some' no_separate_error={true}>
              {[{ value: '', title: '- Select one -' }, ...someOptionsGameStatus].map((option, index) => (
                <option key={option.value} value={option.value}>
                  {option.title}
                </option>
              ))}
            </Field>
          </>
        )}
        {subElementOptions && (
          <>
            {subElementLabel}{' '}
            <Field
              normalize={(value, previousValue, allValues) => (value ? value : null)}
              name={`${condition}.subElementId`}
              noFormGroup={true}
              asCol={false}
              component={renderSelect}
              hideLabel={true}
              label={subElementLabel}
              no_separate_error={true}>
              {[{ value: '', title: subElementOptions.length === 0 ? subElementFirstEntryIfEmpty : '- Select one -' }, ...subElementOptions].map((option) => (
                <option key={option.value} value={option.value}>
                  {option.title}
                </option>
              ))}
            </Field>
          </>
        )}
        {elementOptions && conditionType !== 'CONDITION_TYPE_PLAYER_VERB_SOME_CURRENCY' && (
          <>
            {elementLabel}{' '}
            <Field
              normalize={(value, previousValue, allValues) => (value ? value : null)}
              name={`${condition}.elementId`}
              noFormGroup={true}
              asCol={false}
              component={renderSelect}
              hideLabel={true}
              label={elementLabel}
              no_separate_error={true}>
              {[{ value: '', title: '- Select one -' }, ...elementOptions].map((option) => (
                <option key={option.value} value={option.value}>
                  {option.title}
                </option>
              ))}
            </Field>
            {isTriggerCondition && conditionType == 'CONDITION_TYPE_PLAYER_VERB_MILESTONE' && (
              <Button variant='secondary' type='button' onClick={newMilestoneHandler}>
                <i className='fas fa-plus'></i> Create new
              </Button>
            )}
            {isTriggerCondition && conditionType == 'CONDITION_TYPE_PLAYER_VERB_LOCATION' && (
              <Button className='' variant='secondary' type='button' onClick={newLocationHandler}>
                <i className='fas fa-plus'></i> Create new
              </Button>
            )}
          </>
        )}
        {conditionType === 'CONDITION_TYPE_PLAYER_VERB_SOME_CURRENCY' && <span>{' ' + elementLabel}</span>}
        {conditionType && conditionType.endsWith('CURRENT_OBJECT') && <span>{' a clone of this object'}</span>}
        {conditionType && conditionType.endsWith('PLAYER_VERB_GAME') && <span>{'  game'}</span>}
        {showDesignerInputString && (
          <>
            <Field name={`${condition}.designerInputString`} noFormGroup={true} label='Code' asCol={false} component={renderInput} />
            {stringIsNotEmpty(triggerCode) && (
              <Button variant='secondary' title='Get QR code' type='button' onClick={() => setOpenEditQrCodeModal(true)} style={{ marginLeft: 2 }}>
                <i className='fas fa-qrcode'></i>
              </Button>
            )}
            <Form.Text className='text-muted'>Leave blank for auto generation</Form.Text>
          </>
        )}
      </Col>

      {scene && (
        <ModalEdit width={60} openEditModal={openEditQrCodeModal} closeEditModal={closeEditQrCodeModal} heading={'Get QR Code'} portal={'milestone'}>
          <QrCodeForm eventType={'do'} eventName={'read'} objectId={triggerCode} closeEditQrCodeModal={closeEditQrCodeModal} gameId={game.id} sceneId={scene.name === 'Universe' ? 'any' : scene.id} />
        </ModalEdit>
      )}
      <ModalEdit openEditModal={openEditMilestoneModal} closeEditModal={closeEditMilestoneModal} heading={'Edit or create Milestone'} portal={'milestone'}>
        <MilestoneEdit game={game} interaction={selectedMilestoneToEdit} onSave={handleMilestoneSaved} onCancel={cancelEditMilestoneModal} />
      </ModalEdit>
      <ModalEdit openEditModal={openEditLocationModal} closeEditModal={closeEditLocationModal} heading={'Edit or create Location'} portal={'location'}>
        <LocationEdit game={game} scene={scene} interaction={selectedLocationToEdit} onSave={handleLocationSaved} onCancel={cancelEditLocationModal} />
      </ModalEdit>
    </Row>
  );
};
export default connect((state, ownProps) => {
  const selector = formValueSelector(ownProps.formName);
  const conditionType = selector(state, `${ownProps.condition}.conditionType`);
  const elementId = selector(state, `${ownProps.condition}.elementId`);
  const triggerCode = selector(state, `trigger.designerInputString`);
  return {
    conditionType,
    elementId,
    triggerCode,
  };
})(Condition);
