import gamesApi from '../apis/gamesApi';

import { displayError, displaySuccess } from '../components/utils/notifications';
import { FETCH_ACTIVE_INSTANCES, FETCH_INSTANCE } from './types';

export const fetchActiveInstances = () => async (dispatch) => {
  return new Promise((resolve, reject) => {
    gamesApi
      .get(`/api/manage/gameInstances/active`)
      .then((response) => {
        dispatch({ type: FETCH_ACTIVE_INSTANCES, payload: response.data });
        resolve();
      })
      .catch((err) => {
        dispatch({ type: FETCH_ACTIVE_INSTANCES, payload: {} });
        displayError(err, `Error retrieving game initial state spec`);
        reject();
      });
  });
};

export const fetchActiveInstancesThinList = (gameId) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    gamesApi
      .get(`/api/manage/gameInstances/${gameId}/thin-list`)
      .then((response) => {
        dispatch({ type: FETCH_ACTIVE_INSTANCES, payload: response.data });
        resolve();
      })
      .catch((err) => {
        dispatch({ type: FETCH_ACTIVE_INSTANCES, payload: {} });
        displayError(err, `Error retrieving game initial state spec`);
        reject();
      });
  });
};

export const fetchInstance = (instanceId) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    gamesApi
      .get(`/api/instances/${instanceId}?fullState=true`)
      .then((response) => {
        dispatch({ type: FETCH_INSTANCE, payload: response.data });
        resolve();
      })
      .catch((err) => {
        dispatch({ type: FETCH_INSTANCE, payload: {} });
        displayError(err, `Error retrieving game initial state spec`);
        reject();
      });
  });
};

export const sendMessageToGame = (gameId, values) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    gamesApi
      .post(`/api/games/${gameId}/send-message`, values)
      .then((response) => {
        displaySuccess(`Message sent!`);
        resolve();
        //history.push(`/games/${gameId}`);
      })
      .catch((err) => {
        displayError(err, `Error Sending message`);
        reject();
      });
  });
};

export const applyExpiration = (gameId, message) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    gamesApi
      .get(`/api/manage/games/${gameId}/applyExpiration`)
      .then((response) => {
        displaySuccess(
          `Command sent successfully!`,
          `Expired Instances: Working Version:  ${response.data.expiredInstances?.workingVersion}, Public Version: ${response.data.expiredInstances?.publicVersion}`
        );
        resolve();
        //history.push(`/games/${gameId}`);
      })
      .catch((err) => {
        displayError(err, `Error applying expiration to instances`);
        reject();
      });
  });
};
