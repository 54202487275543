import React, { useCallback, useEffect, useRef } from 'react';
import { formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { ICON_URL_DEFAULT } from './iconUrls';
// Variables

const defaultLocation = {
  // CN Tower Landmark
  lat: 39,
  lng: 22,
  radius: 1000,
};
// styles
const mapStyles = {
  width: '100%',
  height: '100px',
  backgroundColor: 'white',
  border: '2 solid lightgray',
  borderRadius: 5,
};

// function GoogleMap2(props) {
const GoogleMapLocationForm = (props) => {
  // refs
  const googleMapRef = useRef(null);
  const googleMap = useRef(null);
  const marker = useRef(null);
  const radiusCircle = useRef(null);
  //let gmapScriptIncluded = useRef(false);

  const lat = props.lat ? props.lat : defaultLocation.lat;
  const lng = props.lng ? props.lng : defaultLocation.lng;
  const radius = props.radius ? parseInt(props.radius) : defaultLocation.radius;

  // helper functions
  const createGoogleMap = () =>
    new window.google.maps.Map(googleMapRef.current, {
      zoom: 14,
      center: {
        lat: parseFloat(lat),
        lng: parseFloat(lng),
      },
    });

  const createMarker = (iconUrl) => {
    const markerOptions = {
      position: { lat: parseFloat(lat), lng: parseFloat(lng) },
      map: googleMap.current,
      //label:'skata',
      draggable: props.onMarkerDrag ? true : false,
    };
    if (iconUrl) {
      markerOptions.icon = {
        url: iconUrl ? iconUrl : ICON_URL_DEFAULT,
      };
    }
    const marker = new window.google.maps.Marker(markerOptions);
    if (props.onMarkerDrag) {
      window.google.maps.event.addListener(marker, 'dragend', function () {
        props.onMarkerDrag(marker.getPosition().lat(), marker.getPosition().lng());
      });
    }

    return marker;
  };
  const createRadius = () => {
    const radiusCircle = new window.google.maps.Circle({
      map: googleMap.current,
      radius: radius, // in metres
      strokeWeight: 1,
      strokeOpacity: 0.6,
      fillColor: '#0e49aa',
      fillOpacity: 0.4,
    });
    radiusCircle.bindTo('center', marker.current, 'position');
    return radiusCircle;
  };
  const repositionMarker = () => {
    let latLng = new window.google.maps.LatLng(parseFloat(lat), parseFloat(lng));
    marker.current.setPosition(latLng);
    if (!googleMap.current.getBounds()?.contains(latLng)) {
      googleMap.current.setCenter(latLng);
    }
  };
  const repositionRadius = () => {
    radiusCircle.current.setRadius(radius);
  };
  const initMap = useCallback(() => {
    if (googleMap.current === null) {
      googleMap.current = createGoogleMap();
    }
    if (marker.current === null) {
      marker.current = createMarker(props.inconUrl);
    } else {
      repositionMarker();
    }

    if (!props.noRadius) {
      if (radiusCircle.current === null) {
        radiusCircle.current = createRadius();
      } else {
        repositionRadius();
      }
    }
    return 0;
  });

  /*  const delayedInitMap = useCallback(() => {
    if (typeof window.google !== 'object' || typeof window.google.maps !== 'object') {
      setTimeout(delayedInitMap, 500);
    } else {
      initMap();
    }
  }); */
  // useEffect Hook
  useEffect(() => {
    setTimeout(() => {
      if (typeof window.google !== 'object' || typeof window.google.maps !== 'object') {
        setTimeout(initMap, 500);
      } else {
        initMap();
      }
    }, 500);
    // if (typeof window.google === 'object' && typeof window.google.maps === 'object') {
    //     initMap();
    // }
    // else if (!gmapScriptIncluded.current) {
    //     gmapScriptIncluded.current = true;
    //
    //     const googleMapScript = document.createElement('script');
    //     googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&libraries=places`
    //     window.document.body.appendChild(googleMapScript);
    //
    //     googleMapScript.addEventListener('load', () => {
    //         initMap();
    //     })
    // }
    // else { // gmap script included, but window.google not available yet. Delay initMap
    //     setTimeout(delayedInitMap,500);
    // }
  }, [initMap, lat, lng, radius]);

  return <div ref={googleMapRef} className='google-map-location-form' style={{ ...mapStyles, ...props.style }} />;
};

const mapStateToProps = (state, ownProps) => {
  if (ownProps.formName) {
    const selector = formValueSelector(ownProps.formName);
    return selector(state, 'lat', 'lng', 'radius');
  }
  return {};
};
export default connect(mapStateToProps)(GoogleMapLocationForm);

//export default GoogleMap2;
