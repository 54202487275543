import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { fetchDashboardStats, fetchRoleRequests } from '../../actions/adminActions';
import MainContent from '../layouts/MainContent';
import DashboardCard from './DashboardCard';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import isEmpty from 'is-empty';

export default function AdminDashboard() {
  const dispatch = useDispatch();
  const stats = useSelector((state) => state.admin.stats);

  useEffect(() => {
    if (isEmpty(stats)) {
      dispatch(fetchDashboardStats());
    }
  }, [dispatch, stats]);

  return (
    <MainContent hasSideBar={false}>
      <nav aria-label='breadcrumb'>
        <ol className='breadcrumb'>
          <li className='breadcrumb-item'>
            <LinkContainer to='#'>
              <a>Admin Dashboard</a>
            </LinkContainer>
          </li>
        </ol>
      </nav>
      <div className='d-flex flex-row justify-content-center flex-wrap'>
        <DashboardCard title={'Games'} stats={stats?.games} subStats={`Public: ${stats.publishedGames}`} linkTo={'/'} />
        <DashboardCard title={'Users'} stats={stats?.users} subStats={`Designers: ${stats.designers}`} />
        <DashboardCard title={'Games Played'} stats={stats?.gamesPlayed} subStats={`Players: ${stats.playersPlayed}`} />
        {stats.roleRequests > 0 && <DashboardCard title={'Pending Role Requests'} stats={stats?.roleRequests} linkTo={'./admin/role-requests'} />}
      </div>
    </MainContent>
  );
}
