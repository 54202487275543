//import _ from 'lodash';
import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { editItem, createItem, deleteItem } from '../../actions/itemsActions';
import { withRouter } from 'react-router-dom';
import history from '../../history';
import { bconfirm } from '../utils/ConfirmAlert';
import { fetchGameInitialState, deleteInitialStateItem, setInitialStateItem } from '../../actions/initialStateActions';
import { fetchGameInitialStateSpec } from '../../actions/initialStateSpecActions';
import qs from 'qs';
import ObjectForm from '../forms/ObjectForm';
import OfferObjectForm from '../forms/OfferObjectForm';

const InitialStateEdit = ({ dispatch, type, location, game, match }) => {
  const gameSpec = useSelector((state) => state.initialStateSpec);
  const initialState = useSelector((state) => state.initialState);
  const scenes = gameSpec && gameSpec.spec && gameSpec.spec.script.scenes;
  let queryString = qs.parse(location.search, { ignoreQueryPrefix: true });
  const { objecttype, objectid, objectaction, modifier1 } = match.params;

  let item;
  if (type === 'objects' && objectaction === 'edit') {
    if (objectid !== '0' && initialState && initialState.state) {
      Object.keys(initialState.state.has.object).forEach((sceneId) => {
        Object.values(initialState.state.has.object[sceneId]).forEach((obj) => {
          if (obj.id === objectid) {
            item = obj;
          }
        });
      });
    }
  } else if (type === 'offers' && objectaction === 'edit') {
    if (objectid !== '0' && initialState && initialState.state) {
      Object.keys(initialState.state.has.offer).forEach((sceneId) => {
        Object.values(initialState.state.has.offer[sceneId]).forEach((o) => {
          if (o.id === objectid) {
            item = o;
          }
        });
      });
    }
  }

  const versionId = queryString.versionId;
  const versionIdQueryPart = versionId ? `?versionId=${versionId}` : '';
  useEffect(() => {
    if (objectid !== '0') {
      dispatch(fetchGameInitialState(game.id, versionId || 'current'));
    }

    dispatch(fetchGameInitialStateSpec(game.id, versionId || 'current'));
  }, [dispatch, game.id, objectid, versionId]);

  const handleCancel = () => {
    history.push(`/games/${game.id}/initialState/manage-${type}/0${versionIdQueryPart}`);
  };
  const handleDelete = async () => {
    const result = await bconfirm('Are you really sure?', { cancelButtonStyle: 'secondary' });
    if (result) {
      dispatch(deleteInitialStateItem(game.id, versionId || 'current', `state.has.${type.slice(0, -1)}.${item.sceneId}.${item.id}`))
        .then(() => {
          //history.push(`/games/${game.id}/initialState/${type}/0${versionIdQueryPart}`);
          history.push(`/games/${game.id}/initialState/manage-${type}/0${versionIdQueryPart}`);
        })
        .catch((e) => {
          /* do nothing. A toastr notification should have been displayed */
        });
    }
  };

  const onSubmit = (formValues) => {
    dispatch(setInitialStateItem(game.id, versionId || 'current', formValues))
      .then(() => {
        history.push(`/games/${game.id}/initialState/manage-${type}/0${versionIdQueryPart}`);
      })
      .catch((e) => {
        /* do nothing. An error toastr should have been displayed */
      });
  };
  const defaultInitialValues = {
    gameId: game.id,
    approved: true,
  };

  if (objectid && modifier1 === 'objects' && objectaction === 'create') {
    defaultInitialValues.archetypeId = objectid;
  }
  if (objectid && modifier1 === 'offers' && objectaction === 'create') {
    defaultInitialValues.offerId = objectid;
  }

  return (
    <div className=''>
      {type === 'objects' ? (
        <ObjectForm
          scenes={scenes}
          gameSpec={gameSpec}
          enableReinitialize={true}
          type={type}
          handleCancel={handleCancel}
          handleDelete={handleDelete}
          game={game}
          initialValues={item ? item : defaultInitialValues}
          onSubmit={onSubmit}
        />
      ) : (
        <OfferObjectForm
          scenes={scenes}
          gameSpec={gameSpec}
          enableReinitialize={true}
          type={type}
          handleCancel={handleCancel}
          handleDelete={handleDelete}
          game={game}
          initialValues={item ? item : defaultInitialValues}
          onSubmit={onSubmit}
        />
      )}
    </div>
  );
};

export default withRouter(connect(null, null)(InitialStateEdit));
