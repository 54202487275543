import {applyMiddleware, compose, createStore} from "redux";
import reducers from "./reducers";
import reduxThunk from "redux-thunk";
import {
    DELETE_ARCHETYPE, CREATE_ARCHETYPE, EDIT_ARCHETYPE,
    DELETE_SCENE, CREATE_SCENE, EDIT_SCENE,
    DELETE_MEDIA, CREATE_MEDIA, EDIT_MEDIA,
    DELETE_MILESTONE, CREATE_MILESTONE, EDIT_MILESTONE,
    DELETE_MISSION, CREATE_MISSION, EDIT_MISSION,
    DELETE_INTERACTION, CREATE_INTERACTION, EDIT_INTERACTION,
    DELETE_RULE, CREATE_RULE, EDIT_RULE,
    DELETE_LOCATION, CREATE_LOCATION, EDIT_LOCATION,
    DELETE_OFFER, CREATE_OFFER, EDIT_OFFER,
    DELETE_CHARACTER, CREATE_CHARACTER, EDIT_CHARACTER,
    DELETE_ITEM, CREATE_ITEM, EDIT_ITEM,
    DELETE_ATTRIBUTE, CREATE_ATTRIBUTE, EDIT_ATTRIBUTE, EDIT_GAMEVERSION, SET_CURRENT_USER, FETCH_MY_GAMES
} from "./actions/types";
import {getLiveSpecVersion} from './utils/utils'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const loggerMiddleware = (store) => (next) => (action) => {
    if ([DELETE_ARCHETYPE,CREATE_ARCHETYPE,EDIT_ARCHETYPE,
        DELETE_SCENE,CREATE_SCENE,EDIT_SCENE,
        DELETE_MEDIA,CREATE_MEDIA,EDIT_MEDIA,
        DELETE_MILESTONE,CREATE_MILESTONE,EDIT_MILESTONE,
        DELETE_MISSION,CREATE_MISSION,EDIT_MISSION,
        DELETE_INTERACTION,CREATE_INTERACTION,EDIT_INTERACTION,
        DELETE_RULE,CREATE_RULE,EDIT_RULE,
        DELETE_LOCATION,CREATE_LOCATION,EDIT_LOCATION,
        DELETE_OFFER,CREATE_OFFER,EDIT_OFFER,
        DELETE_CHARACTER,CREATE_CHARACTER,EDIT_CHARACTER,
        DELETE_ITEM,CREATE_ITEM,EDIT_ITEM,
        DELETE_ATTRIBUTE,CREATE_ATTRIBUTE,EDIT_ATTRIBUTE
    ].includes(action.type)) {
        // it get's here only if the request was successfull. So the live spec was changed.
        // send a dispatch to alter the live spec version with a dummy, current date/time

        //console.log('previous state', store.getState())
        let liveSpecVersion = getLiveSpecVersion(store.getState().gameVersions?Object.values(store.getState().gameVersions):null)
        if (!liveSpecVersion) {
           liveSpecVersion = {};
        }
        liveSpecVersion.updatedAt = new Date().toISOString(); // so that the app knows that the game is dirty and "publish" should be shown
        store.dispatch({type:EDIT_GAMEVERSION, payload:liveSpecVersion});
        //console.log('liveSpecVersion', liveSpecVersion.updatedAt)
        next(action);
       // console.log('new state', store.getState())
        // store.dispatch({
        //     type: 'SET_MOVIE_DATA',
        //     payload: { movies: res.data },
        // })

    }
    else {
        next(action);
    }
};
const setUserMiddleware = (store) => (next) => (action) => {
    if ([SET_CURRENT_USER].includes(action.type)) {
        // if, for any reason, user changes, clear store of game data
        // this is especially important on logout, when currentuser is set to {}
        // for now, just empty the list of games
        store.dispatch({type:FETCH_MY_GAMES, payload:{}});
        next(action);
    }
    else {
        next(action);
    }
};
const store = createStore(
    reducers,
    composeEnhancers(applyMiddleware(reduxThunk, loggerMiddleware, setUserMiddleware))
);

export default store;
