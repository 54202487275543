import React from 'react';
import { getProperty } from 'dot-prop';
import { Popover, OverlayTrigger, Button } from 'react-bootstrap';

import { formatDate, getTotalCount, extractData, exportToCsv } from '../../utils/utils';

const ScenesSummaryWidget = ({ publishedGameVersion, analytics }) => {
  const exportSceneStats = () => {
    const { scenes } = publishedGameVersion?.game?.spec?.script;
    const columns = scenes.map((i) => [i.systemName]);
    columns.forEach((c, idx) => {
      const scene = scenes[idx];
      analytics.forEach((userState) => {
        const when = getProperty(userState, `has.entered.scene.${scene.id}.when`);
        if (when) {
          const visitTime = formatDate(when);
          c.push(`${userState.avatar?.name}\n(${visitTime})`);
        }
      });
    });
    const maxLength = Math.max(...columns.map((c) => c.length));
    const rows = [];
    for (let i = 0; i < maxLength; i++) {
      const row = columns.map((c) => c[i]);
      rows.push(row); // Join row elements with commas
    }
    //console.log(rows);
    exportToCsv(`SceneStats.csv`, rows);
  };

  return (
    <>
      <h5 className='d-flex flex-row justify-content-between'>
        Scene Stats
        <Button style={{ float: 'right' }} onClick={exportSceneStats}>
          <i className='fas fa-download mr-2' />
          CSV
        </Button>
      </h5>
      <ul className='list-group mt-3'>
        {publishedGameVersion.game.spec.script.scenes &&
          publishedGameVersion.game.spec.script.scenes.map((s) => {
            return (
              <li key={s.id} className='list-group-item d-flex justify-content-between align-items-center'>
                {s.name}

                <small className='font-sm text-right'>
                  {'Visited by: '}

                  <OverlayTrigger
                    trigger='click'
                    rootClose
                    placement='right'
                    overlay={
                      <Popover content>
                        <ul className='list-group list-group-flush '>
                          {extractData(analytics, `has.entered.scene.${s.id}`).length === 0 && <li className='list-group-item'>-</li>}
                          {extractData(analytics, `has.entered.scene.${s.id}`).map((userState) => {
                            return (
                              <li key={userState.userId} className='list-group-item'>
                                {(userState.avatar && userState.avatar.name) || '-'}

                                <small className='d-block text-muted'>{formatDate(getProperty(userState, `has.entered.scene.${s.id}.when`))}</small>
                              </li>
                            );
                          })}
                        </ul>
                      </Popover>
                    }>
                    <a>
                      <strong>{getTotalCount(analytics, `has.entered.scene.${s.id}`)} players</strong>
                    </a>
                  </OverlayTrigger>
                </small>
              </li>
            );
          })}
      </ul>
    </>
  );
};

export default ScenesSummaryWidget;
