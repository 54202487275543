import React from 'react';

import '../../i18n';
import { useTranslation } from 'react-i18next';
import {useSelector} from "react-redux";

const MainHeading = ({game, objecttype, objectaction, objectid}) => {
    const { t, i18n } = useTranslation();
    const scenes = useSelector((state)=>state.scenes);

    if (objecttype
        && objectaction
        && objectid
    ) {
        switch (objecttype) {
            case 'scenes':
                if (!scenes) {
                    return <>{'loading...'}</>;
                }
                const scene = scenes[objectid];//.find(element => element.id === objectid);
                if (!scene && objectid !== '0') {
                    return <>{'loading...'}</>;
                }
                switch (objectaction) {
                    case 'edit':
                        return <>{`Edit scene ${scene.name}`}</>;
                    case 'show':
                        return <></>;//<>{`Scene Plot ${scene.name}`}</>;
                    case 'create':
                        return <>{'New scene'}</>;
                    default:
                        return <>SCENES OBJECT TYPE BUT INVALID ACTION</>
                }
            default:
                return '';
        }

    }
    else {

        if (game) {
            return <>{`Playground of ${game.title}`}</>;
        }
        return <></>;
    }


}
export default MainHeading;