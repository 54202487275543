/* eslint-disable react/prop-types */
import React from 'react';
import { compose, withHandlers, withState, withPropsOnChange } from 'recompose';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import renderError from "./renderError";
import Form from "react-bootstrap/Form";


// https://gist.github.com/kaleem-elahi/7d73bb5505518c3f1868f54d11ee3f80
// toolbar={{ options: ['inline', 'blockType', 'fontSize', 'image', 'list', 'textAlign', 'link', 'history'] }}

const RichTextEditorWYSIWYG =
    ({
         // input: {
         //   value,
         //   onChange,
         // },
         meta,
         editorState,
         onEditorStateChange,
         label,
         hideLabel,
         additionalRTEClass
     }) => (

        <div className={`WysiwygEditor ${additionalRTEClass}`}>
            <Form.Label className={hideLabel?'sr-only':''}>{label}</Form.Label>

            {
                editorState &&

                <Editor
                    editorState={editorState}
                    onEditorStateChange={onEditorStateChange}
                    editorClassName="editor"
                    toolbarClassName="toolbar"
                    toolbar={
                        {
                            options: ['inline', 'blockType',  'list', 'link'],
                            inline:{options:['bold','italic', 'underline']},
                            blockType:{options:['Normal','H1', 'H2', 'H3']}
                        }}
                />
            }
            {renderError(meta)}

        </div>
    );


export default compose(
    withState('editorState', 'setEditorState', EditorState.createEmpty()),
    withPropsOnChange(
        ['input'],
        ({
             input: {
                 value,
             },
             meta: {
                 dirty,
             },
             setEditorState,
         }) => {
            if (dirty) {
                return;
            }
            if (!value && value !== '') {
                return;
            }
            const contentBlock = htmlToDraft(value);
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            setEditorState(editorState);
        },
    ),
    withHandlers({
        onEditorStateChange: ({
                                  input: {
                                      onChange,
                                  },
                                  setEditorState,
                              }) => (editorState) => {
            setEditorState(editorState);
            const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
            onChange(html);
        },
    }),
)(RichTextEditorWYSIWYG);