import React from "react";
import renderError from "./renderError";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";

// const renderBooleanRadio = ({input, label, meta,  ...rest}) => {

const renderBooleanRadio2 = ({input, label, meta, disabled, inline}) => {
    const className = `${meta.error && meta.touched ? 'invalid':''}`;
    const disabledProp = {};
    const inlinedProp = {};
    const isValidProp = {};
    inline = true;
    if (disabled) {
        disabledProp.disabled = 'disabled'; //
    }
    if (inline) {
        inlinedProp.inline = 'inline'; //
    }
    if ((!meta.error && meta.touched)) {
       // isValidProp.isValid = 'isValid'; // to prasinizei, opote den to bazw
    }
    return (
        <>
            <Form.Check {...inlinedProp}  type={"radio"} id={input.name+input.value}>
                <Form.Check.Input {...input} type={"radio"} {...disabledProp} {...isValidProp} className={className} />
                <Form.Check.Label>{label}</Form.Check.Label>
                {
                    (meta.error && meta.touched) &&
                    <Form.Control.Feedback type="invalid">{(inline?' ':'')+meta.error}</Form.Control.Feedback>
                }
            </Form.Check>
        </>
    );
}
export default renderBooleanRadio2;



