import React, { useEffect, useState } from 'react';

import { Card } from 'react-bootstrap';
import ReactGoogleMap from './ReactGoogleMap';

const PlayersMap = ({ usersState, onRefresh, center, worldStatus, onPlayerMarkerClick }) => {
  const players = [];
  const [updateInterval, setUpdateInterval] = useState(10);
  //Auto Refreshing Map Data
  useEffect(() => {
    const timeout = setTimeout(() => {
      onRefresh();
    }, updateInterval * 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [onRefresh, updateInterval]);

  usersState &&
    usersState.forEach((us) => {
      if (!(us?.currentLocation?.latitude && us.status === 'playing' && us.worldStatus === 'active')) {
        return;
      }
      const avatar = us?.avatar;
      players.push({
        id: us.id,
        name: avatar.name,
        iconUrl: avatar.photo?.uri,
        iconMarker: false,
        position: {
          lat: us?.currentLocation?.latitude,
          lng: us?.currentLocation?.longitude,
        },
        onlyRadius: false,
      });
    });

  return (
    <>
      <Card className='shadow-sm'>
        <Card.Header className='d-flex font-weight-bold align-items-center bg-rlck-primary-light'>
          {players.length > 0 && <span>Locations of ({players.length}) Active Players</span>}
          {players.length === 0 && <span>No location data available</span>}
          <div className='ml-auto font-weight-lighter'>
            [Updates every{' '}
            <input
              type={'number'}
              min={1}
              max={100}
              value={updateInterval}
              onChange={(e) => {
                const value = e.target.value;
                if (value > 0 && value <= 100) {
                  setUpdateInterval(value);
                }
              }}
            />{' '}
            seconds]
          </div>
        </Card.Header>
        <Card.Body style={{ padding: 0 }}>
          {/* <MarkerMap markersData={markersData} height={400} onMarkerContentClick={onPlayerMarkerClick} /> */}
          <ReactGoogleMap height={500} items={players} center={center} onItemClick={onPlayerMarkerClick} />
        </Card.Body>
      </Card>
    </>
  );
};

export default PlayersMap;
